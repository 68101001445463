import { Button, Group, Select, Stack, TextInput, Title } from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import BackButton from "../../../../components/View/BackButton";
import PageTitle from "../../../../components/View/PageTitle";
import { editAIKey } from "../../../../api/ConfigsApi";

const EditAiKeyPage = () => {
  const ai_key = useLoaderData();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    setLoading(true);
    editAIKey({ id: ai_key?.id, name: data?.name, api_key: data?.api_key })
      .then((response) => {
        setTimeout(() => {
          notifications.show({
            message: lang.openai_keys.row_highlight.updated,
            color: "tertiary.8",
          });
        }, 300);
        navigate(`../?highlight=${ai_key?.id}`);
      })
      .finally(() => setLoading(false));
  };
  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.openai_keys.browser_tab_title} />
      </Group>
      <Stack
        className="layout-block"
        w={"40%"}
        mx={"auto"}
        gap={"xs"}
        component={"form"}
        onSubmit={handleSubmit}
      >
        <Title order={5}>
          {lang.openai_keys.edit.dialog.title} : {ai_key.name}
        </Title>
        <TextInput
          name="name"
          defaultValue={ai_key?.name}
          label={lang.openai_keys.create.dialog.name}
          placeholder={lang.openai_keys.create.dialog.name}
          autoFocus
          required
        />

        <Select
          label={lang.api_keys.columns.type}
          name="type"
          defaultValue={ai_key?.type}
          data={[
            {
              label: "OpenAI",
              value: "openai",
            },
            {
              label: "Datagma",
              value: "datagma",
            },
            {
              label: "FullEnrich",
              value: "fullenrich",
            },
            {
              label: "Kaspr",
              value: "kaspr",
            },
          ]}
        />

        <TextInput
          name="api_key"
          label={lang.openai_keys.create.dialog.key}
          placeholder={"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"}
          defaultValue={ai_key?.api_key}
          required
        />

        <Group justify="flex-end" mt={"md"}>
          <BackButton />
          <Button type="submit" loading={loading}>
            {lang.datafields.edit.dialog.submit_button}
          </Button>
        </Group>
      </Stack>
    </>
  );
};

export default EditAiKeyPage;
