import {
  ActionIcon,
  Anchor,
  Blockquote,
  CopyButton,
  Group,
  Text,
  Tooltip,
} from "@mantine/core";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../layouts/Table/MainTable";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { useLanguage } from "../../../stores/LanguageStore";
import { useCallback, useMemo, useState } from "react";
import SelectionDeleter from "../../../components/TableComponents/SelectionDeleter";
import { deleteApiKey, editApiKey, getApiKeys } from "../../../api/ConfigsApi";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import ItemDeleter from "../../../components/TableComponents/ItemDeleter";
import dateColumn from "../../../layouts/Table/columns/dateColumn";
import numberColumn from "../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../layouts/Table/columns/nameColumn";
import { IconInfoCircle, IconKey } from "@tabler/icons-react";
import CreateApiKeyButton from "./CreateApiKeyButton";
import { Link } from "react-router-dom";
import ApiKeyRegenerate from "./ApiKeyRegenerate";
import PageTitle from "../../../components/View/PageTitle";
import { useSessionStore } from "../../../stores/UserStore";

const ApiListing = () => {
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);

  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(
    () => [
      numberColumn(),
      nameColumn({
        parentPath: () => `/api`,
        editFunction: () => editApiKey,
        apiRef,
        getDisabled: () => false,
      }),
      {
        field: "token",
        headerName: lang.api_keys.columns.key,
        width: 200,
        renderCell: ({ value }) => (
          <CopyButton value={value}>
            {({ copied, copy }) => (
              <Tooltip
                label={copied ? lang.global.copied : lang.global.copy}
                color={copied ? "green" : "black"}
              >
                <Anchor c={"black"} onClick={copy} truncate="end">
                  {value}
                </Anchor>
              </Tooltip>
            )}
          </CopyButton>
        ),
      },
      dateColumn(),
    ],
    [apiRef, lang]
  );

  const actionsCell = useCallback(
    ({ row }) => {
      return (
        <Group wrap="nowrap" gap={4}>
          <ApiKeyRegenerate row={row} setTableLoading={setLoading} />

          <Tooltip label={lang.api_keys.uses_dialog.button_tooltip}>
            <ActionIcon component={Link} to={`${row.id}`} variant="subtle">
              <IconKey size={18} />
            </ActionIcon>
          </Tooltip>

          <ItemDeleter
            title_bold={`${row.name} #${row.id}`}
            handleDelete={deleteApiKey}
            deletedMessage={lang.api_keys.delete_success}
            id={row.id}
            apiRef={apiRef}
          />
        </Group>
      );
    },
    [
      apiRef,
      lang.api_keys.delete_success,
      lang.api_keys.uses_dialog.button_tooltip,
    ]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => (
      <SelectionDeleter
        handleDelete={deleteApiKey}
        apiRef={apiRef}
        setTableLoading={setLoading}
        ids={selectedIds}
      />
    ),
    [apiRef]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: getApiKeys,
    responseField: "api_keys_list",
    setRows,
  });

  console.log(useSessionStore.getState().reseller);

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle title={lang.api_keys.browser_tab_title} />

        <CreateApiKeyButton />
      </Group>

      {useSessionStore.getState().reseller?.domain_api && (
        <Blockquote color="secondary" radius={"md"} py={"sm"} pl={"md"} ml={16}>
          <Group>
            <IconInfoCircle size={28} opacity={0.4} />
            <Text>
              {lang.api_keys.info.text1}{" "}
              <Anchor
                fw={600}
                href={`https://${useSessionStore.getState().reseller?.domain_api}`}
                target="_blank"
              >
                {lang.api_keys.info.text2}
              </Anchor>{" "}
              {lang.api_keys.info.text3}
            </Text>
          </Group>
        </Blockquote>
      )}

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          toolbarActions={toolbarActions}
          actionsCell={actionsCell}
          pageOrderName={"api_key_order"}
        />
      </TableLayoutBlock>
    </>
  );
};

export default ApiListing;
