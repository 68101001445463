import { AppShell, LoadingOverlay, Stack } from "@mantine/core";
import { useUIStore } from "../../../stores/UIStore";
import Header from "./header/Header";
import Navbar from "./navbar/Navbar";
import { useMemo } from "react";
import { Outlet } from "react-router-dom";
import "./ui2.scss";

const Main = () => {
  const mainLoading = useUIStore((s) => s.mainLoading);
  const navbarClosed = useUIStore((s) => s.navbarClosed);

  const disableNav = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_nav") === "true" ||
      urlParams.get("disableNav")
    );
  }, []);

  const disableHeading = useMemo(() => {
    const urlParams = new URLSearchParams(window.location.search);
    return (
      localStorage.getItem("disable_heading") === "true" ||
      urlParams.get("disableHeading")
    );
  }, []);

  return (
    <>
      <LoadingOverlay
        visible={mainLoading}
        zIndex={1000}
        overlayProps={{ blur: 2 }}
        w={"100%"}
        h={"100%"}
      />

      <AppShell
        navbar={{
          width: navbarClosed ? 75 : 240,
          collapsed: {
            desktop: disableNav,
            mobile: disableNav,
          },
          breakpoint: "sm",
        }}
        padding={0}
        styles={{
          navbar: {
            boxShadow: "none",
          },
        }}
        style={{
          fontFamily: "Inter",
        }}
        className="ui2"
      >
        <AppShell.Main component={Stack} h={"100%"} pt={60}>
          <Header />

          <Outlet />
        </AppShell.Main>

        {!disableNav && (
          <AppShell.Navbar style={{ border: 0, zIndex: 120 }}>
            <Navbar closed={navbarClosed} />
          </AppShell.Navbar>
        )}
      </AppShell>
    </>
  );
};

export default Main;
