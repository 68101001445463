import { rem, TextInput } from '@mantine/core'
import { IconDashboard, IconFileText, IconHome, IconSearch } from '@tabler/icons-react'
import { spotlight, Spotlight } from "@mantine/spotlight"

const actions = [
  {
    id: 'home',
    label: 'Home',
    description: 'Get to home page',
    onClick: () => console.log('Home'),
    leftSection: <IconHome style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
  },
  {
    id: 'dashboard',
    label: 'Dashboard',
    description: 'Get full information about current system status',
    onClick: () => console.log('Dashboard'),
    leftSection: <IconDashboard style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
  },
  {
    id: 'documentation',
    label: 'Documentation',
    description: 'Visit documentation to lean more about all features',
    onClick: () => console.log('Documentation'),
    leftSection: <IconFileText style={{ width: rem(24), height: rem(24) }} stroke={1.5} />,
  },
];

const GlobalSearch = () => {
  return (
    <>
      <TextInput
        placeholder='Rechercher : campagne, page, ...'
        size='md'
        radius={"lg"}
        leftSection={<IconSearch opacity={.5} size={24} />}
        ml={16}
        styles={{
          input: {
            paddingLeft: 48
          }
        }}
        onClick={spotlight.open}
        w={300}
        readOnly
      />
      <Spotlight
        actions={actions}
        nothingFound="Nothing found..."
        highlightQuery
        searchProps={{
          leftSection: <IconSearch style={{ width: rem(20), height: rem(20) }} stroke={1.5} />,
          placeholder: 'Search...',
        }}
        radius={"lg"}
      />
    </>
  )
}

export default GlobalSearch