import {
  Affix,
  Button,
  Divider,
  Group,
  Loader,
  Modal,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import {
  IconAddressBook,
  IconBuildingStore,
  IconCheck,
  IconChevronDown,
  IconChevronRight,
  IconCirclesRelation,
  IconDownload,
  IconKey,
  IconLoader,
  IconMail,
  IconPlus,
  IconRocket,
  IconRouteSquare2,
} from "@tabler/icons-react";
import mockup from "../../../assets/mockup-v2.png";
import { useMemo, useState } from "react";
import email_image from "../../../assets/email_image.png";
import linkedin_image from "../../../assets/linkedin_image.png";
import model_image from "../../../assets/model_image.png";
import list_image from "../../../assets/list_image.png";
import campaign_image from "../../../assets/campaign_image.png";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";

const DefaultContent = ({ active }) => {
  if (active !== "def") {
    return;
  }
  return (
    <Paper
      className="def-section section animate__animated animate__fadeInRight animate__faster"
      component={Group}
      justify="center"
      w={"100%"}
    >
      <img src={mockup} className="video" />
    </Paper>
  );
};

const EmailContent = ({ active }) => {
  if (active !== "email") {
    return;
  }
  return (
    <Paper className="email-section section  animate__animated animate__fadeInRight animate__faster">
      <img src={email_image} />
      <Text fw={400}>
        Pour commencer sur Magileads, la première étape consiste à connecter
        votre email expediteur. Cela vous permet d'adresser vos prospects avec
        votre propre adresse email et votre nom.
        <br />
        Il est possible de connecter plusieurs emails expediteurs à la
        plateforme.
      </Text>
      <Text fw={700}>
        NB : Magileads n’aura en aucun cas accès à votre boîte email.
      </Text>

      <Group justify="center" mt={8}>
        <Button
          size="md"
          variant="gradient"
          rightSection={<IconCirclesRelation />}
          gradient={{ from: "#ffd427", to: "#f6b033" }}
          radius={"md"}
        >
          Connectez votre Email
        </Button>
      </Group>
    </Paper>
  );
};

const LinkedinContent = ({ active }) => {
  if (active !== "linkedin") {
    return;
  }
  return (
    <Paper className="linkedin-section  section  animate__animated animate__fadeInRight animate__faster">
      <img src={linkedin_image} />
      <Text fw={400}>
        L’installation de l’extension Chrome vous permet d’importer vos leads
        depuis Linkedin vers Magileads, d’enrichir vos listes et d’envoyer des
        campagnes Inmail Linkedin depuis la plateforme.
        <br />
        Cette manipulation se fait une seule fois et vous permet de synchroniser
        les actions entre Magileads et Linkedin.
      </Text>
      <Text fw={700}>
        NB : Magileads n’aura en aucun cas accès à votre profil Linkedin.
      </Text>

      <Group justify="center" mt={8}>
        <Button
          size="md"
          variant="gradient"
          rightSection={<IconDownload />}
          gradient={{ from: "#4a979b", to: "#4e67ae" }}
          radius={"md"}
        >
          Installer l'extension Chrome
        </Button>
      </Group>
    </Paper>
  );
};

const ContactsContent = ({ active }) => {
  if (active !== "contacts") {
    return;
  }
  return (
    <Paper className="contacts-section  section  animate__animated animate__fadeInRight animate__faster">
      <img src={list_image} />
      <Text fw={400}>
        Sur Magileads, vos contacts sont centralisés dans l'onglet "Mes Listes".
        Vous pouvez les importer depuis diverses sources telles que LinkedIn,
        Google Maps, des fichiers CSV, etc. De plus, vous avez la possibilité de
        les enrichir grâce à l'IA.
        <br />
        Vous pouvez créer différentes listes en fonction de vos campagnes.
        Celles-ci seront toujours accessibles et prêtes à l'emploi.
      </Text>
      <Group justify="center" mt={8}>
        <Button
          size="md"
          variant="gradient"
          rightSection={<IconPlus />}
          gradient={{ from: "#ffd427", to: "#f6b033" }}
          radius={"md"}
        >
          Créer ma liste
        </Button>
      </Group>
    </Paper>
  );
};

const ModelsContent = ({ active }) => {
  if (active !== "models") {
    return;
  }
  return (
    <Paper className="models-section  section  animate__animated animate__fadeInRight animate__faster">
      <img
        src={model_image}
        style={{ width: "70%", margin: "0 auto", display: "block" }}
      />
      <Text fw={400} mt={8}>
        Sur Magileads, vous n'avez pas à ressaisir vos messages à chaque
        campagne. Créez vos modèles une seule fois et utilisez-les pour toutes
        vos campagnes ultérieures.
        <br />
        Tous vos modèles d'emails, de messages LinkedIn et de SMS seront
        centralisés dans l'onglet "Mes Modèles".
      </Text>
      <Group justify="center" mt={8}>
        <Button
          size="md"
          variant="gradient"
          rightSection={<IconRouteSquare2 />}
          gradient={{ from: "#4a979b", to: "#4e67ae" }}
          radius={"md"}
        >
          Mes modèles
        </Button>
      </Group>
    </Paper>
  );
};

const CampaignContent = ({ active }) => {
  if (active !== "campaign") {
    return;
  }
  return (
    <Paper className="campaign-section  section  animate__animated animate__fadeInRight animate__faster">
      <img
        src={campaign_image}
        style={{
          width: "100%",
          height: 300,
          margin: "0 auto",
          display: "block",
          position: "relative",
          marginBottom: -48,
        }}
      />
      <Text fw={400}>
        <span style={{ paddingRight: 150, display: "block" }}>
          Vos campagnes commencent par la création d'un{" "}
          <b>scénario multicanal</b> , vous permettant d'utiliser divers canaux
          pour atteindre vos cibles de manière efficace, en fonction de leur
          comportement et du rythme souhaité.
        </span>
        Que ce soit par email, LinkedIn, téléphone, tout est centralisé dans un
        schéma unique, garantissant que vous atteigniez vos cibles là où elles
        se trouvent.
      </Text>
      <Group justify="center" mt={8}>
        <Button
          size="md"
          variant="gradient"
          rightSection={<IconRocket />}
          gradient={{ from: "#ffd427", to: "#f6b033" }}
          radius={"md"}
        >
          Créer votre première campagne
        </Button>
      </Group>
    </Paper>
  );
};

const iconProps = {};

const GetStarted = () => {
  const [active, setActive] = useState("def");
  const [openedVideo, setOpenedVideo] = useState(false);

  const elements = useMemo(() => {
    return {
      def: {
        done: true,
        label: "Connectez votre Email Expéditeur",
        icon: <IconMail {...iconProps} />,
      },
      email: {
        done: true,
        label: "Connectez votre Email Expéditeur",
        icon: <IconMail {...iconProps} />,
      },
      linkedin: {
        done: true,
        label: "Connectez votre Compte LinkedIn",
        icon: <IconLinkedin {...iconProps} />,
      },
      contacts: {
        done: true,
        label: "Créez vos listes",
        icon: <IconAddressBook {...iconProps} />,
      },
      models: {
        done: true,
        label: "Créez vos modèles",
        icon: <IconRouteSquare2 {...iconProps} />,
      },
      campaign: {
        label: "Créez votre première campagne",
        icon: <IconRocket {...iconProps} />,
      },
    };
  }, []);

  return (
    <Stack gap={0} mx={0} w={"100%"} className="get-started">
      <Group w={"100%"} mt={8} wrap="nowrap" align="start" gap={32}>
        <Stack w={"40%"} className="buttons">
          <Title order={4} mt={16} fw={500} ff={"Inter"} mb={8}>
            🚀 Premiers pas
          </Title>
          {Object.keys(elements).map((key) => {
            let item = elements[key];

            if (key === "def") {
              return "";
            }

            return (
              <Button
                key={key}
                size="lg"
                w={"100%"}
                justify="start"
                className="new-button bump-stroke"
                radius={"lg"}
                variant={active === key ? "light" : "default"}
                onClick={() => {
                  setActive(active === key ? "def" : key);
                }}
                leftSection={
                  item.done ? (
                    <ThemeIcon
                      variant="light"
                      color="green"
                      size={"lg"}
                      radius={"lg"}
                    >
                      <IconCheck />
                    </ThemeIcon>
                  ) : (
                    <ThemeIcon
                      variant="light"
                      size={"lg"}
                      radius={"lg"}
                      color="secondary"
                    >
                      <IconLoader size={24} />
                    </ThemeIcon>
                  )
                }
                rightSection={
                  <IconChevronDown
                    size={24}
                    opacity={active === key ? 1 : 0.4}
                    style={{
                      transform: active === key ? "rotate(-90deg)" : "none",
                      transition: "all ease .3s",
                    }}
                  />
                }
                styles={{
                  label: {
                    marginLeft: 16,
                  },
                }}
                style={{
                  border: "1px solid #00000012"
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </Stack>

        <Stack
          w={"60%"}
          gap={0}
          mt={24}
          pos={"relative"}
          style={{ overflow: "hidden", height: 500 }}
          align="center"
        >
          <DefaultContent active={active} />

          <EmailContent active={active} />

          <LinkedinContent active={active} />

          <ContactsContent active={active} />

          <ModelsContent active={active} />

          <CampaignContent active={active} />
        </Stack>
      </Group>

      <Group
        className="layout-block animate__animated animate__fadeInRight animate__faster"
        gap={0}
        mx={0}
        w={"100%"}
        pb={16}
        mt={active === "def" ? -96 : -16}
        style={{
          borderRadius: 24,
          width: "100%",
          boxShadow: "#00000021 0 1px 3px",
          borderColor: "var(--mantine-color-secondary-4)",
          zIndex: 2,
        }}
        wrap="nowrap"
      >
        <Text
          fw={800}
          size="xl"
          variant="gradient"
          gradient={{ from: "#0cc0df", to: "#ffde59", deg: 90 }}
          // w={700}
          style={{
            textWrap: "nowrap"
          }}
           className="agence"
        >
          Agence, Marketer, Partenaire ?
        </Text>

        <Text fw={400} mx={"auto"} px={32} className="dispose" >
          Disposez de Magileads en <b>Marque blanche</b>, ou intégrez nos{" "}
          <b>API</b> dans votre environnement propre.
        </Text>

        <Group wrap="nowrap">
          <Button
            size="md"
            radius={16}
            mt={16}
            variant="light"
            color="secondary"
            leftSection={<IconBuildingStore size={24} />}
            w={230}
            className="new-button"
          >
            Marque Blanche
          </Button>

          <Button
            size="md"
            radius={16}
            mt={16}
            variant="light"
            color="secondary"
            leftSection={<IconKey size={24} />}
            w={230}
            className="new-button"
          >
            Bibliothèque API
          </Button>
        </Group>
      </Group>

      {active !== "def" && !openedVideo && (
        <Paper
          pos={"fixed"}
          bottom={64}
          right={16}
          w={200}
          h={100}
          style={{ zIndex: 4, overflow: "hidden", cursor: "pointer" }}
          radius={32}
          shadow="lg"
          className="affix-video animate__animated animate__zoomInRight"
          onClick={() => setOpenedVideo(true)}
        >
          <img src={mockup} width={"100%"} />
        </Paper>
      )}

      <Modal
        opened={openedVideo}
        size={"auto"}
        centered
        onClose={() => setOpenedVideo(false)}
        withCloseButton={false}
        radius={54}
        styles={{
          body: {
            padding: 8,
          },
        }}
      >
        <img src={mockup} width={1024} style={{ marginBottom: -6 }} />
      </Modal>
    </Stack>
  );
};

export default GetStarted;
