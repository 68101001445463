import { useLanguage } from "../../../stores/LanguageStore";
import { Group, NumberInput, Select, TextInput } from "@mantine/core";
import FilterValueSelector from "./FilterValueSelector";
import FilterDateSelector from "./FilterDateSelector";
import FilterLazyValue from "./FilterLazyValue";
import FilterLeadsCampaign from "./FilterLeadsCampaign";

const FilterValue = ({
  type = "text",
  value,
  setValue,
  valueOptions,
  disabled,
  getter,
  responseField,
  storeKey,
  setStoreKey,
  nameField,
  groups,
  label,
  singleSelectOptions,
}) => {
  const lang = useLanguage((s) => s.language);

  const getComponent = () => {
    if (type === "date") {
      return (
        <FilterDateSelector
          value={value}
          placeholder={"Date"}
          disabled={disabled}
          setValue={setValue}
        />
      );
    }
    if (type === "lazy") {
      return (
        <FilterLazyValue
          getter={getter}
          responseField={responseField}
          label={label}
          setValue={setValue}
          value={value}
          storeKey={storeKey}
          setStoreKey={setStoreKey}
          nameField={nameField}
          groups={groups}
        />
      );
    }
    if (type === "campaign") {
      return <FilterLeadsCampaign setValue={setValue} value={value} />;
    }
    if (valueOptions) {
      return (
        <FilterValueSelector
          label={label}
          options={valueOptions}
          value={value}
          setValue={setValue}
          disabled={disabled}
        />
      );
    }
    if (type === "number") {
      return (
        <NumberInput
          value={parseInt(value)}
          onChange={(v) => setValue(v ? v?.toString() : "")}
          placeholder={
            lang.table.toolbar.filter.values.value_placeholder + " *"
          }
          required
          autoFocus
          w={150}
          disabled={disabled}
        />
      );
    }
    if (type === "singleSelect") {
      return (
        <Select
          value={value}
          onChange={setValue}
          placeholder={
            lang.table.toolbar.filter.values.value_placeholder + " *"
          }
          data={singleSelectOptions}
          required
          autoFocus
          w={150}
          disabled={disabled}
        />
      );
    }
    return (
      <TextInput
        type={type}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        placeholder={lang.table.toolbar.filter.values.value_placeholder + " *"}
        required
        autoFocus
        w={150}
        disabled={disabled}
      />
    );
  };

  return <Group miw={175}>{getComponent()}</Group>;
};

export default FilterValue;
