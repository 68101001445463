import { useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  IconAddressBook,
  IconBoxModel,
  IconBrandLinkedin,
  IconCheck,
  IconDeviceMobileMessage,
  IconList,
  IconMail,
  IconMicrophone,
  IconRadar,
  IconSettings,
  IconSpeakerphone,
  IconTimelineEvent,
  IconTrash,
  IconUser,
  IconUserSearch,
  IconX,
} from "@tabler/icons-react";
import { deletePermission, updatePermission } from "../../../../api/AdminAPI";
import { notifications } from "@mantine/notifications";
import { updateSelf } from "../../../../api/UserAPI";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import { defaultPermissionsValue } from "./defaultPermissions";
import {
  ActionIcon,
  Anchor,
  Divider,
  Group,
  Loader,
  SimpleGrid,
  Stack,
  Switch,
  Tooltip,
} from "@mantine/core";

const icon_props = {
  size: 18,
};

const Permissions = ({ profile, setProfile }) => {
  const reseller = useSessionStore((s) => s.reseller);
  const lang = useLanguage((s) => s.language);
  const me = useProfileStore((s) => s.profile);

  const rights = useMemo(
    () => ({
      account: [
        {
          title: "Utilisateur",
          label: lang.administration.right.rights.list.switchTeamUser,
          description:
            lang.administration.right.rights.list.switchTeamUser_tooltip,
          value: "switchTeamUser",
        },
        {
          title: "Utilisateur",
          label: lang.administration.right.rights.list.switchOrganizationUser,
          description:
            lang.administration.right.rights.list
              .switchOrganizationUser_tooltip,
          value: "switchOrganizationUser",
        },
        {
          title: "Utilisateur",
          label: lang.administration.right.rights.list.subscribeUser,
          description:
            lang.administration.right.rights.list.subscribeUser_tooltip,
          value: "subscribeUser",
        },
      ],
      targeting: [
        {
          title: "Ciblage",
          label:
            lang.administration.right.rights.list.displayInternalEmailsPhones,
          description:
            lang.administration.right.rights.list
              .displayInternalEmailsPhones_tooltip,
          value: "displayInternalEmailsPhones",
        },
        {
          title: "Ciblage",
          label: lang.administration.right.rights.list.findMobilePhone,
          description:
            lang.administration.right.rights.list.findMobilePhone_tooltip,
          value: "findMobilePhone",
        },
        {
          title: "Ciblage",
          label: lang.administration.right.rights.list.findGoogleMapsPhone,
          description:
            lang.administration.right.rights.list.findGoogleMapsPhone_tooltip,
          value: "findGoogleMapsPhone",
        },
        {
          title: "Ciblage",
          label:
            lang.administration.right.rights.list.copyInternalContactListToPRM,
          description:
            lang.administration.right.rights.list
              .copyInternalContactListToPRM_tooltip,
          value: "copyInternalContactListToPRM",
        },
        {
          title: "Ciblage",
          label: lang.administration.right.rights.list.displayTargetingGoogle,
          description:
            lang.administration.right.rights.list
              .displayTargetingGoogle_tooltip,
          value: "displayTargetingGoogle",
        },
        {
          title: "Ciblage",
          label:
            lang.administration.right.rights.list.displayTargetingDatabase +
            ` ${reseller?.name}`,
          description:
            lang.administration.right.rights.list
              .displayTargetingDatabase_tooltip + ` ${reseller?.name}`,
          value: "displayTargetingDatabase",
        },
        {
          title: "Ciblage",
          label:
            lang.administration.right.rights.list.accessTargetingDatabase +
            ` ${reseller?.name}`,
          description:
            lang.administration.right.rights.list
              .displayTargetingGoogle_tooltip,
          value: "accessTargetingDatabase",
        },
        {
          title: "Serper",
          label: lang.administration.right.rights.list.serperFrance,
          description:
            lang.administration.right.rights.list.serperFrance_tooltip,
          value: "serperFrance",
        },
      ],
      enrich: [
        {
          title: "Enrichissement",
          label: lang.administration.right.rights.list.generateEmail,
          description:
            lang.administration.right.rights.list.generateEmail_tooltip,
          value: "generateEmail",
        },
        {
          title: "Enrichissement",
          label: lang.administration.right.rights.list.verifEmail,
          description: lang.administration.right.rights.list.verifEmail_tooltip,
          value: "verifEmail",
        },
      ],
      contact_lists: [
        {
          title: "Listes",
          label: lang.administration.right.rights.list.displayEmailsPhones,
          description:
            lang.administration.right.rights.list.displayEmailsPhones_tooltip,
          value: "displayEmailsPhones",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.copyContactListToPRM,
          description:
            lang.administration.right.rights.list.copyContactListToPRM_tooltip,
          value: "copyContactListToPRM",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.generateIceBreaker,
          description:
            lang.administration.right.rights.list.generateIceBreaker_tooltip,
          value: "generateIceBreaker",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.generateDatafieldAi,
          description:
            lang.administration.right.rights.list.generateDatafieldAi_tooltip,
          value: "generateDatafieldAi",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.aiPerplexity,
          description:
            lang.administration.right.rights.list.aiPerplexity_tooltip,
          value: "aiPerplexity",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.iceBreakerV35,
          description:
            lang.administration.right.rights.list.iceBreakerV35_tooltip,
          value: "iceBreakerV35",
        },
        {
          title: "Listes",
          label: lang.administration.right.rights.list.iceBreakerV40,
          description:
            lang.administration.right.rights.list.iceBreakerV40_tooltip,
          value: "iceBreakerV40",
        },
      ],
      models: [
        {
          title: "Modèles",
          label: lang.administration.right.rights.list.modelGenerator,
          description:
            lang.administration.right.rights.list.modelGenerator_tooltip,
          value: "modelGenerator",
        },
        {
          title: "Modèles",
          label:
            lang.administration.right.rights.list
              .createModelsLinkedinInvitation,
          description:
            lang.administration.right.rights.list
              .createModelsLinkedinInvitation_tooltip,
          value: "createModelsLinkedinInvitation",
        },
        {
          title: "Modèles",
          label:
            lang.administration.right.rights.list.createModelsLinkedinMessage,
          description:
            lang.administration.right.rights.list
              .createModelsLinkedinMessage_tooltip,
          value: "createModelsLinkedinMessage",
        },
        {
          title: "Modèles",
          label: lang.administration.right.rights.list.createModelsSms,
          description:
            lang.administration.right.rights.list.createModelsSms_tooltip,
          value: "createModelsSms",
        },
      ],
      campaign: [
        {
          title: "Campagnes",
          label: lang.administration.right.rights.list.priorityCampaign,
          description:
            lang.administration.right.rights.list.priorityCampaign_tooltip,
          value: "priorityCampaign",
        },
      ],
      prm: [
        {
          title: "PRM",
          label: lang.administration.right.rights.list.answsersAIMarkPositive,
          description:
            lang.administration.right.rights.list.answsersAIMarkPositive,
          value: "answsersAIMarkPositive",
        },
      ],
      emails: [
        {
          title: "Emails Expéd.",
          label:
            lang.administration.right.rights.list
              .integrationsEmailForceValidate,
          description:
            lang.administration.right.rights.list
              .integrationsEmailForceValidate_tooltip,
          value: "integrationsEmailForceValidate",
        },
        {
          title: "Emails Expéd.",
          label:
            lang.administration.right.rights.list
              .displayAddIntegrationsEmailMassMailing,
          description:
            lang.administration.right.rights.list
              .displayAddIntegrationsEmailMassMailing_tooltip,
          value: "displayAddIntegrationsEmailMassMailing",
        },
      ],
      linkedin: [
        {
          title: "Comptes LinkedIn",
          label:
            lang.administration.right.rights.list
              .linkedinConversationsExtractorAtWeekends,
          description:
            lang.administration.right.rights.list
              .linkedinConversationsExtractorAtWeekends_tooltip,
          value: "linkedinConversationsExtractorAtWeekends",
        },
        {
          title: "Comptes LinkedIn",
          label:
            lang.administration.right.rights.list
              .linkedinConnectionsExtractorAtWeekends,
          description:
            lang.administration.right.rights.list
              .linkedinConnectionsExtractorAtWeekends_tooltip,
          value: "linkedinConnectionsExtractorAtWeekends",
        },
      ],
    }),
    [lang, reseller.name]
  );

  const rightsTitles = useMemo(
    () => ({
      targeting: {
        title: lang.administration.right.titles.targeting,
        icon: <IconRadar {...icon_props} />,
        link: "/contact-lists/import/linkedin",
      },
      enrich: {
        title: lang.administration.right.titles.enrich,
        icon: <IconUserSearch {...icon_props} />,
        link: "/targeting",
      },
      contact_lists: {
        title: lang.administration.right.titles.contact_lists,
        icon: <IconList {...icon_props} />,
        link: "/contact-lists",
      },
      models: {
        title: lang.administration.right.titles.models,
        icon: <IconBoxModel {...icon_props} />,
        link: "/models",
      },
      workflows: {
        title: lang.administration.right.titles.workflows,
        icon: <IconTimelineEvent {...icon_props} />,
        link: "/models/workflow",
      },
      campaign: {
        title: lang.administration.right.titles.campaigns,
        icon: <IconSpeakerphone {...icon_props} />,
        link: "/campaign/create",
      },
      prm: {
        title: lang.administration.right.titles.prm,
        icon: <IconAddressBook {...icon_props} />,
        link: "/leads-tracking",
      },
      emails: {
        title: lang.administration.right.titles.emails,
        icon: <IconMail {...icon_props} />,
        link: "/senders/email",
      },
      linkedin: {
        title: lang.administration.right.titles.linkedin,
        icon: <IconBrandLinkedin {...icon_props} />,
        link: "/senders/linkedin",
      },
      account: {
        title: lang.administration.right.titles.account,
        icon: <IconUser {...icon_props} />,
        link: "/account-settings",
      },
      others: {
        title: lang.administration.right.titles.others,
        icon: <IconSettings {...icon_props} />,
      },
      limit_emails: {
        title: lang.administration.right.titles.limit_emails,
        icon: <IconMail {...icon_props} />,
        link: "/senders/email",
      },
      limit_linkedin: {
        title: lang.administration.right.titles.limit_linkedin,
        icon: <IconBrandLinkedin {...icon_props} />,
        link: "/senders/linkedin",
      },
      limit_sms: {
        title: lang.administration.right.titles.limit_sms,
        icon: <IconDeviceMobileMessage {...icon_props} />,
        link: "/models/sms",
      },
      limit_smv: {
        title: lang.administration.right.titles.limit_smv,
        icon: <IconMicrophone {...icon_props} />,
        link: "/models/smv",
      },
    }),
    [lang]
  );

  const [loading, setLoading] = useState(false);

  const handleCheck = ({ value, checked }) => {
    setLoading(value);
    updatePermission({
      permission_name: value,
      user_id: profile?.id,
      value: checked,
    })
      .then((response) => {
        notifications.show({
          message: lang.administration.right.rights.changed,
          color: "tertiary.8",
        });
        if (profile.id === me.id) {
          updateSelf(true);
        }
        setProfile((prev) => {
          let index = profile.permissions.findIndex((x) => x.name === value);
          let newProfile = { ...prev };

          newProfile.permissions[index] = {
            ...newProfile.permissions[index],
            value: checked,
          };

          return newProfile;
        });
      })
      .finally(() => setLoading(null));
  };

  const handleDelete = (field) => {
    setLoading(field);
    deletePermission({
      permission_name: field,
      user_id: profile?.id,
    })
      .then((response) => {
        notifications.show({
          message: lang.administration.right.rights.deleted,
          color: "tertiary.8",
        });
        setProfile((prev) => {
          let index = profile.permissions.findIndex((x) => x.name === field);
          let newProfile = { ...prev };

          newProfile.permissions[index] = {
            ...newProfile.permissions[index],
            value: defaultPermissionsValue[field],
          };

          return newProfile;
        });
      })
      .finally(() => setLoading(null));
  };

  const getDisabled = (field) => {
    if (loading === field || loading) {
      return true;
    }
    if (me?.level === "admin") {
      return false;
    }
    if (!profile?.permissions?.find((x) => x.name === field)?.is_editable) {
      return true;
    }
    return false;
  };

  return (
    <Stack style={{ overflow: "auto" }} flex={"1 1 auto"} h={0}>
      {Object.keys(rights).map((key) => (
        <Stack key={key}>
          <Group>
            {rightsTitles[key]?.icon}
            <Anchor
              fw={500}
              href={rightsTitles[key]?.link}
              c="black"
              target="_blank"
            >
              {rightsTitles[key]?.title}
            </Anchor>
          </Group>
          <SimpleGrid cols={3}>
            {rights[key].map((item) => (
              <Group
                key={item?.value}
                className="hover-source"
                align="start"
                wrap="nowrap"
              >
                <Tooltip label={item?.description} refProp="rootRef">
                  <Switch
                    disabled={getDisabled(item.value)}
                    label={item.label}
                    onChange={(e) =>
                      handleCheck({
                        value: item?.value,
                        checked: e.target.checked,
                      })
                    }
                    checked={
                      profile?.permissions?.find((x) => x.name === item?.value)
                        ?.value === true
                    }
                    color="primary"
                    thumbIcon={
                      loading === item?.value ? (
                        <Loader type="oval" size={9} />
                      ) : profile?.permissions?.find(
                          (x) => x.name === item?.value
                        )?.value === true ? (
                        <IconCheck size={11} />
                      ) : (
                        <IconX size={11} color="red" />
                      )
                    }
                  />
                </Tooltip>
                <ActionIcon
                  disabled={getDisabled(item.value)}
                  color="red"
                  size={"sm"}
                  variant="light"
                  className="hover-target"
                  ml={"auto"}
                  onClick={(e) => handleDelete(item?.value)}
                >
                  <IconTrash size={16} />
                </ActionIcon>
              </Group>
            ))}
          </SimpleGrid>

          <Divider />
        </Stack>
      ))}
    </Stack>
  );
};

export default Permissions;
