import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Anchor,
  Group,
  HoverCard,
  NumberFormatter,
  Stack,
  Text,
} from "@mantine/core";
import { status_list } from "../../../leadsTracking/utils";
import {
  useAccountsStore,
  useConfigStore,
} from "../../../../../stores/ConfigStore";
import { useMemo } from "react";
import { useProfileStore } from "../../../../../stores/UserStore";
import { Link } from "react-router-dom";
import { getLimit } from "../../../../../functions/utilFunctions";

const ListsArgumentsGoogle = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack mt={"xs"} gap={"xs"}>
      <Group>
        <Text mr={"xs"}>{lang.contact_lists.jobs.completed.google_links}</Text>
        {item?.arguments?.googleMapsSearchUrls.length === 1 ? (
          <Anchor
            href={item?.arguments?.googleMapsSearchUrls[0] || ""}
            target="_blank"
            fw={500}
            fz={12}
            truncate="end"
          >
            {item?.arguments?.googleMapsSearchUrls[0] || ""}
          </Anchor>
        ) : (
          <HoverCard>
            <HoverCard.Target>
              <Text fw={500}>
                {item?.arguments?.googleMapsSearchUrls?.length}{" "}
                <span style={{ opacity: 0.5, fontWeight: 400, fontSize: 12 }}>
                  {lang.contact_lists.jobs.completed.hover_to_show}
                </span>
              </Text>
            </HoverCard.Target>

            <HoverCard.Dropdown>
              <Stack maxHeight={150} overflow={"auto"}>
                {item?.arguments?.googleMapsSearchUrls?.map((link) => (
                  <Anchor
                    key={link}
                    href={link || "#"}
                    target="_blank"
                    fontSize={12}
                    truncate="end"
                    flexGrow={1}
                    c="inherit"
                  >
                    {link || ""} <br />
                  </Anchor>
                ))}
              </Stack>
            </HoverCard.Dropdown>
          </HoverCard>
        )}
      </Group>
      <Text size="sm">
        {lang.contact_lists.jobs.completed.max_profiles}{" "}
        <b>{item?.arguments?.maxResults}</b>
      </Text>
    </Stack>
  );
};

const ListsArgumentsLinkedIns = ({ item }) => {
  const lang = useLanguage((s) => s.language);
  const accounts = useAccountsStore((s) => s.linkedin_accounts);
  const profile = useProfileStore((s) => s.profile);

  const account = useMemo(() => {
    return accounts.find((x) => x.id === item.arguments?.linkedinAccountId);
  }, [accounts, item.arguments?.linkedinAccountId]);

  const linkName = {
    LinkedinExtractSalesNavigatorPeoplesSearch:
      "linkedinSalesNavigatorSearchUrl",
    LinkedinExtractSalesNavigatorPeoplesSearchAlternative:
      "linkedinSalesNavigatorSearchUrl",
    LinkedinExtractPeoplesSearch: "linkedinPeopleSearchUrl",
    LinkedinExtractPostLikers: "linkedinPostUrl",
    LinkedinExtractPostCommenters: "linkedinPostUrl",
    LinkedinExtractConnections: "none",
    LinkedinExtractSalesNavigatorCompaniesSearch:
      "linkedinSalesNavigatorSearchUrl",
    LinkedinExtractSalesNavigatorCompaniesSearchAlternative:
      "linkedinSalesNavigatorSearchUrl",
  };

  const limitReached = useMemo(() => {
    let day = {
      used:
        getLimit("maxLinkedinInteractionsPerDay", profile, "value") -
        getLimit("maxLinkedinInteractionsPerDay", profile, "remaining"),
      limit: getLimit("maxLinkedinInteractionsPerDay", profile, "value"),
    };
    let month = {
      used:
        getLimit("maxLinkedinInteractionsPerMonth", profile, "value") -
        getLimit("maxLinkedinInteractionsPerMonth", profile, "remaining"),
      limit: getLimit("maxLinkedinInteractionsPerMonth", profile, "value"),
    };

    let reached_day = day.limit <= day.used && day.used !== 0;
    let reached_month = month.limit <= month.used && month.used !== 0;
    return {
      type: reached_day ? "day" : "month",
      isReached: reached_day || reached_month,
    };
  }, [profile]);

  if (!linkName[item.type]) return "";
  return (
    <Stack mt={"xs"} gap={"xs"}>
      {limitReached?.isReached && (
        <Text c={"red"} fw={600} size="sm">
          {limitReached?.type === "day"
            ? lang.contact_lists.jobs.completed.limit_reached_daily
            : lang.contact_lists.jobs.completed.limit_reached_monthly}
          <Link
            style={{ marginLeft: 6, fontWeight: 600 }}
            to={"/account-settings/subscription"}
          >
            {lang.contact_lists.jobs.completed.upgrade_account}
          </Link>
        </Text>
      )}
      {item.type !== "LinkedinExtractConnections" && (
        <Stack>
          <Group wrap="nowrap">
            <Text size="sm">
              {lang.contact_lists.jobs.completed.linkedin_links}
            </Text>
            <Anchor
              href={item?.arguments?.[linkName?.[item.type]] || ""}
              target="_blank"
              fw={500}
              fz={12}
              truncate="end"
              maw={450}
            >
              {item?.arguments?.[linkName?.[item.type]] || ""}
            </Anchor>
          </Group>
        </Stack>
      )}
      {item?.arguments?.linkedinAccountId && (
        <Group>
          <Text size="sm">
            {lang.contact_lists.jobs.completed.linkedin_account}{" "}
            <Anchor
              href={`/senders/linkedin/?highlight=${item?.arguments?.linkedinAccountId}`}
              target="_blank"
              fw={500}
              ml={8}
            >
              {account?.name || ""} #{item?.arguments?.linkedinAccountId}
            </Anchor>
          </Text>

          {account && !account?.is_valid && (
            <Text c={"red"} fw={600} size="sm">
              {lang.contact_lists.jobs.completed.not_valid_account}
            </Text>
          )}
        </Group>
      )}
      <Text size="sm">
        {lang.contact_lists.jobs.completed.max_profiles}{" "}
        <b style={{ marginLeft: 8 }}>{item?.arguments?.maxResults}</b>
      </Text>
    </Stack>
  );
};

export function handleJobItem({ item, fromLists, isProgress }) {
  const lang = useLanguage.getState().language;
  if (item.type === "Split") {
    return (
      <Text fz={12} mt={"xs"}>
        {lang.contact_lists.jobs.list}{" "}
        <b>
          {" "}
          {fromLists?.name || ""} #{fromLists?.id}{" "}
        </b>
      </Text>
    );
  }
  if (item.type === "Merge") {
    return (
      <Group gap={4} mt={"xs"}>
        {lang.contact_lists.jobs.list}
        {fromLists.map((list) => (
          <Text size="sm" fw={500} key={list.id} mr={8}>
            {list?.name || ""} #{list.id}
          </Text>
        ))}
      </Group>
    );
  }
  if (item.type === "ContactListCopyContactListToPrm") {
    return (
      <Stack mt={"xs"} gap={"xs"}>
        <Text size="sm">
          {lang.contact_lists.jobs.completed.status}{" "}
          <b>
            {status_list()?.find((x) => x.value === item?.arguments?.status)
              .label || item?.arguments?.status}
          </b>
        </Text>
        {!isProgress && (
          <>
            <Text size="sm">
              <b style={{ marginRight: 4 }}>
                <NumberFormatter
                  value={item.import_contacts_response?.contacts_added}
                  thousandSeparator=" "
                />
              </b>{" "}
              {lang.contact_lists.jobs.completed.contacts_added}
            </Text>
            <Text size="sm">
              <b style={{ marginRight: 4 }}>
                <NumberFormatter
                  value={
                    item.import_contacts_response?.contacts_already_existing
                  }
                  thousandSeparator=" "
                />
              </b>{" "}
              {lang.contact_lists.jobs.completed.contacts_already_existing}
            </Text>
          </>
        )}
      </Stack>
    );
  }
  if (
    item.type === "IceBreaker" ||
    item.type === "DatafieldAi" ||
    item.type === "DatafieldEnrich"
  ) {
    return (
      <Stack mt={"xs"} gap={"xs"}>
        {item?.arguments?.subject && (
          <Group gap={0} wrap="nowrap" align="start">
            <Text mr={8} fw={600} fz={12}>
              {lang.contact_lists.jobs.completed.subject}:
            </Text>
            <Text fz={12}>{item?.arguments?.subject}</Text>
          </Group>
        )}
        {item?.arguments?.prompt && (
          <Group gap={0} wrap="nowrap" align="start">
            <Text mr={8} fw={600} fz={12}>
              {lang.contact_lists.datafield_content.dialog.prompt}:
            </Text>
            <Text fz={12}>{item?.arguments?.prompt}</Text>
          </Group>
        )}
        {item?.arguments?.language && (
          <Group gap={0} wrap="nowrap">
            <Text mr={8} fw={600} fz={12}>
              {lang.contact_lists.jobs.completed.language}:
            </Text>
            <Text fz={12}>
              {
                lang.contact_lists.jobs.completed.language_maps[
                  item?.arguments?.language
                ]
              }
            </Text>
          </Group>
        )}
        {item?.arguments?.temperature && (
          <Group gap={0} wrap="nowrap">
            <Text mr={8} fw={600} fz={12}>
              {lang.contact_lists.jobs.completed.temperature}:
            </Text>
            <Text fz={12}>{item.arguments.temperature}</Text>
          </Group>
        )}
        {item?.arguments?.datafieldId && (
          <Group gap={0} wrap="nowrap">
            <Text mr={8} fw={600} fz={12}>
              {lang.contact_lists.jobs.completed.field}:
            </Text>
            <Text fz={12}>
              {" "}
              {
                useConfigStore
                  .getState()
                  .datafields.find((x) => x.id === item?.arguments?.datafieldId)
                  ?.name
              }{" "}
              #{item?.arguments?.datafieldId}
            </Text>
          </Group>
        )}
        {item?.arguments?.openAiApiKeyId && (
          <Group>
            <Text size="sm" fw={600}>
              {lang.contact_lists.jobs.completed.openai_api_key}:
              <Anchor
                href={`/extra/external-api-keys?highlight=${item?.arguments?.openAiApiKeyId}`}
                target="_blank"
                fw={500}
                ml={8}
              >
                #{item?.arguments?.openAiApiKeyId}
              </Anchor>
            </Text>
          </Group>
        )}
      </Stack>
    );
  }
  if (
    (item.type === "Import" ||
      item.type === "Copy" ||
      item.type === "CopyTo") &&
    !isProgress
  ) {
    return (
      <Stack gap={"xs"} mt={"xs"}>
        <Text size="sm">
          <b style={{ marginRight: 4 }}>
            <NumberFormatter
              value={item.import_contacts_response?.contacts_added}
              thousandSeparator=" "
            />
          </b>
          {lang.contact_lists.jobs.completed.contacts_added}
        </Text>
        <Text size="sm">
          <b style={{ marginRight: 4 }}>
            <NumberFormatter
              value={item.import_contacts_response?.contacts_updated}
              thousandSeparator=" "
            />
          </b>
          {lang.contact_lists.jobs.completed.contacts_updated}
        </Text>
        <Text size="sm">
          <b style={{ marginRight: 4 }}>
            <NumberFormatter
              value={item.import_contacts_response?.contacts_deleted}
              thousandSeparator=" "
            />
          </b>
          {lang.contact_lists.jobs.completed.contacts_deleted}
        </Text>
        <Text size="sm">
          <b style={{ marginRight: 4 }}>
            <NumberFormatter
              value={item.import_contacts_response?.contacts_ignored}
              thousandSeparator=" "
            />
          </b>
          {lang.contact_lists.jobs.completed.contacts_ignored}
        </Text>
        <Text size="sm">
          <b style={{ marginRight: 4 }}>
            <NumberFormatter
              value={
                item.import_contacts_response?.contacts_with_ignored_fields
              }
              thousandSeparator=" "
            />
          </b>
          {lang.contact_lists.jobs.completed.contacts_with_ignored_fields}
        </Text>
      </Stack>
    );
  }

  if (item.type === "GoogleExtractMapsSearch") {
    return <ListsArgumentsGoogle item={item} />;
  }
  return <ListsArgumentsLinkedIns item={item} />;
}
