import { useCallback, useMemo, useState } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import TableLayoutBlock from "../../../layouts/Table/TableLayoutBlock";
import MainTable from "../../../layouts/Table/MainTable";
import {
  ActionIcon,
  Badge,
  Button,
  Group,
  Loader,
  Switch,
  Tooltip,
} from "@mantine/core";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { Link, useParams, useRouteLoaderData } from "react-router-dom";
import { IconEdit, IconUserCheck, IconUserX } from "@tabler/icons-react";
import { useListingLoader } from "../../../components/hooks/ListingHooks";
import {
  disableUser,
  enableUserAccount,
  getOrgUsers,
  getResellerUsers,
  getTeamUsers,
} from "../../../api/UserAPI";
import numberColumn from "../../../layouts/Table/columns/numberColumn";
import { nameColumn } from "../../../layouts/Table/columns/nameColumn";
import { parseDateFuture } from "../../../functions/dates";
import { notifications } from "@mantine/notifications";
import ResellerInfo from "../resellers/howto/ResellerInfo";
import CreateUser from "../resellers/createUser/CreateUserButton";
import InviteToCommunity from "./InviteToCommunity";
import PageTitle from "../../../components/View/PageTitle";
import ResellerPointInput from "./ResellerPointInput";
import ResellerExtension from "../resellers/howto/ResellerExtension";
import DeleteAccount from "../admin/DeleteAccount";

const ActivationCell = ({ row, value, setTableLoading }) => {
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(false);

  const toggleUser = () => {
    setLoading(true);
    let func = value === true ? disableUser : enableUserAccount;
    func(row.id)
      .then((response) => {
        notifications.show({
          message: lang.users_list.row_highlight.updated,
          color: "tertiary.8",
        });
        setTableLoading(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Group w={"100%"}>
      <Tooltip
        label={value ? lang.users_list.deactivate : lang.users_list.activate}
        refProp="rootRef"
      >
        <Switch
          size="sm"
          defaultChecked={value}
          disabled={loading}
          onChange={() => toggleUser()}
          thumbIcon={
            loading ? (
              <Loader type="oval" size={9} />
            ) : !value ? (
              <IconUserX size={11} color="red" />
            ) : (
              <IconUserCheck size={11} color="tertiary.8" />
            )
          }
        />
      </Tooltip>
    </Group>
  );
};

const UsersList = () => {
  const lang = useLanguage((s) => s.language);

  const { resellerId, teamId, orgId } = useParams();
  const team = useRouteLoaderData("team");
  const org = useRouteLoaderData("org");
  const reseller = useRouteLoaderData("reseller");

  const apiRef = useGridApiRef();
  const [loading, setLoading] = useState(true);
  const [rows, setRows] = useState([]);

  const columns = useMemo(() => {
    const result = [
      numberColumn(),
      {
        field: "activated",
        headerName: lang.users_list.columns.activated,
        width: 75,
        renderCell: ({ row, value }) => {
          return (
            <ActivationCell
              row={row}
              setTableLoading={setLoading}
              value={value}
            />
          );
        },
      },
      nameColumn({
        field: "first_name",
        headerName: lang.users_list.columns.first_name,
        width: 100,
      }),
      {
        field: "last_name",
        headerName: lang.users_list.columns.last_name,
        width: 130,
      },
      {
        field: "email",
        headerName: lang.users_list.columns.email,
        minWidth: 150,
      },
      {
        field: "resellers",
        headerName: lang.users_list.columns.level,
        minWidth: 150,
        renderCell: ({ value }) => {
          let level = value?.find(
            (x) => x.id === parseInt(resellerId)
          )?.user_level;

          return level
            ? lang.teams.columns["user_level_value_" + level]
            : " - ";
        },
      },
      {
        field: "subscriptions",
        headerName: lang.users_list.columns.subscription,
        width: 110,
        renderCell: ({ row, value }) => {
          return value?.active && !value?.trial ? (
            <Tooltip
              label={`${lang.referral.end_date} ${parseDateFuture(
                value?.end_date
              )}`}
            >
              <Badge color={"tertiary.8"} size="xs">
                {lang.referral.active}
              </Badge>
            </Tooltip>
          ) : (
            <Tooltip
              label={
                value?.trial
                  ? `${lang.referral.trial} ${parseDateFuture(value?.end_date)}`
                  : lang.referral.free_version
              }
            >
              <Badge color={"red"} size="xs">
                {lang.referral.not_active}{" "}
              </Badge>
            </Tooltip>
          );
        },
      },
      {
        field: "linkedin_accounts",
        headerName: lang.linkedin_accounts.browser_tab_title,
        minWidth: 130,
        cellClassName: "no-padding",
        renderCell: ({ row }) => (
          <ResellerPointInput row={row} permission="maxIntegrationsLinkedin" />
        ),
      },
      {
        field: "email_accounts",
        headerName: lang.emails_account.browser_tab_title,
        minWidth: 140,
        cellClassName: "no-padding",
        renderCell: ({ row }) => (
          <ResellerPointInput row={row} permission="maxIntegrationsEmailSMTP" />
        ),
      },
      {
        field: "enrich_credit",
        headerName: "Crédits Enrichissements",
        minWidth: 140,
        cellClassName: "no-padding",
        renderCell: ({ row }) => (
          <ResellerPointInput
            row={row}
            permission="maxEmailsGeneratedPerMonth"
          />
        ),
      },
    ];

    return result;
  }, [
    lang.emails_account.browser_tab_title,
    lang.linkedin_accounts.browser_tab_title,
    lang.referral.active,
    lang.referral.end_date,
    lang.referral.free_version,
    lang.referral.not_active,
    lang.referral.trial,
    lang.teams.columns,
    lang.users_list.columns.activated,
    lang.users_list.columns.email,
    lang.users_list.columns.first_name,
    lang.users_list.columns.last_name,
    lang.users_list.columns.level,
    lang.users_list.columns.subscription,
    resellerId,
  ]);

  const actionsCell = useCallback(({ row }) => {
    return (
      <Group wrap="nowrap" gap={8}>
        <Tooltip
          label={
            !row.activated
              ? lang.errors.main.user_account_disabled
              : lang.global.edit
          }
        >
          <ActionIcon
            component={!row.activated ? undefined : Link}
            to={`${row.id}`}
            variant="subtle"
            disabled={!row.activated}
          >
            <IconEdit size={18} />
          </ActionIcon>
        </Tooltip>

        <DeleteAccount
          profile={row}
          onSuccess={() => setLoading(true)}
          inTable
        />
      </Group>
    );
  }, []);

  const toolbarActions = useCallback(
    ({ selectedIds }) => (reseller ? <ResellerInfo reseller={reseller} /> : ""),
    [reseller]
  );

  useListingLoader({
    loading,
    setLoading,
    listGetter: () =>
      resellerId
        ? getResellerUsers({
            reseller_id: parseInt(resellerId),
            include_subscriptions: true,
            include_resellers: true,
            // include_teams: true,
            // include_organizations: true,
          })
        : orgId
        ? getOrgUsers(parseInt(orgId))
        : getTeamUsers(parseInt(teamId)),
    responseField: "users_list",
    setRows,
  });

  return (
    <>
      <Group
        className="layout-block top"
        w={"100%"}
        gap={0}
        justify="space-between"
      >
        <PageTitle
          title={`${reseller?.name || team?.name || org?.name} : ${
            lang.users_list.main_help
          }`}
        />

        {resellerId && (
          <Group>
            <ResellerExtension id={reseller?.id} name={reseller?.name} />

            <Button
              component={Link}
              variant="light"
              to={".."}
              leftSection={<IconEdit size={18} />}
            >
              {lang.resellers.edit.button_tooltip}
            </Button>

            <CreateUser />
          </Group>
        )}
        {!resellerId && (
          <InviteToCommunity current={team || org} isOrg={!!org} />
        )}
      </Group>

      <TableLayoutBlock>
        <MainTable
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          loading={loading}
          setLoading={setLoading}
          id={"list-table"}
          actionsCell={actionsCell}
          pageOrderName={"resellers_order"}
          actionsCellWidth={75}
          toolbarActions={toolbarActions}
        />
      </TableLayoutBlock>
    </>
  );
};

export default UsersList;
