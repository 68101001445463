import { Avatar, Button, Group, Menu, Stack, Text } from "@mantine/core";
import {
  IconChevronDown,
  IconFileDollar,
  IconLogout,
  IconSettings,
  IconUser,
} from "@tabler/icons-react";
import { useProfileStore, useSessionStore } from "../../../../stores/UserStore";
import { useMemo, useState } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { Link, useNavigate } from "react-router-dom";
import { modals } from "@mantine/modals";
import HeaderTools from "../../../../layouts/Header/HeaderTools";

const iconSize = 21;

const HeaderProfile = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);

  const [opened, setOpened] = useState(false);

  const links = useMemo(() => {
    return [
      [
        {
          icon: <IconUser size={iconSize} />,
          name: lang.header.menus.link_groups.profile,
          url: "/account-settings/profile",
        },
        {
          icon: <IconSettings size={iconSize} />,
          name: "Paramètres",
          url: "/settings",
        },
        {
          icon: <IconFileDollar size={iconSize} />,
          name: "Facturation",
          url: "/community/organizations",
        },
      ],
    ];
  }, [lang]);

  const navigate = useNavigate();
  const handleLogout = () => {
    function main() {
      let s = useSessionStore.getState();
      s.setUserSwitch(null);
      s.setSession(null);
      let api_url = localStorage.getItem("api_url");

      localStorage.clear();

      setTimeout(() => {
        let p = useProfileStore.getState();
        p.setProfile(null);
        navigate("/login?logged_out=true");

        localStorage.setItem("api_url", api_url);
      }, 50);
    }

    modals.openConfirmModal({
      title: "Se déconnecter ?",
      children: (
        <Text>Êtes-vous sûr de vouloir déconnecter de la plateforme ?</Text>
      ),
      labels: {
        confirm: lang.header.menus.logout,
        cancel: lang.components.navigation_blocker.rest,
      },
      confirmProps: { color: "red" },
      onConfirm: main,
      onCancel: () => modals.closeAll(),
    });
  };

  return (
    <Menu opened={opened} onChange={setOpened} offset={0} withArrow width={250}>
      <Menu.Target>
        <Button
          rightSection={<IconChevronDown size={16} color="#000" />}
          variant="subtle"
          px={12}
          size="lg"
          color="white"
        >
          <Group gap={4}>
            <Avatar src={profile?.avatar} size={40} color="white" />
          </Group>
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Button
          w={"100%"}
          variant="subtle"
          mih={0}
          h={52}
          justify="start"
          p={0}
          mb={4}
          color="black"
          component={Link}
          to={"/account-settings/profile"}
        >
          <Group gap={4} p={12} wrap="nowrap">
            <Avatar src={profile?.avatar} size={40} color="white" />
            <Stack ml={"sm"} ta={"start"} gap={0} mr={8}>
              <Text fw={600} truncate="end" w={170}>
                {profile?.first_name} {profile?.last_name}
              </Text>
              <Text opacity={0.5} fz={"sm"}>
                #{profile?.id}
              </Text>
            </Stack>
          </Group>
        </Button>
        <Menu.Divider mt={0} />
        {links.map((group, index) => (
          <Stack key={index} gap={0}>
            {group.map((item, i) => {
              if (item.children) {
                return <HeaderTools key={i} item={item} />;
              }
              return (
                <Menu.Item
                  leftSection={item.icon}
                  component={Link}
                  to={item.url}
                  key={item.url}
                  fz={13}
                >
                  <Group ml={8}>{item.name}</Group>
                </Menu.Item>
              );
            })}
            {index < links.length - 1 && <Menu.Divider />}
          </Stack>
        ))}
        <Menu.Divider />
        <Menu.Item
          leftSection={<IconLogout />}
          onClick={handleLogout}
          color="red"
        >
          <Group ml={5} fw={500}>
            {lang.header.menus.logout}
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

export default HeaderProfile;
