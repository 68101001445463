import { lazy, useCallback, useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import {
  Button,
  Group,
  LoadingOverlay,
  Stack,
  Stepper,
  Tabs,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconArrowAutofitLeft,
  IconChevronLeft,
  IconChevronRight,
  IconCopy,
  IconFile,
} from "@tabler/icons-react";
import StepMapping from "./StepMapping";
import MappingStats from "./MappingStats";
import system_map from "./default_system_fields.json";
import StepImportSettings from "./StepImportSettings";
import { useConfigStore } from "../../../../../stores/ConfigStore";
import { executeSequentially } from "../../../../../functions/utilFunctions";
import {
  createList,
  enrichList,
  importContacts,
} from "../../../../../api/ContactAPI";
import StepCopyPaste from "./StepCopyPaste";
import { modals } from "@mantine/modals";
import { IconArrowAutofitRight } from "@tabler/icons-react";
import { useProfileStore } from "../../../../../stores/UserStore";
const contactsLimit = 50000;

const StepImportFile = lazy(() => import("./StepImportFile"));

const ManualImport = ({
  onFinish,
  isCopyPaste,
  listProfile,
  listId,
  isUnsub,
  isBlacklist,
}) => {
  const lang = useLanguage((s) => s.language);
  const [activeStep, setActiveStep] = useState(1);

  const defaultFields = useMemo(() => {
    let result = {};

    Object.keys(system_map).forEach((key) => {
      system_map[key].forEach((value) => {
        result[value] = key;
      });
    });

    return result;
  }, []);

  const handleNext = useCallback(() => {
    setActiveStep((prev) => prev + (isUnsub ? 2 : 1));
    setShouldWarn?.(true);
  }, [isUnsub]);

  const handlePrev = () => {
    setActiveStep((prev) => prev - 1);
  };

  const [file, setFile] = useState(null);
  const [data, setData] = useState(null);
  const [sheetNames, setSheetNames] = useState(null);
  const [sheets, setSheets] = useState([]);
  const [merge, setMerge] = useState(false);
  const [fields, setFields] = useState(isBlacklist ? {} : defaultFields);
  const [consent, setConsent] = useState(true);
  const [importing, setImporting] = useState(false);
  const [headerLine, setHeaderLine] = useState(1);
  const [lines, setLines] = useState([1, 0]);

  // Copy-paste
  const [pastedText, setPastedText] = useState("");
  const [separator, setSeparator] = useState("0");

  const getNextDisabled = () => {
    if (activeStep === 1) {
      return !file || data?.length === 0 || !consent;
    }
    if (activeStep === 2) {
      let statFields = {};
      Object.keys(fields || {}).forEach((field) => {
        if (data) {
          if (Object.keys(data?.[0] || {})?.includes(field)) {
            statFields = { ...statFields, [field]: fields[field] };
          }
        }
      });
      return Object.values(statFields).filter((x) => !!x).length === 0;
    }
    return false;
  };

  const handleImport = useCallback(
    async ({ name, ignoreContactErrors, enrich, list }) => {
      setImporting(true);
      const datafields = useConfigStore.getState().datafields;
      const parsedFields = {};
      Object.keys(data[0]).forEach((key) => {
        parsedFields[key] = datafields.find(
          (x) => x.identifier_placeholder === fields[key]
        )?.id;
      });

      let contacts = data.map((contact) => {
        let props = [];
        Object.keys(contact).forEach((column) => {
          if (parsedFields[column] && contact[column]) {
            props.push({
              data_field_id: parsedFields[column],
              value: contact[column]?.toString(),
            });
          }
        });
        return {
          properties: props,
        };
      });

      // Divide requests
      async function mainFunction(id) {
        let requests = [];
        const numberOfUploads = Math.ceil(contacts.length / contactsLimit);

        const importFunction = (slicedData) => {
          let formData = new FormData();
          var file = new Blob([JSON.stringify(slicedData)], {
            type: "application/json",
          });
          formData.append("contacts", file, "data.json");
          formData.append("ignore_errors", ignoreContactErrors);

          return importContacts(id, formData);
        };

        for (let i = 0; i < numberOfUploads; i++) {
          // const contactsToUpload = Math.min(contactsLimit, (totalContacts.length) - (i * contactsLimit));
          const sliced = contacts.slice(
            i * contactsLimit,
            (i + 1) * contactsLimit
          );

          requests.push(() => importFunction(sliced));
        }
        return executeSequentially(requests)
          .catch((err) => {
            console.log(err);
            // let state_message = err.response.data?.state_message;
            // if (state_message === "data_validation_exception") {
            //   setErrors(err.response.data.errors);
            // } else if (
            //   state_message === "multiple_contact_info_already_exist"
            // ) {
            //   setErrors(err.response.data.errors);
            // } else if (state_message === "import_limit_exceeded") {
            //   setExceeded(true);
            // }
            // return setExceeded(false);
          })
          .then(() => {
            setShouldWarn(false);

            if (enrich) {
              enrichList({ contact_list_id: id, generate_email: true });
            }

            setTimeout(() => {
              onFinish?.(id);
              setImporting(true);
            }, 100);
          })
          .finally(() => {
            setImporting(false);
          });
      }

      // Create a list or import to a specific one
      if (!list?.id) {
        return createList({ name })
          .catch((createError) => {
            let state_message = createError.response.data.state_message;
            // if (state_message === "empty_name") {
            //   return handleErrors(state_message);
            // }
          })
          .then((response) => {
            if (response.data.state) {
              // If list created, import to that list
              mainFunction(response.data.contact_list_id);
            }
          });
      }
      return mainFunction(list?.id);
    },
    [data, fields, onFinish]
  );

  const [active, setActive] = useState(isCopyPaste ? "copy" : "file");
  const [openedLeft, setOpenedLeft] = useState(true);
  const [shouldWarn, setShouldWarn] = useState(false);
  const tabs = useMemo(() => {
    return [
      {
        label: lang.contact_lists.import.steps_title.file,
        icon: <IconFile size={18} />,
        value: "file",
      },
      {
        label: lang.contact_lists.import.steps_title.copy,
        icon: <IconCopy size={18} />,
        value: "copy",
      },
    ];
  }, []);

  const handleTabChange = (v) => {
    if (shouldWarn)
      modals.openConfirmModal({
        title: "Attention",
        children: (
          <Text size="sm">
            Vos modifications ne seront pas sauvegardés si vous changez de
            méthode d'import.
          </Text>
        ),
        labels: { confirm: "Confirm", cancel: "Cancel" },
        onCancel: () => {},
        onConfirm: () => {
          setShouldWarn(false);
          setActive(v);
          setActiveStep(1);
        },
        centered: true,
      });
    else {
      setActive(v);
      setActiveStep(1);
    }
  };

  const getStepComponent = useCallback(() => {
    if (activeStep === 1) {
      if (active === "copy") {
        return (
          <StepCopyPaste
            pastedText={pastedText}
            setPastedText={setPastedText}
            separator={separator}
            setSeparator={setSeparator}
            consent={consent}
            setConsent={setConsent}
            handleNext={handleNext}
            setData={setData}
            isUnsub={isUnsub}
            isBlacklist={isBlacklist}
          />
        );
      }
      return (
        <StepImportFile
          file={file}
          setFile={setFile}
          data={data}
          setData={setData}
          consent={consent}
          setConsent={setConsent}
          isUnsub={isUnsub}
          handleNext={handleNext}
          isBlacklist={isBlacklist}
          merge={merge}
          setMerge={setMerge}
          sheetNames={sheetNames}
          setSheetNames={setSheetNames}
          sheets={sheets}
          setSheets={setSheets}
          isLinkedToCampaign={listProfile?.usedInProgrammations?.length > 0}
          lines={lines}
          setLines={setLines}
          headerLine={headerLine}
          setHeaderLine={setHeaderLine}
        />
      );
    }
    if (activeStep === 2) {
      return (
        <StepMapping
          data={data}
          fields={fields}
          setFields={setFields}
          isUnsub={isUnsub}
          isBlacklist={isBlacklist}
        />
      );
    }
    if (activeStep === 3) {
      return (
        <StepImportSettings
          file={file}
          data={data}
          fields={fields}
          handlePrev={handlePrev}
          handleImport={handleImport}
          listId={listId}
          isUnsub={isUnsub}
          isBlacklist={isBlacklist}
          setImporting={setImporting}
          onFinish={onFinish}
        />
      );
    }
  }, [
    activeStep,
    active,
    file,
    data,
    consent,
    isUnsub,
    handleNext,
    isBlacklist,
    merge,
    sheetNames,
    sheets,
    listProfile?.usedInProgrammations?.length,
    lines,
    headerLine,
    pastedText,
    separator,
    fields,
    handleImport,
    listId,
    onFinish,
  ]);

  const profile = useProfileStore((s) => s.profile);
  useEffect(() => {
    const datafields = useConfigStore.getState().datafields;
    if (profile?.saved_order?.mapping) {
      let autoFields = { ...defaultFields };
      let savedMap = profile.saved_order.mapping;

      Object.keys(savedMap).forEach((key) => {
        let df = datafields.find((x) => x.id === parseInt(key));
        if (df) {
          savedMap[key].forEach((value) => {
            let capitalizedKey = value.charAt(0).toUpperCase() + value.slice(1);
            let upperCasedKey = value.toUpperCase();

            let target = df.identifier_placeholder;

            autoFields = {
              ...autoFields,
              [value]: target,
              [capitalizedKey]: target,
              [upperCasedKey]: target,
            };
          });
        }
      });

      setFields(autoFields);
    }
  }, [profile, defaultFields]);

  // useNavigationBlocker(shouldWarn);

  return (
    <Tabs
      value={active}
      orientation="vertical"
      w={"100%"}
      onChange={handleTabChange}
      h={"100%"}
      flex={1}
      mih={500}
    >
      <Tabs.List mr={32}>
        {tabs.map((tab) => (
          <Tooltip key={tab.value} label={tab.label} position="right">
            <Tabs.Tab
              value={tab.value}
              key={tab.value}
              size={2}
              style={{ borderRightWidth: 6 }}
              bg={
                active === tab.value
                  ? "var(--mantine-primary-color-light-hover)"
                  : undefined
              }
              h={64}
              w={openedLeft ? 175 : 55}
            >
              <Group>
                {tab.icon}
                {openedLeft && <Text>{tab.label}</Text>}
              </Group>
            </Tabs.Tab>
          </Tooltip>
        ))}

        <Button
          px={0}
          variant="subtle"
          onClick={() => setOpenedLeft(!openedLeft)}
          radius={0}
          w={54}
          ml={openedLeft ? "auto" : 0}
          mt={"auto"}
        >
          {openedLeft ? (
            <IconArrowAutofitLeft size={18} />
          ) : (
            <IconArrowAutofitRight size={18} />
          )}
        </Button>
      </Tabs.List>

      <Stack flex={1} pos={"relative"} w={"100%"}>
        <LoadingOverlay visible={importing} overlayProps={{ blur: 1 }} />
        <Stepper active={activeStep - 1} iconSize={24} w={"95%"}>
          {isCopyPaste ? (
            <Stepper.Step
              label={lang.contact_lists.import.steps_title.contacts}
              description={lang.contact_lists.import.steps_title.contacts_text}
            />
          ) : (
            <Stepper.Step
              label={lang.contact_lists.import.steps_title.file}
              description={
                file?.name || lang.contact_lists.import.steps_title.file_text
              }
              styles={{
                stepDescription: {
                  maxWidth: 210,
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                },
              }}
            />
          )}
          {!isUnsub && (
            <Stepper.Step
              label={lang.contact_lists.import.steps_title.mapping}
              description={lang.contact_lists.import.steps_title.mapping_text}
            />
          )}
          <Stepper.Step
            label={lang.contact_lists.import.steps_title.importing}
            description={lang.contact_lists.import.steps_title.importing_text}
          />
        </Stepper>

        {getStepComponent()}

        {activeStep !== 3 && (
          <Group mt={"auto"}>
            {activeStep === 2 && <MappingStats fields={fields} data={data} />}
            {active === "copy" && activeStep === 1 ? (
              ""
            ) : (
              <Group ml={"auto"}>
                {activeStep !== 1 && (
                  <Button
                    variant="subtle"
                    leftSection={<IconChevronLeft />}
                    onClick={handlePrev}
                  >
                    {lang.global.back}
                  </Button>
                )}
                <Button
                  // type={activeStep === 1 && !isCopyPaste ? "submit" : "button"}
                  // form={activeStep === 1 && !isCopyPaste ? "file-form" : null}
                  rightSection={<IconChevronRight />}
                  onClick={handleNext}
                  disabled={getNextDisabled()}
                >
                  {lang.global.next}
                </Button>
              </Group>
            )}
          </Group>
        )}
      </Stack>
    </Tabs>
  );
};

export default ManualImport;
