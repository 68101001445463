import {
  Button,
  Checkbox,
  Group,
  Paper,
  Radio,
  Select,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import TitlesSelector from "../../protected/lists/import/quickImport/TitlesSelector";
import SenioritySelector from "../../protected/lists/import/quickImport/SenioritySelector";
import LocationsSelector from "../../protected/lists/import/quickImport/LocationsSelector";
import IndustriesSelector from "../../protected/lists/import/quickImport/IndustriesSelector";
import CreatableContactListSelector from "../../../components/Selectors/CreatableContactListSelector";
import { useLanguage } from "../../../stores/LanguageStore";
import { useRouteLoaderData } from "react-router-dom";
import { IconSparkles } from "@tabler/icons-react";

const Step1 = ({ setActiveStep }) => {
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
    >
      <Text fz={28} ta="center" mb={32}>
        Commençons par le profil de vos cibles
      </Text>

      <Group justify="end">
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep(1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

const Step2 = ({ setActiveStep, values, setValues }) => {
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={48}
    >
      <Text fz={28} ta="center">
        Quelles fonctions ciblez-vous ?
      </Text>
      <TitlesSelector
        size="lg"
        w={"100%"}
        label={null}
        placeholder="CEO, directeur, secretaire, ..."
        currentTitles={values}
        setCurrentTitles={setValues}
      />

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

// const Step3 = ({ setActiveStep, values, setValues }) => {
//   return (
//     <Stack
//       justify="center"
//       align="center"
//       h={"100%"}
//       className="animate__animated animate__fadeIn"
//       mt={32}
//       px={48}
//     >
//       <Text fz={28} ta="center">
//         Combien d’années d’expérience ?{" "}
//       </Text>
//       <SenioritySelector
//         size="lg"
//         w={"100%"}
//         label={null}
//         placeholder="Choisir parmis les valeurs"
//         currentTitles={values}
//         setCurrentTitles={setValues}
//       />

//       <Group justify="space-between" w={"100%"}>
//         <Button
//           className="new-button"
//           size="md"
//           radius={"md"}
//           variant="subtle"
//           onClick={() => setActiveStep((prev) => prev - 1)}
//         >
//           Retour
//         </Button>
//         <Button
//           className="new-button"
//           size="md"
//           radius={"md"}
//           onClick={() => setActiveStep((prev) => prev + 1)}
//         >
//           Suivant
//         </Button>
//       </Group>
//     </Stack>
//   );
// };

const Step4 = ({ setActiveStep, values, setValues }) => {
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={48}
    >
      <Text fz={30} ta={"center"}>
        Ou se trouvent vos cibles ?
      </Text>
      <LocationsSelector
        size="lg"
        w={"100%"}
        label={null}
        placeholder="Pays, ville, rue, ..."
        currentTitles={values}
        setCurrentTitles={setValues}
      />

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

const Step5 = ({ setActiveStep }) => {
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={48}
    >
      <Text fz={28} ta="center" mb={32}>
        Poursuivons avec les informations sur l’entreprise{" "}
      </Text>

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

const greatCompaniesOptions = [
  "201-500",
  "501-1000",
  "1001-5000",
  "5001-10000",
  "10001-above",
];
const CompanySizeItem = ({ item, values, setValues }) => {
  const [activeValue, setActiveValue] = useState(
    values.find((x) => greatCompaniesOptions.includes(x)) || "201-500"
  );
  const active = useMemo(() => {
    if (item?.isGreat) {
      return values.includes(activeValue);
    }
    return values.includes(item.value);
  }, [activeValue, item?.isGreat, item.value, values]);

  const handleCheck = (e) => {
    // Cancel if on select option click
    if (
      e.target.tagName === "SPAN" ||
      e.target.classList.contains("mantine-Select-option")
    ) {
      return;
    }

    if (item?.isGreat) {
      if (active) {
        return setValues(values.filter((x) => x !== activeValue));
      } else {
        return setValues([...values, activeValue]);
      }
    }
    if (active) {
      setValues(values.filter((x) => x !== item.value));
    } else {
      setValues([...values, item.value]);
    }
  };

  const handleSelectChange = (newValue) => {
    setValues([
      ...values.filter((x) => !greatCompaniesOptions.includes(x)),
      newValue,
    ]);
    setActiveValue(newValue);
  };

  if (item?.isGreat) {
    console.log(values);
  }

  return (
    <Paper
      withBorder
      shadow="sm"
      p={"md"}
      radius={"lg"}
      w="48%"
      h={112}
      mb={8}
      style={{
        border: active
          ? "2px solid var(--mantine-color-secondary-6)"
          : "2px solid #00000012",
        cursor: "pointer",
      }}
      className="card-button"
      onClick={handleCheck}
    >
      <Group justify="space-between" mb={"sm"}>
        <Text size="lg">{item.label}</Text>

        <Checkbox checked={active} color="secondary" size="md" readOnly />
      </Group>

      {item?.isGreat ? (
        <Select
          data={greatCompaniesOptions.map((item) => ({
            label: `${item} salariés`,
            value: item,
          }))}
          value={activeValue}
          onChange={handleSelectChange}
          onClick={(e) => e.stopPropagation()}
          comboboxProps={{
            portalProps: {
              onClick: (e) => e.stopPropagation(),
            },
          }}
          allowDeselect={false}
          size="md"
        />
      ) : (
        <Text fw={400}>{item.text}</Text>
      )}
    </Paper>
  );
};
const Step6 = ({ setActiveStep, values, setValues }) => {
  const items = [
    {
      label: "Micro entreprises",
      text: "1 - 10 salariés",
      value: "1-10",
    },
    {
      label: "Petites entreprises",
      text: "11 - 50 salariés",
      value: "11-50",
    },
    {
      label: "Entreprises intérmediaires",
      text: "51 - 200 salariés",
      value: "51-200",
    },
    {
      label: "Grands groupes",
      isGreat: true,
    },
  ];
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={32}
    >
      <Text fz={28} ta={"center"}>
        Quelles tailles d’entreprises ciblez vous ?{" "}
      </Text>

      <Group justify="space-between">
        {items.map((item) => (
          <CompanySizeItem
            item={item}
            setValues={setValues}
            values={values}
            key={item.label}
          />
        ))}
      </Group>

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

const Step7 = ({ setActiveStep, values, setValues }) => {
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={48}
    >
      <Text fz={28} ta={"center"}>
        Quelles industries ?
      </Text>
      <IndustriesSelector
        size="lg"
        w={"100%"}
        label={null}
        placeholder="Pharmaceutique, informatique, immobilier"
        currentTitles={values}
        setCurrentTitles={setValues}
      />

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          onClick={() => setActiveStep((prev) => prev + 1)}
        >
          Suivant
        </Button>
      </Group>
    </Stack>
  );
};

// const Step8 = ({ setActiveStep, values, setValues }) => {
//   return (
//     <Stack
//       justify="center"
//       align="center"
//       h={"100%"}
//       className="animate__animated animate__fadeIn"
//       mt={32}
//       px={48}
//     >
//       <Text fz={28} ta={"center"}>
//         Quelle est la localisation des sièges sociaux ?
//       </Text>
//       <LocationsSelector
//         size="lg"
//         w={"100%"}
//         label={null}
//         placeholder="Pays, ville, rue, ..."
//         currentTitles={values}
//         setCurrentTitles={setValues}
//       />

//       <Group justify="space-between" w={"100%"}>
//         <Button
//           className="new-button"
//           size="md"
//           radius={"md"}
//           variant="subtle"
//           onClick={() => setActiveStep((prev) => prev - 1)}
//         >
//           Retour
//         </Button>
//         <Button
//           className="new-button"
//           size="md"
//           radius={"md"}
//           onClick={() => setActiveStep((prev) => prev + 1)}
//         >
//           Suivant
//         </Button>
//       </Group>
//     </Stack>
//   );
// };

const Step9 = ({ setActiveStep, listState, list, setList, setListState }) => {
  const lang = useLanguage((s) => s.language);
  return (
    <Stack
      justify="center"
      align="center"
      h={"100%"}
      className="animate__animated animate__fadeIn"
      mt={32}
      px={48}
    >
      <Text fz={28} ta={"center"}>
        Souhaitez-vous :
      </Text>

      <Group gap={64} mb={12}>
        <Radio
          label={lang.targeting_linkedin_google.create_new_list}
          name="list_state"
          checked={listState === 1}
          size="lg"
          onChange={(e) => setListState(1)}
        />

        <Radio
          label={lang.targeting_linkedin_google.import}
          name="list_state"
          size="lg"
          checked={listState === 2}
          onChange={(e) => setListState(2)}
        />
      </Group>

      {listState === 1 ? (
        <TextInput
          id="new-list-name"
          name="contact_list_name"
          w={"100%"}
          disabled={listState !== 1}
          label={lang.targeting_linkedin_google.list_name}
          placeholder={lang.targeting_linkedin_google.list_name}
          required={listState === 1}
          size="lg"
        />
      ) : (
        <CreatableContactListSelector
          value={list}
          setValue={setList}
          label={lang.targeting_linkedin_google.select_list}
          placeholder={lang.contact_lists.create.dialog.desc}
          noDesc
          w={"100%"}
          disabled={listState !== 2}
          required={listState === 2}
          disableCreate
          size="lg"
        />
      )}

      <Group justify="space-between" w={"100%"}>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          variant="subtle"
          onClick={() => setActiveStep((prev) => prev - 1)}
        >
          Retour
        </Button>
        <Button
          className="new-button"
          size="md"
          radius={"md"}
          leftSection={<IconSparkles />}
        >
          Générer ma liste avec IA
        </Button>
      </Group>
    </Stack>
  );
};

const AiImport = ({ onFinish, active }) => {
  const [activeStep, setActiveStep] = useState(0);

  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);
  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const [currentTitles, setCurrentTitles] = useState([]);
  const [companyCounts, setCompanyCounts] = useState([]);
  const [locations, setLocations] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [seniority, setSeniority] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [headQuarters, setHeadQuarters] = useState([]);

  const stepContent = useMemo(() => {
    if (activeStep === 0) {
      return <Step1 setActiveStep={setActiveStep} />;
    }
    if (activeStep === 1) {
      return (
        <Step2
          setActiveStep={setActiveStep}
          values={currentTitles}
          setValues={setCurrentTitles}
        />
      );
    }
    if (activeStep === 2) {
      return (
        <Step4
          setActiveStep={setActiveStep}
          values={locations}
          setValues={setLocations}
        />
      );
    }
    if (activeStep === 3) {
      return <Step5 setActiveStep={setActiveStep} />;
    }
    if (activeStep === 4) {
      return (
        <Step6
          values={companyCounts}
          setValues={setCompanyCounts}
          setActiveStep={setActiveStep}
        />
      );
    }
    if (activeStep === 5) {
      return (
        <Step7
          values={industries}
          setValues={setIndustries}
          setActiveStep={setActiveStep}
        />
      );
    }
    if (activeStep === 6) {
      return (
        <Step9
          values={headQuarters}
          list={list}
          listState={listState}
          setActiveStep={setActiveStep}
          setList={setList}
          setListState={setListState}
        />
      );
    }
  }, [
    activeStep,
    companyCounts,
    currentTitles,
    headQuarters,
    industries,
    list,
    listState,
    locations,
  ]);

  if (active !== "ai") {
    return;
  }
  return stepContent;
};

export default AiImport;
