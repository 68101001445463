import {
  ActionIcon,
  Group,
  LoadingOverlay,
  Paper,
  TagsInput,
  Tooltip,
} from "@mantine/core";
import EmailAccountSelector from "../../../../../components/Selectors/EmailAccountSelector";
import { useState } from "react";
import { IconMailbox } from "@tabler/icons-react";
import { sendEmail } from "../../../../../api/WorkflowAPI";
import { notifications } from "@mantine/notifications";
import EmailAnswerEditor from "./EmailAnswerEditor";
import EmailSignatureSelector from "../../../../../components/Selectors/EmailSignatureSelector";
import { useLanguage } from "../../../../../stores/LanguageStore";

const AnswerSender = ({ item, setAnswering, about, setProspectLoading }) => {
  const lang = useLanguage((s) => s.language);
  const [sender, setSender] = useState(
    item?.reply_to?.integration_id
      ? { id: item?.reply_to?.integration_id }
      : null
  );
  const [recipients, setRecipients] = useState([about?.email || ""]);
  const [sign, setSign] = useState(
    item?.reply_to?.signature_id ? { id: item?.reply_to?.signature_id } : null
  );
  const [loading, setLoading] = useState(false);

  const [initialized, setInitialized] = useState(false);

  const handleInit = () => {
    setInitialized(true);
  };

  const handleSubmit = ({ html, text }) => {
    let oldHtml = item.reply;
    let parsedHtml = `
      ${html}
      <br />

      <div style="
          border: none;
          border-top: solid #e1e1e1 1pt;
          padding: 3pt 0in 0in 0in;
        ">
      </div>

      ${oldHtml || ""}
    `;

    setLoading(true);

    sendEmail({
      html: parsedHtml,
      text,
      subject: `Re : ${item?.reply_to?.subject || ""}`,
      email_account_id: sender?.id,
      recipients,
      // contact_id_prm: current.id,
      email_signature_id: sign?.id,
      reply_id: item?.id,
    })
      .then((res) => {
        notifications.show({ message: "Email envoyé", color: "green.8" });
        setAnswering(false);

        setProspectLoading(true);
      })
      .finally(() => setLoading(false));
  };

  return (
    <Paper pos={"relative"} pt={4} mih={64}>
      <LoadingOverlay visible={loading || !initialized} />

      {initialized && (
        <Group
          w={"100%"}
          wrap="nowrap"
          gap={"lg"}
          px={8}
          pl={4}
          pt={4}
          pos={"relative"}
          style={{
            zIndex: 4,
            background: "white",
          }}
          align="start"
        >
          <TagsInput
            type="email"
            value={recipients}
            onChange={setRecipients}
            label={lang.components.email_preview.recipients}
            placeholder={lang.global.enter_key_to_add}
            w={"100%"}
            required
            rightSection={
              <Tooltip label={lang.components.email_preview.take_contact_mail}>
                <ActionIcon
                  disabled={!about?.email}
                  variant="light"
                  onClick={() => setRecipients([about.email])}
                >
                  <IconMailbox size={18} />
                </ActionIcon>
              </Tooltip>
            }
          />
          <EmailAccountSelector
            label={lang.components.email_preview.sender}
            size={"sm"}
            required
            onChange={setSender}
            value={sender?.id}
            smtpOnly={"semi"}
          />
          <EmailSignatureSelector
            value={sign?.id}
            onChange={setSign}
            label={lang.emails_account.columns.signature}
            size={"sm"}
            w="100%"
          />
        </Group>
      )}

      <EmailAnswerEditor
        key={item.id}
        id={item.id}
        handleSubmit={handleSubmit}
        handleClose={() => setAnswering(false)}
        onInit={handleInit}
        loading={loading}
      />
    </Paper>
  );
};

export default AnswerSender;
