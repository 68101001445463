import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Group,
  Stack,
  Tooltip,
} from "@mantine/core";
import { useUIStore } from "../../../../stores/UIStore";
import {
  IconChevronRight,
  IconChevronRightPipe,
  IconHelp,
  IconLayoutSidebarLeftExpand,
  IconX,
} from "@tabler/icons-react";
import { IconChevronLeftPipe } from "@tabler/icons-react";
import AllSwitcher from "../../../../layouts/Footer/AllSwitcher";
import { useProfileStore } from "../../../../stores/UserStore";
import { useMemo } from "react";
import Notifications from "../../../../layouts/Header/Notifications";
import VersionController from "../../../../components/View/VersionController";
import { getPermission } from "../../../../functions/utilFunctions";
import GlobalSearch from "../../../../layouts/Header/GlobalSearch";
import HeaderProfile2 from "./HeaderProfile";
import { useWindowScroll } from "@mantine/hooks";

const Header = () => {
  const navbarClosed = useUIStore((s) => s.navbarClosed);
  const setNavbarClosed = useUIStore((s) => s.setNavbarClosed);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);
  const profile = useProfileStore((s) => s.profile);

  const canSwitch = useMemo(() => {
    let p = unswitchedProfile || profile;
    if (p?.level === "admin") {
      return true;
    }
    if (
      p?.permissions?.find((x) => x.name === "switchOrganizationUser")?.value ||
      p?.permissions?.find((x) => x.name === "switchTeamUser")?.value
    ) {
      return true;
    }
    return false;
  }, [unswitchedProfile, profile]);

  const [scroll, scrollTo] = useWindowScroll();

  const campaignOpened = useUIStore((s) => s.campaignOpened);
  const setCampaignOpened = useUIStore((s) => s.setCampaignOpened);
  const handleClick = () => {
    if (campaignOpened) {
      setCampaignOpened(false);
    }
    setNavbarClosed(!navbarClosed);
  };

  return (
    <Stack
      gap={0}
      pos={"fixed"}
      top={0}
      left={navbarClosed ? 75 : 240}
      flex={1}
      w={`calc(100% - ${navbarClosed ? 75 : 240}px)`}
      bg={"#fff"}
      style={{
        zIndex: 2,
        boxShadow: scroll.y > 0 ? "#00000012 6px 0 4px" : "none",
        borderBottom: scroll.y > 0 ? "1px solid #00000012" : "0",
      }}
    >
      <Group
        align={"center"}
        gap={0}
        h={"100%"}
        py={0}
        w={"100%"}
        wrap="nowrap"
        bg={"#fff"}
      >
        {getPermission("displaySideMenu") && (
          <ActionIcon
            onClick={handleClick}
            h={48}
            w={48}
            radius={16}
            variant="subtle"
            ml={8}
          >
            {navbarClosed ? (
              <IconChevronRight
                size={28}
                style={{ strokeWidth: 1.1 }}
              />
            ) : (
              <IconX size={28} style={{ strokeWidth: 1.1 }} />
            )}
          </ActionIcon>
        )}

        <GlobalSearch />

        <VersionController />

        <Group ml={"auto"}>
          <Tooltip label="Support">
            <Button
              variant="subtle"
              px={12}
              size="lg"
              component={Anchor}
              href={"/support"}
              target="_blank"
              color={"#000"}
            >
              <IconHelp />
            </Button>
          </Tooltip>

          <Notifications is2 />

          {/* <LanguageChanger is2 /> */}
        </Group>

        <Divider orientation="vertical" my={16} mx={"md"} opacity={0.75} />

        <HeaderProfile2 />

        {canSwitch && <AllSwitcher is22 />}
      </Group>
    </Stack>
  );
};

export default Header;
