import { useMemo } from "react";
import { useLanguage } from "../../../stores/LanguageStore";
import { Select } from "@mantine/core";

const FilterCondition = ({
  type = "text",
  value,
  setValue,
  isClient,
  disabled,
}) => {
  const lang = useLanguage((s) => s.language);

  const booleanConditions = useMemo(
    () => [
      {
        value: "equals",
        label: lang.table.toolbar.filter.filters_list_label.server.equals,
      },
      {
        value: "not_equals",
        label: lang.table.toolbar.filter.filters_list_label.server.not_equals,
      },
    ],
    [lang]
  );

  const existConditions = useMemo(
    () => [
      {
        value: "does_exist",
        label: lang.table.toolbar.filter.filters_list_label.server.positive,
      },
      {
        value: "does_not_exist",
        label: lang.table.toolbar.filter.filters_list_label.server.negative,
      },
    ],
    [lang]
  );

  const clientConditions = useMemo(() => {
    let result = [
      {
        value: "contains",
        label: lang.table.toolbar.filter.filters_list_label.client.contains,
      },
      {
        value: "equals",
        label: lang.table.toolbar.filter.filters_list_label.client.equals,
      },
      {
        value: "startsWith",
        label: lang.table.toolbar.filter.filters_list_label.client.startsWith,
      },
      {
        value: "endsWith",
        label: lang.table.toolbar.filter.filters_list_label.client.endsWith,
      },
      {
        value: "isEmpty",
        label: lang.table.toolbar.filter.filters_list_label.client.isEmpty,
      },
      {
        value: "isNotEmpty",
        label: lang.table.toolbar.filter.filters_list_label.client.isNotEmpty,
      },
    ];

    if (type === "number") {
      result = [
        {
          value: "=",
          label: lang.table.toolbar.filter.filters_list_label.client.equals,
        },
        {
          value: "!=",
          label: lang.table.toolbar.filter.filters_list_label.server.not_equals,
        },
        {
          value: ">=",
          label:
            lang.table.toolbar.filter.filters_list_label.server
              .more_or_equal_than,
        },
        {
          value: ">",
          label: lang.table.toolbar.filter.filters_list_label.server.more_than,
        },
        {
          value: "<",
          label: lang.table.toolbar.filter.filters_list_label.server.less_than,
        },
        {
          value: "<=",
          label:
            lang.table.toolbar.filter.filters_list_label.server
              .less_or_equal_than,
        },
        {
          value: "isEmpty",
          label: lang.table.toolbar.filter.filters_list_label.client.isEmpty,
        },
        {
          value: "isNotEmpty",
          label: lang.table.toolbar.filter.filters_list_label.client.isNotEmpty,
        },
      ];
    }

    if (type === "date") {
      result = [
        {
          value: "is",
          label: lang.table.toolbar.filter.filters_list_label.client.equals,
        },
        {
          value: "not",
          label: lang.table.toolbar.filter.filters_list_label.server.not_equals,
        },
        {
          value: "after",
          label:
            lang.table.toolbar.filter.filters_list_label.server.date_more_than,
        },
        {
          value: "onOrAfter",
          label:
            lang.table.toolbar.filter.filters_list_label.server
              .date_more_or_equal_than,
        },
        {
          value: "before",
          label:
            lang.table.toolbar.filter.filters_list_label.server.date_less_than,
        },
        {
          value: "onOrBefore",
          label:
            lang.table.toolbar.filter.filters_list_label.server
              .date_less_or_equal_than,
        },
      ];
    }

    return result;
  }, [lang, type]);

  const serverConditions = useMemo(() => {
    const additional =
      type !== "text"
        ? [
            {
              value: "more_than",
              label:
                type === "date"
                  ? lang.table.toolbar.filter.filters_list_label.server
                      .date_more_than
                  : lang.table.toolbar.filter.filters_list_label.server
                      .more_than,
            },
            {
              value: "more_or_equal_than",
              label:
                type === "date"
                  ? lang.table.toolbar.filter.filters_list_label.server
                      .date_more_or_equal_than
                  : lang.table.toolbar.filter.filters_list_label.server
                      .more_or_equal_than,
            },
            {
              value: "less_than",
              label:
                type === "date"
                  ? lang.table.toolbar.filter.filters_list_label.server
                      .date_less_than
                  : lang.table.toolbar.filter.filters_list_label.server
                      .less_than,
            },
            {
              value: "less_or_equal_than",
              label:
                type === "date"
                  ? lang.table.toolbar.filter.filters_list_label.server
                      .date_less_or_equal_than
                  : lang.table.toolbar.filter.filters_list_label.server
                      .less_or_equal_than,
            },
          ]
        : [];
    return [
      {
        value: "equals",
        label: lang.table.toolbar.filter.filters_list_label.server.equals,
      },
      {
        value: "not_equals",
        label: lang.table.toolbar.filter.filters_list_label.server.not_equals,
      },
      {
        value: "contains",
        label: lang.table.toolbar.filter.filters_list_label.server.contains,
      },
      {
        value: "not_contains",
        label: lang.table.toolbar.filter.filters_list_label.server.not_contains,
      },
      {
        value: "end_with",
        label: lang.table.toolbar.filter.filters_list_label.server.end_with,
      },
      {
        value: "start_with",
        label: lang.table.toolbar.filter.filters_list_label.server.start_with,
      },
      {
        value: "does_not_exist",
        label:
          lang.table.toolbar.filter.filters_list_label.server.does_not_exist,
      },
      {
        value: "does_exist",
        label: lang.table.toolbar.filter.filters_list_label.server.does_exist,
      },
      ...additional,
    ];
  }, [type, lang]);

  return (
    <Select
      value={value}
      onChange={(v) => setValue(v)}
      w={140}
      disabled={disabled}
      placeholder="Opérateur *"
      required
      data={
        type === "boolean"
          ? booleanConditions
          : type === "exist"
          ? existConditions
          : isClient
          ? clientConditions
          : serverConditions
      }
      comboboxProps={{ offset: 2, shadow: "sm", zIndex: 1400 }}
    />
  );
};

export default FilterCondition;
