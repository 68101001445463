import { useEffect, useMemo, useState } from "react";
import { useLanguage } from "../../../../../stores/LanguageStore";
import { useFlowStore } from "../../../../../stores/WorkflowStore";
import {
  Anchor,
  Divider,
  Group,
  Paper,
  Select,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { getNodeDetails } from "../flow/flowSettings";
import EmailPreviewer from "../../../../../components/View/EmailPreviewer";
import MessagePreviewer from "../../../../../components/View/MessagePreviewer";
import SenderVisual from "../flow/nodes/SenderVisual";
import { getFirstPageContacts } from "../../../../../api/ContactAPI";
import ContactPreview from "./ContactPreview";
import { useListStore } from "../../../../../stores/ListStore";

const Review = ({ programData }) => {
  const lang = useLanguage((s) => s.language);
  const nodes = useFlowStore((s) => s.nodes);
  const flowProfile = useFlowStore((s) => s.profile);
  const [contact, setContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const lists = useListStore((s) => s.lists);

  const actionNodes = useMemo(() => {
    return nodes.filter(
      (x) =>
        (x?.data?.actionType?.includes("email") ||
          x?.data?.actionType?.includes("linkedin")) &&
        x?.data?.actionType !== "linkedin_visit"
    );
  }, [nodes]);

  const [selected, setSelected] = useState(actionNodes[0]);
  const preview = useMemo(() => {
    if (selected?.data.actionType === "email") {
      return (
        <EmailPreviewer
          model_id={selected?.data?.model_id}
          iframeHeight={"100%"}
          signatureId={selected?.data?.signature_id}
          contact={contact}
          paperStyle={{ radius: 0 }}
          withEditor
        />
      );
    } else {
      return (
        <MessagePreviewer
          model_id={selected?.data?.model_id}
          iframeHeight={"100%"}
          type={selected?.data?.actionType}
          contact={contact}
          paperStyle={{ radius: 0 }}
          mah={"52vh"}
        />
      );
    }
  }, [selected, contact]);

  const [loading, setLoading] = useState(false);
  useEffect(() => {
    let contactLists = programData?.contactlist_ids;
    if (contactLists?.length > 0) {
      let list = contactLists[0];
      setLoading(true);
      getFirstPageContacts({ id: list, per_page: 5 })
        .then((res) => {
          let results = res.data.results;
          if (results.length > 0) {
            setContact(res.data.results?.[0]);
            setContacts(res.data.results);
          }
        })
        .finally(() => setLoading(false));
    }
  }, [programData?.contactlist_ids]);

  useEffect(() => {
    setSelected(actionNodes[0]);
  }, [actionNodes]);

  return (
    <Stack w={"65%"} mx={"auto"} gap={0} flex={1}>
      <Title order={5} ta={"center"}>
        {lang.campaign.review.title}{" "}
        <span style={{ fontWeight: 500 }}>{flowProfile?.name}</span>
      </Title>
      {actionNodes.length === 0 && (
        <Text c={"red"} size="md" ta={"center"} mt={8} fw={600}>
          {lang.campaign.review.no_node}
        </Text>
      )}
      <Select
        label={lang.campaign.review.action}
        placeholder={lang.campaign.review.action}
        data={actionNodes.map((x, index) => ({
          label: `${getNodeDetails(x.data.actionType).label} #${index + 1}`,
          value: x.id,
        }))}
        searchable
        value={selected?.id}
        onChange={(value) =>
          setSelected(actionNodes.find((x) => x.id === value))
        }
        disabled={actionNodes.length === 0}
      />
      <Paper
        component={Stack}
        gap={"xs"}
        withBorder
        p={"xs"}
        mt={"xs"}
        style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0 }}
      >
        <SenderVisual data={selected?.data} inReview />
      </Paper>
      <Paper
        component={Group}
        gap={"xs"}
        withBorder
        // p={"xs"}
        style={{ borderRadius: 0, borderTop: 0, borderBottom: 0 }}
        wrap="nowrap"
      >
        <ContactPreview
          loading={loading}
          contact={contact}
          contacts={contacts}
          setContact={setContact}
        />
        <Divider orientation="vertical" />
        <Text w={"100%"} truncate>
          {lang.campaign.review.list} :{" "}
          <Anchor
            href={`/contact-lists/${programData?.contactlist_ids[0]}`}
            target="_blank"
          >
            #{programData?.contactlist_ids[0]} -{" "}
            {lists?.find((x) => x.id === programData?.contactlist_ids[0])?.name}
          </Anchor>
        </Text>
      </Paper>
      <Stack flex={1}>{preview}</Stack>
    </Stack>
  );
};

export default Review;
