import { Button, Group, Stack, Title } from "@mantine/core";
import { useProfileStore } from "../../../stores/UserStore";
import { IconCalendarUser, IconHeadset, IconHelp } from "@tabler/icons-react";
import GetStarted from "./GetStarted";

// const data = [
//   {
//     name: "Page A",
//     uv: 4000,
//     pv: 2400,
//     amt: 2400,
//   },
//   {
//     name: "Page B",
//     uv: 3000,
//     pv: 1398,
//     amt: 2210,
//   },
//   {
//     name: "Page C",
//     uv: 4000,
//     pv: 9800,
//     amt: 2290,
//   },
//   {
//     name: "Page D",
//     uv: 2780,
//     pv: 3908,
//     amt: 6000,
//   },
//   {
//     name: "Page E",
//     uv: 1890,
//     pv: 4800,
//     amt: 3600,
//   },
//   {
//     name: "Page F",
//     uv: 3100,
//     pv: 3800,
//     amt: 2500,
//   },
//   {
//     name: "Page G",
//     uv: 3490,
//     pv: 7000,
//     amt: 5000,
//   },
// ];

// const dataCircle = [
//   { name: "Ouvreurs", value: 400, color: "#ffde59" },
//   { name: "Cliqueurs", value: 300, color: "#fa7d75" },
//   { name: "Répondeurs", value: 100, color: "#b4ca91" },
// ];

const Home2 = () => {
  return (
    <Stack
      style={{
        background: "#fff",
      }}
      flex={1}
      px={24}
      pb={16}
    >
      <Title order={2} mt={16} ff={"Inter"}>
        Bienvenue sur Magileads,{" "}
        {useProfileStore.getState()?.profile?.first_name}
      </Title>

      {/* <Group wrap="nowrap" align="stretch" gap={"xl"}>
        <Stack
          className="layout-block"
          gap={0}
          mx={0}
          w={825}
          style={{
            borderRadius: 24,
            boxShadow: "#00000012 0 1px 3px",
          }}
        >
          <Title order={4} mt={4}>
            📊 Performance de vos campagnes
          </Title>
          <Text mb={16}>
            Suivez, ici, l’évolution de vos campagnes en temps réel.
          </Text>

          <Box ml={-28}>
            <LineChart width={800} height={300} data={data}>
              <XAxis dataKey="name" />
              <YAxis />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Line type="monotone" dataKey="pv" stroke="#1a00c6" />
              <Line type="monotone" dataKey="uv" stroke="#49c3fb" />
              <Line type="monotone" dataKey="amt" stroke="#ffc536" />
            </LineChart>
          </Box>
        </Stack>

        <Stack
          className="layout-block"
          gap={0}
          mx={0}
          w={825}
          style={{
            borderRadius: 24,
            boxShadow: "#00000012 0 1px 3px",
          }}
        >
          <Title order={4} mt={4}>
            Suivi des leads
          </Title>
          <Text mb={16}>Suivez, ici, l'ensemble des taux de vos leads</Text>

          <Stack gap={"xs"}>
            <DonutChart
              paddingAngle={4}
              data={dataCircle}
              mb={8}
              size={192}
              thickness={32}
            />

            <Group>
              <ColorSwatch
                color={dataCircle.find((x) => x.name === "Ouvreurs").color}
                size={18}
              />
              <Text>Ouvreurs</Text>
            </Group>

            <Group>
              <ColorSwatch
                color={dataCircle.find((x) => x.name === "Cliqueurs").color}
                size={18}
              />
              <Text>Cliqueurs</Text>
            </Group>

            <Group>
              <ColorSwatch
                color={dataCircle.find((x) => x.name === "Répondeurs").color}
                size={18}
              />
              <Text>Répondeurs</Text>
            </Group>
          </Stack>
        </Stack>
      </Group> */}

      <Group wrap="nowrap" align="start" gap={"xl"}>
        <GetStarted />
      </Group>

      <Stack
        gap={0}
        mx={"auto"}
        mt={16}
        w={"50%"}
        px={0}
        style={{
          borderRadius: 24,
        }}
      >
        <Title order={4} mt={4} ta={"center"}>
          Besoin d'aide, {useProfileStore.getState().profile?.first_name} ?
        </Title>

        <Stack
          gap={0}
          mx={0}
          mt={16}
          w={"100%"}
          px={0}
          style={{
            borderRadius: 24,
          }}
        >
          <Group wrap="nowrap" gap={"lg"}>
            <Button
              radius={16}
              size="lg"
              variant="outline"
              leftSection={<IconCalendarUser size={24} />}
              w={"100%"}
            >
              Réservez une démo
            </Button>

            <Button
              radius={16}
              size="lg"
              variant="outline"
              leftSection={<IconHeadset size={24} />}
              w={"100%"}
            >
              Contactez le support
            </Button>
          </Group>

          <Button
            mt={16}
            radius={16}
            size="lg"
            variant="outline"
            leftSection={<IconHelp size={24} />}
            w={"100%"}
          >
            Accéder à la base de connaissance
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default Home2;
