import {
  Anchor,
  Badge,
  Button,
  Divider,
  Group,
  HoverCard,
  LoadingOverlay,
  NumberFormatter,
  NumberInput,
  Paper,
  SegmentedControl,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useEffect, useMemo, useState } from "react";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";
import {
  IconArrowRight,
  IconCheck,
  IconInfoCircle,
  IconMail,
  IconShoppingCartCheck,
  IconUserSearch,
} from "@tabler/icons-react";
import { Link } from "react-router-dom";
import FeaturesTable from "./FeaturesTable";
import { useLanguage } from "../../../stores/LanguageStore";
import { ArcherContainer, ArcherElement } from "react-archer";
import { getProducts, subscribe } from "../../../api/SubscriptionAPI";
import { useProfileStore, useSessionStore } from "../../../stores/UserStore";
import { parseDateFuture } from "../../../functions/dates";
import PagePermission from "../../../components/hooks/usePagePermission";
import { updateSelf } from "../../../api/UserAPI";
import ChangeValidation from "./ChangeValidation";
import Price from "./Price";
import RequiredActions from "../account/RequiredActions/RequiredActions";

const EnrichInfo = () => {
  const lang = useLanguage((s) => s.language);

  return (
    <HoverCard position="top">
      <HoverCard.Target>
        <ThemeIcon variant="subtle">
          <IconInfoCircle />
        </ThemeIcon>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Stack maw={300}>
          <Text>{lang.pricing.premium.enrich_info}</Text>
          <Text>{lang.pricing.premium.enrich_info2}</Text>
        </Stack>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

const Pricing = () => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [linkedin, setLinkedin] = useState(1);
  const [email, setEmail] = useState(1);
  const [enrich, setEnrich] = useState(1);
  const [reccurence, setRecurrence] = useState(
    profile?.subscriptions?.recurring_interval === "year" ? "annual" : "monthly"
  );
  const [opened, setOpened] = useState(false);
  const [actionRequiredError, setActionRequiredError] = useState(false);
  const [actionRequiredEmails, setActionRequiredEmails] = useState(0);
  const [actionRequiredLinkedins, setActionRequiredLinkedins] = useState(0);

  const [defaultValues, setDefaultValues] = useState({
    email: null,
    linkedin: null,
    enrich: null,
  });

  const defaultValuesStart = useMemo(() => {
    return {
      email: profile?.permissions.find(
        (x) => x.name === "maxIntegrationsEmailSMTP"
      )?.default_value,
      linkedin: profile?.permissions.find(
        (x) => x.name === "maxIntegrationsLinkedin"
      )?.default_value,
      enrich: profile?.permissions.find(
        (x) => x.name === "maxEmailsGeneratedPerMonth"
      )?.default_value,
    };
  }, [profile?.permissions]);

  const [loading, setLoading] = useState(true);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    if (loading) {
      getProducts()
        .then((r) => {
          setProducts(r.data.products_list);
          updateSelf(true).finally(() => {
            setLoading(false);
          });
        })
        .catch(() => setLoading(false));
    }
  }, [loading]);

  const productsMap = useMemo(() => {
    let email_product, linkedin_product, enrich_product;
    if (reccurence === "annual") {
      email_product = products.find(
        (x) => x.sub_type === "smtp" && x.billing_interval === "year"
      );
      linkedin_product = products.find(
        (x) => x.sub_type === "linkedin" && x.billing_interval === "year"
      );
      enrich_product = products.find(
        (x) => x.sub_type === "enrich" && x.billing_interval === "year"
      );
    } else {
      email_product = products.find(
        (x) => x.sub_type === "smtp" && x.billing_interval === "month"
      );
      linkedin_product = products.find(
        (x) => x.sub_type === "linkedin" && x.billing_interval === "month"
      );
      enrich_product = products.find(
        (x) => x.sub_type === "enrich" && x.billing_interval === "month"
      );
    }

    return {
      email_product,
      linkedin_product,
      enrich_product,
    };
  }, [products, reccurence]);

  const defaultPrice = useMemo(() => {
    let email_price = productsMap?.email_product?.price_amount || 0;

    let linkedin_price = productsMap?.linkedin_product?.price_amount || 0;

    let enrich_price = productsMap?.enrich_product?.price_amount || 0;

    let emailCost = (email_price / 5) * defaultValuesStart?.email;
    let linkedinCost = linkedin_price * defaultValuesStart?.linkedin;
    let enrichCost = (enrich_price / 1000) * defaultValuesStart?.enrich;

    return emailCost + linkedinCost + enrichCost;
  }, [
    defaultValuesStart?.email,
    defaultValuesStart?.enrich,
    defaultValuesStart?.linkedin,
    productsMap?.email_product?.price_amount,
    productsMap?.enrich_product?.price_amount,
    productsMap?.linkedin_product?.price_amount,
  ]);

  const price = useMemo(() => {
    let email_price = productsMap?.email_product?.price_amount || 0;

    let linkedin_price = productsMap?.linkedin_product?.price_amount || 0;

    let enrich_price = productsMap?.enrich_product?.price_amount || 0;

    let emailCost = defaultValues?.email
      ? (email_price / 5) * defaultValues?.email
      : email * email_price;
    let linkedinCost = defaultValues?.linkedin
      ? linkedin_price * defaultValues?.linkedin
      : linkedin * linkedin_price;
    let enrichCost = defaultValues?.enrich
      ? (enrich_price / 1000) * defaultValues?.enrich
      : enrich * enrich_price;

    return emailCost + linkedinCost + enrichCost;
  }, [
    defaultValues?.email,
    defaultValues?.enrich,
    defaultValues?.linkedin,
    email,
    enrich,
    linkedin,
    productsMap?.email_product?.price_amount,
    productsMap?.enrich_product?.price_amount,
    productsMap?.linkedin_product?.price_amount,
  ]);

  const handleCheckout = () => {
    if (profile?.subscriptions?.subscription_id) {
      return setOpened(true);
    }

    const reseller = useSessionStore.getState().reseller;
    setLoading(true);

    let items = [
      { price_id: productsMap.email_product.price_id, quantity: email },
      { price_id: productsMap.linkedin_product.price_id, quantity: linkedin },
      { price_id: productsMap.enrich_product.price_id, quantity: enrich },
    ];

    setActionRequiredError(false);
    setActionRequiredEmails(0);
    setActionRequiredLinkedins(0);
    
    subscribe({
      items,
      success_url: `https://${
        reseller?.domain_saas2 || reseller?.domain_saas
      }/account-settings/subscription?session_id={CHECKOUT_SESSION_ID}`,
      cancel_url: `https://${
        reseller?.domain_saas2 || reseller?.domain_saas
      }/error/subscription`,
    })
      .then((r) => {
        window.open(r.data.checkout_url, "_self");
      })
      .catch((err) => {
        let message = err.response.data.state_message;
        if (message.includes("accounts_removal")) {
          setActionRequiredError(true);
          let number = err.response.data?.accounts_to_remove;
          if (message.includes("linkedin")) {
            setActionRequiredLinkedins(number);
          } else {
            setActionRequiredEmails(number);
          }
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (profile?.subscriptions?.active && !profile?.subscriptions?.trial) {
      setDefaultValues({
        email: defaultValuesStart?.email,
        linkedin: defaultValuesStart?.linkedin,
        enrich: defaultValuesStart?.enrich,
      });
    }
  }, [
    defaultValuesStart,
    profile?.subscriptions?.active,
    profile?.subscriptions?.trial,
  ]);

  const [edited, setEdited] = useState([]);

  return (
    <>
      <PagePermission permission={"displayPricing"} />

      <RequiredActions
        defaultEmailsToDelete={actionRequiredEmails}
        defaultLinkedinsToDelete={actionRequiredLinkedins}
        defaultOpened={actionRequiredError}
        setOpened={setActionRequiredError}
        isError
      />

      <Stack gap={"xs"} pr={20}>
        <Title order={4}>{lang.pricing.title}</Title>
        <Text>{lang.pricing.title_tooltip}</Text>

        {profile?.subscriptions?.trial ||
        !profile?.subscriptions ||
        (!profile?.subscriptions?.trial && !profile?.subscriptions?.active) ? (
          <ArcherContainer offset={12} endMarker={false}>
            <Group wrap="nowrap" gap={72} align="start">
              <Paper
                withBorder
                p={"sm"}
                component={Stack}
                radius={"lg"}
                mt={"sm"}
                w={"100%"}
                justify="center"
                pos={"relative"}
                style={{ overflow: "hidden" }}
              >
                <Title order={4} ta={"center"}>
                  {lang.pricing.free.title}
                </Title>

                <Badge
                  pos={"absolute"}
                  top={16}
                  left={-60}
                  px={64}
                  radius={0}
                  variant="light"
                  style={{ transform: "rotate(-30deg)" }}
                  bg={"primary.0"}
                >
                  {lang.pricing.free.actual}
                </Badge>
                <Text ta={"center"} mb={8} size="sm" c={"red"} fw={600} px={16}>
                  {profile?.subscriptions?.end_date ? (
                    <>
                      {lang.pricing.free.will_end}{" "}
                      <span style={{ fontWeight: 500 }}>
                        {parseDateFuture(profile?.subscriptions?.end_date)}
                      </span>
                    </>
                  ) : (
                    <>{lang.pricing.free.ended}</>
                  )}
                </Text>

                <Stack>
                  {Object.keys(lang.pricing.free.features).map((key, i) => {
                    return (
                      <Group key={i}>
                        <ThemeIcon
                          radius={"sm"}
                          variant="light"
                          color="primary"
                        >
                          <IconCheck size={20} />
                        </ThemeIcon>

                        <Stack gap={0}>
                          <Text>{lang.pricing.free.features[key]}</Text>
                        </Stack>
                      </Group>
                    );
                  })}
                </Stack>

                <Divider mb={-4} />

                <ArcherElement
                  id="root"
                  relations={[
                    {
                      targetId: "element2",
                      targetAnchor: "left",
                      sourceAnchor: "right",
                      style: {
                        strokeDasharray: "6",
                        zIndex: 10,
                        strokeColor: "var(--mantine-color-secondary-6)",
                        noCurves: true,
                      },
                    },
                  ]}
                >
                  <Text
                    mb={4}
                    size="md"
                    ta={"center"}
                    fw={600}
                    c={"secondary.6"}
                  >
                    {lang.pricing.free.more_text}
                  </Text>
                </ArcherElement>
              </Paper>

              <Paper
                withBorder
                p={"sm"}
                component={Stack}
                radius={"lg"}
                mt={"sm"}
                w={"100%"}
                justify="center"
                style={{ border: "2px solid var(--mantine-color-secondary-6)" }}
                pos={"relative"}
              >
                <LoadingOverlay
                  visible={loading}
                  style={{ borderRadius: 24 }}
                />
                <ArcherElement
                  id="element2"
                  relations={[
                    {
                      targetAnchor: "left",
                      sourceAnchor: "left",
                      style: { strokeColor: "blue", strokeWidth: 1 },
                    },
                  ]}
                >
                  <Title order={4} ta={"center"}>
                    {lang.pricing.premium.title}
                  </Title>
                </ArcherElement>

                {profile?.subscriptions?.trial === false && (
                  <>
                    <Badge
                      pos={"absolute"}
                      top={-12}
                      left={16}
                      px={16}
                      variant="light"
                      bg={"primary.0"}
                    >
                      {lang.pricing.free.actual}
                    </Badge>
                    {/* <Text ta={"center"} mb={8} size="md" c={"red"} fw={600}>
                      {profile?.subscriptions?.end_date ? (
                        <>
                          {lang.pricing.free.will_end}:{" "}
                          <span>
                            {parseDateFuture(profile?.subscriptions?.end_date)}
                          </span>
                        </>
                      ) : (
                        <>{lang.pricing.free.ended}</>
                      )}
                    </Text> */}
                  </>
                )}

                <SegmentedControl
                  data={[
                    {
                      value: "monthly",
                      label: (
                        <Group w={"100%"} justify="center">
                          <Text>{lang.pricing.monthly}</Text>
                        </Group>
                      ),
                    },
                    {
                      value: "annual",
                      label: (
                        <Group w={"100%"} justify="center" pos={"relative"}>
                          <Text>{lang.pricing.annual}</Text>

                          <Badge color="tertiary.6" size="md" mr={-18}>
                            -20%
                          </Badge>
                        </Group>
                      ),
                    },
                  ]}
                  size="md"
                  value={reccurence}
                  onChange={setRecurrence}
                />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={250} gap={4}>
                    <Group>
                      <ThemeIcon variant="subtle">
                        <IconLinkedin size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.linkedin_accounts}</Text>
                    </Group>
                  </Stack>
                  <NumberInput
                    value={linkedin}
                    onChange={(v) => {
                      setLinkedin(v);
                    }}
                    w={100}
                    ml={"auto"}
                    min={1}
                    max={100}
                    size="md"
                  />
                </Group>

                <Divider my={-4} />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={250} gap={4}>
                    <Group>
                      <ThemeIcon color="orange" variant="subtle">
                        <IconMail size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.emails}</Text>
                    </Group>
                    <Text size="xs" c={"dimmed"}>
                      {lang.pricing.premium.email_desc}
                    </Text>
                  </Stack>
                  <NumberInput
                    value={email * 5}
                    onChange={(v) => {
                      setEmail(v / 5);
                    }}
                    onBlur={(e) => {
                      let v = parseInt(e.target.value);

                      setEmail(Math.ceil(v / 5));
                    }}
                    step={5}
                    w={100}
                    ml={"auto"}
                    min={1}
                    max={100}
                    size="md"
                  />
                </Group>

                <Divider my={-4} />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={300} gap={4}>
                    <Group>
                      <ThemeIcon color="tertiary.8" variant="subtle">
                        <IconUserSearch size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.enrich_monthly}</Text>

                      <EnrichInfo />
                    </Group>
                    <Text size="xs" c={"dimmed"}>
                      {lang.pricing.enrich_text}
                    </Text>
                  </Stack>
                  <NumberInput
                    value={enrich * 1000}
                    onChange={(v) => {
                      setEnrich(v / 1000);
                    }}
                    w={100}
                    size="md"
                    ml={"auto"}
                    step={1000}
                    min={1000}
                    max={100000}
                    onBlur={(e) => {
                      let v = parseInt(e.target.value);

                      setEnrich(Math.ceil(v / 1000));
                    }}
                  />
                </Group>

                <Group
                  style={{ border: "1px solid #00000033", borderRadius: 12 }}
                  p={"md"}
                  mt={"sm"}
                >
                  <Text size="lg">
                    <b style={{ fontSize: 32, marginRight: 8 }}>
                      <NumberFormatter value={price?.toFixed(0)} />€
                    </b>{" "}
                    {reccurence === "monthly"
                      ? lang.pricing.monthly_payment
                      : lang.pricing.annual_payment}
                  </Text>

                  <Button
                    size="md"
                    variant="gradient"
                    leftSection={<IconShoppingCartCheck />}
                    ml={"auto"}
                    radius={"sm"}
                    fw={700}
                    onClick={handleCheckout}
                  >
                    {lang.pricing.next}
                  </Button>
                </Group>

                <Stack>
                  {lang.pricing.premium.features.map((item, i) => (
                    <Group key={i}>
                      <ThemeIcon radius={"sm"} variant="light" color="primary">
                        <IconCheck size={20} />
                      </ThemeIcon>

                      <Text>{item}</Text>
                    </Group>
                  ))}
                </Stack>
              </Paper>
            </Group>
          </ArcherContainer>
        ) : (
          <Paper
            withBorder
            p={"sm"}
            component={Stack}
            radius={"lg"}
            mt={"sm"}
            w={"100%"}
            justify="center"
            style={{
              border: "2px solid var(--mantine-color-secondary-6)",
              overflow: "hidden",
            }}
            pos={"relative"}
          >
            <LoadingOverlay visible={loading} style={{ borderRadius: 24 }} />
            <Title order={4} ta={"center"}>
              {lang.pricing.premium.title}
            </Title>

            <Badge
              pos={"absolute"}
              top={16}
              left={-60}
              px={64}
              radius={0}
              variant="light"
              style={{ transform: "rotate(-30deg)" }}
              bg={"primary.0"}
            >
              {lang.pricing.free.actual}
            </Badge>
            <Text ta={"center"} size="md" c={"red"} fw={600}>
              {profile?.subscriptions?.end_date ? (
                <>
                  {lang.pricing.premium.will_end}:{" "}
                  <span style={{ fontWeight: 500 }}>
                    {parseDateFuture(profile?.subscriptions?.end_date)}
                  </span>
                </>
              ) : (
                <>{lang.pricing.free.ended}</>
              )}
            </Text>

            <Group wrap="nowrap" align="start" gap={"xl"}>
              <Stack w={"100%"}>
                <SegmentedControl
                  data={[
                    {
                      value: "monthly",
                      label: (
                        <Group w={"100%"} justify="center">
                          <Text>{lang.pricing.monthly}</Text>
                        </Group>
                      ),
                    },
                    {
                      value: "annual",
                      label: (
                        <Group w={"100%"} justify="center" pos={"relative"}>
                          <Text>{lang.pricing.annual}</Text>

                          <Badge color="tertiary.6" size="md" mr={-18}>
                            -20%
                          </Badge>
                        </Group>
                      ),
                    },
                  ]}
                  size="md"
                  value={reccurence}
                  onChange={setRecurrence}
                  disabled={profile?.subscriptions?.active === true}
                />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={250} gap={4}>
                    <Group>
                      <ThemeIcon variant="subtle">
                        <IconLinkedin size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.linkedin_accounts}</Text>
                    </Group>
                  </Stack>
                  <NumberInput
                    value={defaultValues?.linkedin || linkedin}
                    onChange={(v) => {
                      setDefaultValues((prev) => ({ ...prev, linkedin: 0 }));
                      setLinkedin(v);

                      // Check if same value, then remove it in edited
                      if (!defaultValuesStart?.linkedin) {
                        return setEdited((prev) => [...prev, "linkedin"]);
                      }
                      if (
                        defaultValuesStart?.linkedin &&
                        v !== defaultValuesStart?.linkedin
                      ) {
                        return setEdited((prev) => [...prev, "linkedin"]);
                      }
                      if (v === defaultValuesStart?.linkedin) {
                        setEdited((prev) =>
                          prev.filter((x) => x !== "linkedin")
                        );
                      }
                    }}
                    w={100}
                    ml={"auto"}
                    min={1}
                    max={1000}
                    size="md"
                  />
                </Group>

                <Divider my={-4} />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={250} gap={4}>
                    <Group>
                      <ThemeIcon color="orange" variant="subtle">
                        <IconMail size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.emails}</Text>
                    </Group>
                    <Text size="xs" c={"dimmed"}>
                      {lang.pricing.premium.email_desc}
                    </Text>
                  </Stack>
                  <NumberInput
                    value={defaultValues?.email || email * 5}
                    onChange={(v) => {
                      setDefaultValues((prev) => ({ ...prev, email: 0 }));
                      setEmail(v / 5);
                      // Check if same value, then remove it in edited
                      if (!defaultValuesStart?.email) {
                        return setEdited((prev) => [...prev, "email"]);
                      }
                      if (
                        defaultValuesStart?.email &&
                        v !== defaultValuesStart?.email
                      ) {
                        return setEdited((prev) => [...prev, "email"]);
                      }
                      if (v === defaultValuesStart?.email) {
                        setEdited((prev) => prev.filter((x) => x !== "email"));
                      }
                    }}
                    onBlur={(e) => {
                      let v = parseInt(e.target.value);

                      setEmail(Math.ceil(v / 5));
                    }}
                    step={5}
                    w={100}
                    ml={"auto"}
                    min={1}
                    max={1000}
                    size="md"
                  />
                </Group>

                <Divider my={-4} />

                <Group wrap="nowrap" gap={"xs"}>
                  <Stack w={300} gap={4}>
                    <Group>
                      <ThemeIcon color="tertiary.8" variant="subtle">
                        <IconUserSearch size={24} />
                      </ThemeIcon>
                      <Text mr={8}>{lang.pricing.enrich_monthly}</Text>

                      <EnrichInfo />
                    </Group>
                    <Text size="xs" c={"dimmed"}>
                      {lang.pricing.enrich_text}
                    </Text>
                  </Stack>
                  <NumberInput
                    value={defaultValues?.enrich || enrich * 1000}
                    onChange={(v) => {
                      setDefaultValues((prev) => ({ ...prev, enrich: 0 }));
                      setEnrich(v / 1000);
                      // Check if same value, then remove it in edited
                      if (
                        (defaultValuesStart?.enrich &&
                          v !== defaultValuesStart?.enrich) ||
                        !defaultValuesStart?.enrich
                      ) {
                        return setEdited((prev) => [...prev, "enrich"]);
                      }
                      if (v === defaultValuesStart?.enrich) {
                        setEdited((prev) => prev.filter((x) => x !== "enrich"));
                      }
                    }}
                    w={100}
                    size="md"
                    ml={"auto"}
                    step={1000}
                    min={1000}
                    max={1000000}
                    onBlur={(e) => {
                      let v = parseInt(e.target.value);

                      setEnrich(Math.ceil(v / 1000));
                    }}
                  />
                </Group>

                <Group
                  style={{ border: "1px solid #00000033", borderRadius: 12 }}
                  p={"md"}
                  mt={"sm"}
                >
                  <Price
                    defaultPrice={defaultPrice}
                    futurePrice={price}
                    reccurence={reccurence}
                    active={profile?.subscriptions?.active}
                  />

                  <Button
                    size="md"
                    variant="gradient"
                    rightSection={
                      profile?.subscriptions.subscription_id ? (
                        <IconArrowRight />
                      ) : (
                        <IconShoppingCartCheck />
                      )
                    }
                    ml={"auto"}
                    radius={"sm"}
                    fw={700}
                    onClick={handleCheckout}
                    disabled={edited.length === 0}
                  >
                    {profile?.subscriptions.subscription_id
                      ? lang.pricing.next
                      : lang.pricing.validate}
                  </Button>
                </Group>
              </Stack>

              <Divider orientation="vertical" />

              <Stack w={"100%"}>
                {lang.pricing.premium.features.map((item, i) => (
                  <Group key={i}>
                    <ThemeIcon radius={"sm"} variant="light" color="primary">
                      <IconCheck size={20} />
                    </ThemeIcon>

                    <Text>{item}</Text>
                  </Group>
                ))}
              </Stack>
            </Group>
          </Paper>
        )}

        <Divider mt={36} mb={12} w={"90%"} mx={"auto"} />

        <Paper
          withBorder
          p={"sm"}
          component={Stack}
          radius={"lg"}
          mt={"lg"}
          w={"100%"}
          gap={"xs"}
          bg={"var(--mantine-color-gray-0)"}
        >
          <Title order={5}>{lang.pricing.how_to_part.title}</Title>

          <Text>
            {lang.pricing.how_to_part.text1}{" "}
            <Anchor component={Link} to={"/resellers/white-labels"}>
              {lang.pricing.how_to_part.text2}
            </Anchor>{" "}
            {lang.pricing.how_to_part.text3} <br />{" "}
            {lang.pricing.how_to_part.text4}
          </Text>
        </Paper>

        <Divider mt={32} w={"90%"} mx={"auto"} />
        <FeaturesTable />
      </Stack>

      <ChangeValidation
        opened={opened}
        setOpened={setOpened}
        defaultValues={defaultValuesStart}
        email={email}
        enrich={enrich}
        linkedin={linkedin}
        price={price}
        defaultPrice={defaultPrice}
        edited={edited}
        reccurence={reccurence}
        productsMap={productsMap}
        setMainLoading={setLoading}
      />
    </>
  );
};

export default Pricing;
