import {
  Accordion,
  Anchor,
  Button,
  Checkbox,
  Divider,
  Group,
  NumberInput,
  Stack,
  Text,
  TextInput,
  Textarea,
} from "@mantine/core";
import {
  createLinkedinAccount,
  editLinkedinAccount,
  getLinkedinAccounts,
} from "../../../../../api/EmailAPI";
import { useState } from "react";
import { notifications } from "@mantine/notifications";
import { useLanguage } from "../../../../../stores/LanguageStore";
import LinkedinCookie from "./LinkedinCookie";
import BackButton from "../../../../../components/View/BackButton";
import {
  IconDeviceFloppy,
  IconExternalLink,
  IconPlus,
  IconSettings,
} from "@tabler/icons-react";
import Sparkles from "../../../../../components/Sparkles/Sparkles";
import useNavigationBlocker from "../../../../../components/View/NavigationBlocker";
import { useForm } from "react-hook-form";
import { SharingInAccordion } from "../../../../../components/Sharing/SharingController";
import PoolsSelector from "../../../../../components/Selectors/PoolsSelector";
import { useRouteLoaderData } from "react-router-dom";
import { useProfileStore, useSessionStore } from "../../../../../stores/UserStore";

const LinkedinAccount = ({
  onCreated,
  handleClose,
  isPage = false,
  accountProfile,
  inList,
}) => {
  const lang = useLanguage((s) => s.language);
  const reseller = useSessionStore((s) => s.reseller);
  const [loading, setLoading] = useState(false);
  const [purge, setPurge] = useState(
    !!accountProfile?.withdraw_pending_invitations
  );
  const actualPool = useRouteLoaderData("linkedin-pool");
  const [dirty, setDirty] = useState(false);
  const [pools, setPools] = useState(
    accountProfile?.pools
      ? accountProfile.pools
      : actualPool
      ? [actualPool]
      : []
  );
  const sharingForm = useForm({
    defaultValues: {
      sharing:
        accountProfile?.sharing?.map((x) => ({
          ...x,
          sharing_targets: x.sharing_targets.map((sharing_target) => ({
            ...sharing_target,
            option_name: sharing_target.name,
          })),
        })) || [],
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    let fd = new FormData(e.target);
    let data = Object.fromEntries(fd);

    data.is_sales_navigator_account = data.is_sales_navigator_account
      ? true
      : false;
    data.accept_received_invitations = data.accept_received_invitations
      ? true
      : false;
    data.withdraw_pending_invitations = data.withdraw_pending_invitations
      ? true
      : false;
    data.withdraw_pending_invitations_number =
      data.withdraw_pending_invitations_number
        ? parseInt(data.withdraw_pending_invitations_number)
        : 100;
    data.max_conversations_messages_extractor =
      data.max_conversations_messages_extractor
        ? parseInt(data.max_conversations_messages_extractor)
        : 100;
    data.withdraw_pending_invitations_oldest =
      data.withdraw_pending_invitations_oldest ? true : false;
    data.signature = "";
    data.pools_ids = pools?.map((x) => x.id) || [];
    data.user_agent = data?.user_agent || null;

    const sharing = isPage
      ? sharingForm
          .getValues()
          .sharing.filter((x) => x.sharing_targets.length > 0)
          .map((share) => ({
            ...share,
            ids_list: share.sharing_targets.map((x) => x.id),
            sharing_targets: undefined,
          }))
      : [];

    setLoading(true);

    const main = () =>
      accountProfile
        ? editLinkedinAccount({
            id: accountProfile.id,
            data,
            sharing: sharing?.length > 0 ? sharing : null,
          })
        : createLinkedinAccount(data, sharing);

    main()
      .then((response) => {
        setLoading(true);
        setDirty(false);
        if (!isPage) {
          getLinkedinAccounts()
            .then((res) => {
              let newItemsList = res.data.linkedin_accounts_list;
              let newItem = newItemsList.find(
                (x) => x.id === response.data.linkedin_account_id
              );
              notifications.show({
                title: lang.senders.linkedin.added,
                message: lang.senders.linkedin.added_text,
                color: "tertiary.8",
              });
              onCreated?.(newItem);
              handleClose?.();
            })
            .finally(() => {
              setLoading(false);
            });
        } else {
          notifications.show({
            message: accountProfile
              ? lang.linkedin_accounts.row_highlight.updated
              : lang.linkedin_accounts.row_highlight.created,
            color: "tertiary.8",
          });
          setTimeout(() => {
            onCreated?.(
              accountProfile?.id || response.data.linkedin_account_id
            );
            handleClose?.();
          }, 100);
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useNavigationBlocker(dirty);

  const main = (
    <Stack
      component={"form"}
      onSubmit={handleSubmit}
      onChange={() => setDirty(true)}
      id="form"
    >
      <TextInput
        label={lang.linkedin_accounts.create.dialog.name}
        defaultValue={accountProfile?.name}
        required
        name="name"
        placeholder="John Doe"
      />
      <Textarea
        name="session_cookie"
        label={lang.linkedin_accounts.create.dialog.cookie}
        defaultValue={accountProfile?.session_cookie}
        placeholder="AQEDAQAQvlsAEoReAA..."
        rows={4}
        required
      />
      <Textarea
        name="session_cookie_sn"
        label={lang.linkedin_accounts.create.dialog.cookie_sn}
        defaultValue={accountProfile?.session_cookie_sn}
        placeholder="AQEDAQAQvlsAEoReAA..."
        rows={4}
      />
      {reseller?.name === "Magileads" && <LinkedinCookie />}
      
      {
        useProfileStore.getState().profile?.level === "admin" &&
        <TextInput
          name="user_agent"
          label={lang.linkedin_accounts.create.dialog.user_agent}
          defaultValue={accountProfile?.user_agent}
          placeholder="Mozilla/5.0 (Windows NT 10.0; Win64; x64)"
          description="It must be the same than the one used when logging in to LinkedIn"
        />
      }

      <PoolsSelector type={"Linkedin"} value={pools} setValue={setPools} />

      <Accordion mt={"md"} variant="contained">
        <Accordion.Item value={"advanced"}>
          <Accordion.Control
            bg={"var(--mantine-color-gray-0)"}
            icon={<IconSettings size={18} />}
            pl={"xs"}
          >
            {lang.senders.linkedin.advanced}
          </Accordion.Control>
          <Accordion.Panel>
            <Stack>
              <Checkbox
                label={
                  lang.linkedin_accounts.create.dialog
                    .is_sales_navigator_account
                }
                name="is_sales_navigator_account"
                defaultChecked={accountProfile?.is_sales_navigator_account}
              />
              <Checkbox
                label={
                  lang.linkedin_accounts.create.dialog
                    .accept_received_invitations
                }
                name="accept_received_invitations"
                mt={"xs"}
                defaultChecked={accountProfile?.accept_received_invitations}
              />
              <NumberInput
                label={
                  lang.linkedin_accounts.create.dialog
                    .max_conversations_messages_extractor
                }
                min={1}
                max={100}
                defaultValue={
                  accountProfile?.max_conversations_messages_extractor || 100
                }
                name="max_conversations_messages_extractor"
              />
              <Divider />
              <Checkbox
                label={
                  lang.linkedin_accounts.create.dialog
                    .withdraw_pending_invitations
                }
                name="withdraw_pending_invitations"
                onChange={(e) => setPurge(e.target.checked)}
                defaultChecked={accountProfile?.withdraw_pending_invitations}
              />
              <Checkbox
                label={
                  lang.linkedin_accounts.create.dialog
                    .withdraw_pending_invitations_oldest
                }
                name="withdraw_pending_invitations_oldest"
                defaultChecked={
                  accountProfile?.withdraw_pending_invitations_oldest
                }
                disabled={!purge}
              />
              <NumberInput
                label={
                  lang.linkedin_accounts.create.dialog
                    .withdraw_pending_invitations_number
                }
                min={1}
                max={1000}
                defaultValue={
                  accountProfile?.withdraw_pending_invitations_number || 100
                }
                name="withdraw_pending_invitations_number"
                disabled={!purge}
              />
            </Stack>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      {isPage && <SharingInAccordion form={sharingForm} />}

      {accountProfile && (
        <Group justify="end">
          {!inList && <BackButton onClick={handleClose} />}
          <Button
            form="form"
            type="submit"
            leftSection={<IconDeviceFloppy size={18} />}
            color="primary"
            loading={loading}
          >
            {lang.linkedin_accounts.edit.dialog.submit_button}
          </Button>
        </Group>
      )}
    </Stack>
  );

  return (
    <Stack w={"100%"} flex={1} gap={"sm"}>
      {accountProfile ? (
        main
      ) : (
        <Group flex={"1 1 auto"} wrap="nowrap" gap={"xl"} align="stretch">
          <Stack mih={"100%"} flex={"1 1 auto"} style={{ overflow: "auto" }}>
            <Text size="sm" mt={8}>
              {lang.senders.linkedin.extension_text}
            </Text>

            {reseller?.link_chrome_extension && (
              <Sparkles>
                <Anchor
                  href={
                    reseller?.name === "Magileads"
                      ? "https://chromewebstore.google.com/detail/magileads-v2/oojfjkpfkaekaifokifpgfaghiokinaf"
                      : reseller?.link_chrome_extension
                  }
                  target="_blank"
                  onClick={() => handleClose()}
                >
                  <Button
                    rightSection={<IconExternalLink size={22} />}
                    size="lg"
                    variant="gradient"
                    w={"100%"}
                  >
                    {lang.senders.linkedin.extenstion_label.replace(
                      "#",
                      reseller?.name
                    )}
                  </Button>
                </Anchor>
              </Sparkles>
            )}

            <Accordion
              variant="default"
              mb={"xs"}
              defaultValue={"manual"}
              style={{
                borderTop: "1px solid #00000018",
              }}
            >
              <Accordion.Item value={"manual"}>
                <Accordion.Control>
                  {lang.senders.linkedin.manual_config}
                </Accordion.Control>
                <Accordion.Panel>{main}</Accordion.Panel>
              </Accordion.Item>
            </Accordion>

            <Group justify="end">
              {!inList && <BackButton onClick={handleClose} />}
              <Button
                form="form"
                type="submit"
                leftSection={
                  accountProfile ? (
                    <IconDeviceFloppy size={18} />
                  ) : (
                    <IconPlus size={18} />
                  )
                }
                color="primary"
                loading={loading}
              >
                {accountProfile
                  ? lang.linkedin_accounts.edit.dialog.submit_button
                  : lang.linkedin_accounts.create.dialog.submit_button}
              </Button>
            </Group>
          </Stack>

          {useSessionStore.getState().reseller?.name === "Magileads" && (
            <Stack align="center" flex={1} pt={40}>
              <iframe
                width="480"
                height="360"
                src="https://www.youtube.com/embed/hRICGIU7Hl4?si=ObtaskiMyE2MhAhC"
                title="YouTube video player"
                allowfullscreen=""
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                style={{
                  borderRadius: 12,
                  border: "1px solid #00000033",
                  margin: "0 auto",
                }}
              />
            </Stack>
          )}
        </Group>
      )}
    </Stack>
  );
};

export default LinkedinAccount;
