import {
  Button,
  Divider,
  Group,
  HoverCard,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { IconLinkedin } from "../../../components/Icons/IconLinkedin";
import { IconGmaps } from "../../../components/Icons/IconGmaps";
import mockup from "../../../assets/mockup2.png";
import { IconChevronDown, IconSparkles } from "@tabler/icons-react";
import { IconCSV } from "../../../components/Icons/IconCSV";
import LinkedinImport from "./LinkedinImport";
import GoogleMapsImport from "./GoogleMapsImport";
import AiImport from "./AiImport";
import ManualImport from "./ManualImport";

const iconProps = { size: 32 };

const DefaultContent = ({ active }) => {
  if (active !== "def") {
    return "";
  }
  return (
    <Paper
      className="def-section section animate__animated animate__fadeIn animate__faster"
      component={Group}
      justify="center"
      w={"100%"}
      mt={60}
    >
      <img
        src={mockup}
        style={{
          height: 320,
        }}
      />
    </Paper>
  );
};

const ContactListImport = () => {
  const [active, setActive] = useState("def");
  const elements = useMemo(() => {
    return {
      linkedin: {
        label: "LinkedIn",
        icon: <IconLinkedin {...iconProps} />,
        text: "Importez vos listes depuis Linkedin, basique ou sales Navigator.",
        path: "import/linkedin",
      },
      gmaps: {
        label: "Google Maps",
        icon: <IconGmaps {...iconProps} />,
        text: "Importez vos listes depuis Google Maps en précisant la localisation ciblée.",
        path: "import/google",
      },
      manual: {
        label: "Importer un fichier",
        icon: <IconCSV {...iconProps} />,
        text: "Importez votre fichier Excel et centralisez vos contacts sur la plateforme.",
        path: "import/manual",
      },
      ai: {
        label: "Recherche avec IA",
        icon: <IconSparkles {...iconProps} />,
        text: "Essayez la recherche rapide IA, qui adapte la source de scrapping à vos critères de recherche.",
        path: "import/quick",
      },
    };
  }, []);

  return (
    <Stack
      style={{
        background: "#fff",
      }}
      flex={1}
      pb={16}
      mx={0}
      w={"100%"}
      className="list-import"
    >
      <Title order={3} mt={16} fw={500} ml={24}>
        Suivez ces étapes pour créer vos listes de contacts.
      </Title>

      <Divider />

      <Title order={4} c={"primary"} mb={0} ml={24} fw={500}>
        Où sont vos leads ?
      </Title>

      <Group wrap="nowrap" align="start" gap={48} ml={24}>
        <Stack w={"50%"} className="buttons" gap={0}>
          {Object.keys(elements).map((key) => {
            let item = elements[key];

            if (key === "def") {
              return "";
            }

            return (
              <HoverCard
                key={key}
                position="right"
                offset={active === key ? 8 : -32}
                radius={8}
                closeDelay={0}
              >
                <HoverCard.Target>
                  <Group wrap="nowrap">
                    <Button
                      size="xl"
                      w={active === key ? "100%" : "90%"}
                      justify="start"
                      radius={"lg"}
                      my={"sm"}
                      variant={active === key ? "light" : "default"}
                      className="new-button bump-stroke"
                      leftSection={
                        <ThemeIcon
                          variant="subtle"
                          size={"lg"}
                          radius={"lg"}
                          // color="secondary"
                        >
                          {item.icon}
                        </ThemeIcon>
                      }
                      rightSection={
                        <IconChevronDown
                          size={24}
                          opacity={active === key ? 1 : 0.4}
                          style={{
                            transform:
                              active === key ? "rotate(-90deg)" : "none",
                            transition: "all ease .3s",
                          }}
                        />
                      }
                      styles={{
                        label: {
                          marginLeft: 16,
                        },
                      }}
                      onClick={() => {
                        setActive(active === key ? "def" : key);
                      }}
                      style={{
                        transition: "all ease .3s",
                        // borderTopRightRadius: active === key ? 0 : 12,
                        // borderBottomRightRadius: active === key ? 0 : 12,
                        // borderRight:
                        //   active === key ? "2px solid" : "1px solid #00000033",
                        opacity:
                          active === "def" ? 1 : active === key ? 1 : 0.5,
                      }}
                    >
                      {item.label}
                    </Button>

                    {active !== key && (
                      <Divider ml={32} orientation="vertical" />
                    )}
                  </Group>
                </HoverCard.Target>

                <HoverCard.Dropdown p={"md"} bg={"gray.2"}>
                  <Text>{item.text}</Text>
                </HoverCard.Dropdown>
              </HoverCard>
            );
          })}
        </Stack>

        <Stack
          w={"100%"}
          gap={0}
          pos={"relative"}
          style={{ overflow: "hidden", minHeight: 400,  }}
          mt={-46}
        >
          <DefaultContent active={active} />

          <LinkedinImport active={active} />

          <GoogleMapsImport active={active} />

          <AiImport active={active} />

          <ManualImport isActive={active} />
        </Stack>
      </Group>
    </Stack>
  );
};

export default ContactListImport;
