import {
  ActionIcon,
  Anchor,
  Button,
  Divider,
  Group,
  Stack,
  Tooltip,
} from "@mantine/core";
import { Logo } from "../Logo";
import { useUIStore } from "../../stores/UIStore";
import { IconChevronRightPipe, IconHelp } from "@tabler/icons-react";
import { Link } from "react-router-dom";
import HeaderProfile from "./HeaderProfile";
import { IconChevronLeftPipe } from "@tabler/icons-react";
import AllSwitcher from "../Footer/AllSwitcher";
import { useProfileStore, useSessionStore } from "../../stores/UserStore";
import { useLayoutEffect, useMemo } from "react";
import Notifications from "./Notifications";
import LanguageChanger from "./LanguageChanger";
import VersionController from "../../components/View/VersionController";
import { getPermission } from "../../functions/utilFunctions";

const Header = ({ navDisabled }) => {
  const reseller = useSessionStore((s) => s.reseller);
  const asideCollapsed = useUIStore((s) => s.asideCollapsed);
  const setAsideCollapsed = useUIStore((s) => s.setAsideCollapsed);
  const unswitchedProfile = useProfileStore((s) => s.unswitchedProfile);
  const profile = useProfileStore((s) => s.profile);

  const canSwitch = useMemo(() => {
    let p = unswitchedProfile || profile;
    if (
      !p?.permissions?.find((x) => x.name === "switchOrganizationUser")
        ?.value &&
      !p?.permissions?.find((x) => x.name === "switchTeamUser")?.value &&
      p?.level === "user"
    ) {
      return false;
    }
    return true;
  }, [unswitchedProfile, profile]);

  useLayoutEffect(() => {
    if (reseller?.name === "Magileads") {
      (function (d, t) {
        var BASE_URL = "https://app.chatwoot.com";
        var g = d.createElement(t),
          s = d.getElementsByTagName(t)[0];
        g.src = BASE_URL + "/packs/js/sdk.js";
        g.defer = true;
        g.async = true;
        s.parentNode.insertBefore(g, s);
        g.onload = function () {
          window.chatwootSDK.run({
            websiteToken: "3JpcGGTHAx4SAD469xDEAHxV",
            baseUrl: BASE_URL,
          });
        };
      })(document, "script");
    }
  }, [reseller]);

  return (
    <Stack gap={0}>
      <Group
        align={"center"}
        gap={0}
        h={"100%"}
        py={0}
        w={"100%"}
        wrap="nowrap"
        style={{ borderBottom: "1px solid #00000018" }}
        bg={
          reseller?.color_muted !== "A8A8A8"
            ? `#${reseller.color_muted}`
            : `#${reseller.color_primary}`
        }
      >
        {!navDisabled && getPermission("displaySideMenu") && (
          <ActionIcon
            onClick={() => setAsideCollapsed(!asideCollapsed)}
            h={60}
            w={48}
            radius={0}
            variant="subtle"
            color="white"
          >
            {asideCollapsed ? (
              <IconChevronRightPipe size={28} style={{ strokeWidth: 1.1 }} />
            ) : (
              <IconChevronLeftPipe size={28} style={{ strokeWidth: 1.1 }} />
            )}
          </ActionIcon>
        )}

        <Link to={"/"}>
          <Group w={300} ml={16}>
            <Logo h={42} sm />
          </Group>
        </Link>

        <VersionController />

        <Group ml={"auto"}>
          <Tooltip label="Support">
            <Button
              variant="subtle"
              px={12}
              size="lg"
              component={Anchor}
              href={"/support"}
              target="_blank"
              color="white"
            >
              <IconHelp />
            </Button>
          </Tooltip>

          <Notifications />

          {!useSessionStore.getState().user_switch && <LanguageChanger />}
        </Group>

        <Divider orientation="vertical" my={16} mx={"md"} opacity={0.75} />

        <HeaderProfile />

        {canSwitch && <AllSwitcher />}
      </Group>

      {/* <NavbarHeader /> */}
    </Stack>
  );
};

export default Header;
