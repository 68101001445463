import { useNavigate, useParams } from "react-router-dom";
import { getTypeIcon } from "../../../workflows/CreateCampaign/flow/flowSettings";
import { Anchor, Button, Group, Modal, Stack, Text } from "@mantine/core";
import { useLanguage } from "../../../../../stores/LanguageStore";
import ovh_free from "../../../../../assets/logos/ovh_free.png";
import ovh_pro from "../../../../../assets/logos/ovh_pro.png";
import BackButton from "../../../../../components/View/BackButton";
import { useDisclosure } from "@mantine/hooks";

const CreateSMTP = ({ buttonProps, iconSize = 18, inReseller }) => {
  const lang = useLanguage((s) => s.language);
  const [opened, { open, close }] = useDisclosure();

  const navigate = useNavigate();
  const { poolId } = useParams();
  const route = poolId ? `/senders/email/pool/${poolId}` : `/senders/email`;

  const handleNavigate = (type) => {
    let smtp_host = type === "ovh_free" ? "ssl0.ovh.net" : "pro1.mail.ovh.net";
    let smtp_port = type === "ovh_free" ? 465 : 587;
    let smtp_secure = type === "ovh_pro" ? "tls" : "ssl";
    let imap_host = type === "ovh_free" ? "ssl0.ovh.net" : "pro1.mail.ovh.net";
    let imap_port = 993;
    let imap_secure = "ssl";

    if (type !== "custom")
      return navigate(
        `${route}/create/smtp?smtp_host=${smtp_host}&smtp_port=${smtp_port}&smtp_secure=${smtp_secure}&imap_host=${imap_host}&imap_port=${imap_port}&imap_secure=${imap_secure}`
      );
    return navigate(`${route}/create/smtp`);
  };

  return (
    <>
      {inReseller ? (
        <Anchor my={-8} size="sm" onClick={open}>
          {lang.resellers.integration_smtp_add}
        </Anchor>
      ) : (
        <Button
          leftSection={getTypeIcon(
            "SMTP",
            false,
            iconSize,
            { variant: "subtle", color: "primary" },
            true
          )}
          variant="subtle"
          w={"100%"}
          justify="start"
          c={"var(--mantine-color-gray-7)"}
          onClick={open}
          {...buttonProps}
        >
          {lang.senders.emails.other_smtp}
        </Button>
      )}

      <Modal
        opened={opened}
        title={lang.emails_account.smtp.step_supplier.title}
        size={"md"}
      >
        <Stack>
          <Text>{lang.emails_account.smtp.step_supplier.text}</Text>
          <Button
            variant="subtle"
            onClick={() => handleNavigate("ovh_free")}
            h={128}
            size="xl"
            w={"100%"}
            style={{ border: "1px solid #00000021" }}
          >
            <img src={ovh_free} alt="ovh_free" />
          </Button>
          <Button
            variant="subtle"
            onClick={() => handleNavigate("ovh_pro")}
            h={128}
            size="xl"
            w={"100%"}
            style={{ border: "1px solid #00000021" }}
          >
            <img src={ovh_pro} alt="ovh_pro" />
          </Button>
          <Button
            variant="subtle"
            onClick={() => handleNavigate("custom")}
            h={128}
            size="xl"
            w={"100%"}
            style={{ border: "1px solid #00000021" }}
          >
            {lang.emails_account.smtp.step_supplier.other_supplier}
          </Button>

          <Group justify="end" mt={"md"}>
            <BackButton onClick={close} />
          </Group>
        </Stack>
      </Modal>
    </>
  );
};

export default CreateSMTP;
