import { useEffect, useState } from "react";
import {
  findLinkedinInvitation,
  findLinkedinMessage,
  findSMS,
} from "../../api/ModelsApi";
import {
  LoadingOverlay,
  Overlay,
  Paper,
  Stack,
  Text,
  TextInput,
} from "@mantine/core";
import { replaceTokens } from "./previewUtils";
import { IconSend } from "@tabler/icons-react";
import { useLanguage } from "../../stores/LanguageStore";

const MessagePreviewer = ({
  model_id,
  type,
  senderSize = "sm",
  text,
  sender,
  withExample,
  contact,
  mah,
}) => {
  const lang = useLanguage((s) => s.language);
  const [modelProfile, setModelProfile] = useState({ text, sender });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (model_id) {
      setLoading(true);
      let func;
      if (type === "linkedin_invitation") func = findLinkedinInvitation;
      if (type === "linkedin_message") func = findLinkedinMessage;
      if (type === "sms") func = findSMS;
      func(model_id)
        .then((response) => setModelProfile(response.data.model_profile))
        .finally(() => setLoading(false));
    } else if (!text) {
      setModelProfile(null);
    }
  }, [model_id, type, text]);

  return (
    <Stack
      pos={"relative"}
      gap={"sm"}
      flex={1}
      w={"100%"}
      mah={mah}
      style={{ border: "1px solid #00000018", overflow: "auto" }}
      px={12}
      pt={12}
    >
      <LoadingOverlay visible={loading} />
      {type === "sms" && (
        <TextInput
          value={sender || modelProfile?.sender || ""}
          readOnly
          size={senderSize}
          placeholder={lang.components.email_preview.sender}
          leftSection={<IconSend size={16} />}
          w={300}
        />
      )}

      {withExample && (
        <Paper
          withBorder
          style={{ boxShadow: "#00000018 1px 2px 4px", overflow: "hidden" }}
          bg={"var(--mantine-color-gray-1)"}
          radius={"md"}
          p={"sm"}
          py={"xs"}
          w={"fit-content"}
          mr={"auto"}
          miw={100}
          maw={300}
          mt={"auto"}
          pos={"relative"}
        >
          <Overlay blur={5} backgroundOpacity={0} />
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Veniam
          aspernatur minus facere dolor.
        </Paper>
      )}

      <Paper
        withBorder
        style={{ boxShadow: "#00000018 1px 2px 4px" }}
        bg={"var(--mantine-primary-color-1)"}
        radius={"md"}
        p={"sm"}
        py={"xs"}
        w={"fit-content"}
        ml={"auto"}
        miw={200}
        maw={340}
        mt={withExample ? 16 : "auto"}
      >
        {replaceTokens({ text: modelProfile?.text, contact })
          .split("\n")
          .map((x, i) => (
            <Text key={i} mih={16}>
              {x || " "}
            </Text>
          ))}
      </Paper>
      <Text ta={"right"} size="sm" c={"dimmed"} w={"100%"} mb={"lg"}>
        {new Date().toLocaleString(lang.id === "FRA" ? "fr-FR" : "en-EN", {
          day: "2-digit",
          month: "short",
          year: "numeric",
          hour: "2-digit",
          minute: "2-digit",
          hourCycle: "h23",
        })}
      </Text>
    </Stack>
  );
};

export default MessagePreviewer;
