import {
  Accordion,
  ActionIcon,
  Anchor,
  Button,
  Checkbox,
  Group,
  Image,
  Input,
  InputLabel,
  NumberInput,
  Paper,
  Radio,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import {
  IconArrowBarUp,
  IconListDetails,
  IconPlus,
  IconSettings,
  IconSparkles,
  IconTrash,
  IconVideo,
} from "@tabler/icons-react";
import { useEffect, useMemo, useState } from "react";
import LinkedinAccountSelector from "../../../components/Selectors/LinkedinAccountSelector";
import { useLanguage } from "../../../stores/LanguageStore";
import { useRouteLoaderData } from "react-router-dom";
import { notifications } from "@mantine/notifications";
import GlobalLanguageSelector from "../../../components/Selectors/GlobalLanguageSelector";
import CountrySelector from "../../../components/Selectors/CountrySelector";
import { useProfileStore } from "../../../stores/UserStore";
import CreatableContactListSelector from "../../../components/Selectors/CreatableContactListSelector";
import RemainingHoverCard from "../../protected/lists/components/RemainingHoverCard";
import HowItWorks from "../../protected/lists/pages/HowItWorks";
import {
  extractLSearch,
  extractLSearchSN,
} from "../../../api/TargetingLinkedInAPI";
import { handleLinks } from "../../protected/lists/import/linkedin/LinkedinLinks";
import { Carousel } from "@mantine/carousel";
import slider1 from "../../../assets/linkedin-slider1.png";
import slider2 from "../../../assets/linkedin-slider2.png";
import slider3 from "../../../assets/linkedin-slider3.png";
import "@mantine/carousel/styles.css";

const LinkItem = ({ value, handleDelete }) => {
  return (
    <Stack
      style={{
        border: "#00000021 solid 1px",
        padding: 8,
        paddingRight: 8,
        position: "relative",
        marginBottom: 1,
        borderRadius: 4,
        boxShadow: "#00000009 0 2px 3px",
      }}
    >
      <Anchor
        size="md"
        maw={500}
        style={{
          textWrap: "nowrap",
        }}
        href={value}
        target="_blank"
        truncate
      >
        {value}
      </Anchor>

      <ActionIcon
        variant="subtle"
        color="red"
        className="on-hover-opacity"
        style={{
          position: "absolute",
          right: 8,
          top: "50%",
          transform: "translateY(-50%)",
          minWidth: 0,
        }}
        onClick={handleDelete}
      >
        <IconTrash size={16} />
      </ActionIcon>
    </Stack>
  );
};

const GoogleMapsImport = ({ onFinish, active }) => {
  const lang = useLanguage((s) => s.language);
  const profile = useProfileStore((s) => s.profile);
  const [account, setAccount] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");

  //#region Items
  const [step, setStep] = useState(0);
  const [links, setLinks] = useState([]);
  const addLink = (value) => {
    const regex = /^https:\/\/www\.linkedin\.[a-z]/;
    if (!regex.test(value)) {
      return notifications.show({
        message: lang.targeting_linkedin_google.bad_link,
        color: "red",
      });
    }
    if (links.includes(value)) {
      return notifications.show({
        message: lang.targeting_linkedin_google.link_already_exist,
        color: "red",
      });
    }
    if (links.length <= 5) setLinks((prev) => [...prev, value]);
    document.getElementById("url-search").value = "";
    document.getElementById("url-search").focus();
  };

  const removeLink = (value) => {
    setLinks((prev) => prev.filter((x) => x !== value));
  };
  //#endregion

  const [loading, setLoading] = useState(false);
  const listDataLoader = useRouteLoaderData("contact-list");
  const listDataFolderLoader = useRouteLoaderData("folder-contact-list");
  const listProfile = useMemo(() => {
    return listDataLoader || listDataFolderLoader;
  }, [listDataLoader, listDataFolderLoader]);
  const [list, setList] = useState(listProfile);
  const [listState, setListState] = useState(listProfile ? 2 : 1);

  const handleSubmit = (e) => {};

  if (active !== "gmaps") {
    return;
  }

  return (
    <Stack
      pr={32}
      className="animate__animated animate__fadeIn animate__faster"
    >
      <Group>
        <Title order={4} c={"primary"} fw={500}>
          Importez depuis Google Maps
        </Title>

        <HowItWorks type={"google"} inside />
      </Group>

      <Group pos={"relative"}>
        {links.length === 0 ? (
          <TextInput
            label={"Copier coller l’URL de recherche"}
            id="url-search"
            autoFocus
            w={"100%"}
            placeholder="https://www.google.com/maps/search/..."
            type="url"
            size="md"
            required
            radius={"md"}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addLink(document.getElementById("url-search").value);
              }
            }}
            styles={{
              label: {
                marginBottom: 2,
              },
            }}
            rightSection={
              <Button
                leftSection={<IconPlus />}
                variant="subtle"
                onClick={() =>
                  addLink(document.getElementById("url-search").value)
                }
                ml={"auto"}
                disabled={links.length > 4}
                mr={6}
                size="xs"
              >
                Ajouter
              </Button>
            }
            rightSectionWidth={116}
          />
        ) : (
          <TextInput
            label={"Copier coller l’URL de recherche"}
            id="url-search"
            autoFocus
            w={"100%"}
            placeholder="https://www.google.com/maps/search/..."
            type="url"
            size="md"
            required
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                addLink(document.getElementById("url-search").value);
              }
            }}
            rightSection={
              <Button
                leftSection={<IconPlus />}
                variant="subtle"
                onClick={() =>
                  addLink(document.getElementById("url-search").value)
                }
                ml={"auto"}
                disabled={links.length > 4}
                mr={6}
                size="xs"
              >
                Ajouter
              </Button>
            }
            rightSectionWidth={116}
            styles={{
              label: {
                marginBottom: 2,
              },
            }}
            radius={"md"}
          />
        )}
        {links.length > 0 && (
          <Stack gap={0} w={"100%"}>
            <Group w={"100%"}>
              <Input.Label size="sm" fz={14}>
                {links.length} {lang.targeting_linkedin_google.added_links}{" "}
                <span style={{ opacity: 0.45, fontSize: 10 }}>{"max: 5"}</span>
              </Input.Label>
            </Group>

            <Stack
              style={{
                border: "1px solid #00000012",
                overflow: "auto",
                borderRadius: 4,
              }}
              h={162}
              p={"xs"}
              gap={"xs"}
              mt={6}
            >
              {links.length > 0 ? (
                links.map((link) => (
                  <LinkItem
                    value={link}
                    key={link}
                    handleDelete={() => removeLink(link)}
                  />
                ))
              ) : (
                <Text opacity={0.5} size="sm">
                  {lang.targeting_linkedin_google.no_links}
                </Text>
              )}
            </Stack>
          </Stack>
        )}

        <Button
          leftSection={<IconVideo size={18} />}
          variant="subtle"
          pos={"absolute"}
          top={0}
          right={0}
          size="compact-xs"
          color="secondary"
        >
          Voir le tuto
        </Button>
      </Group>

      <Accordion variant="contained" w={"100%"} radius={"md"}>
        <Accordion.Item value="list">
          <Accordion.Control icon={<IconListDetails size={18} opacity={0.5} />}>
            <InputLabel size="md" required>
              Nom de la liste
            </InputLabel>
          </Accordion.Control>

          <Accordion.Panel>
            <Group gap={"xl"} mb={12}>
              <Checkbox
                label={lang.targeting_linkedin_google.create_new_list}
                name="list_state"
                checked={listState === 1}
                size="sm"
                onChange={(e) => setListState(1)}
              />

              <Checkbox
                label={lang.targeting_linkedin_google.import}
                name="list_state"
                size="sm"
                checked={listState === 2}
                onChange={(e) => setListState(2)}
              />
            </Group>

            {listState === 1 ? (
              <TextInput
                id="new-list-name"
                name="contact_list_name"
                w={"100%"}
                disabled={listState !== 1}
                label={lang.targeting_linkedin_google.list_name}
                placeholder={lang.targeting_linkedin_google.list_name}
                required={listState === 1}
                size="sm"
              />
            ) : (
              <CreatableContactListSelector
                value={list}
                setValue={setList}
                label={lang.targeting_linkedin_google.select_list}
                placeholder={lang.contact_lists.create.dialog.desc}
                noDesc
                w={"100%"}
                disabled={listState !== 2}
                required={listState === 2}
                disableCreate
                size="sm"
              />
            )}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Accordion variant="filled" w={"100%"} radius={"md"}>
        <Accordion.Item value="more">
          <Accordion.Control icon={<IconSettings size={18} opacity={0.5} />}>
            Plus d'options
          </Accordion.Control>

          <Accordion.Panel>
            <Group w={"100%"} wrap="nowrap" gap={"xl"}>
              <GlobalLanguageSelector
                key={"language-" + list?.id}
                defaultValue={list?.language || profile?.contact_list_language}
                name={"contact_list_language"}
                size="sm"
                flex={1}
              />
              <CountrySelector
                key={"country-" + list?.id}
                defaultValue={list?.country || profile?.contact_list_country}
                name={"contact_list_country"}
                required
                flex={1}
                size="sm"
              />
              <NumberInput
                name="max_results"
                label={"Contacts à importer"}
                required
                min={1}
                max={100}
                clampBehavior="strict"
                // description={
                //   isSales ? "max: 2500" : "max: 1000, Sales Navigator: 2500"
                // }
                flex={1}
                allowNegative={false}
                allowDecimal={false}
                placeholder={"Contacts à importer"}
                defaultValue={100}
                size="sm"
              />
            </Group>
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>

      <Group justify="center" mt={"lg"} gap={"xl"} wrap="nowrap">
        {profile?.permissions?.find((x) => x.name === "generateEmail")
          ?.value && (
          <Group wrap="nowrap" w={300} gap={"md"}>
            <RemainingHoverCard>
              <Button
                className="new-button"
                leftSection={<IconSparkles />}
                loading={loading === "yes"}
                onClick={(e) => handleSubmit({ ...e, generate_email: true })}
                w={250}
                size="md"
              >
                Importer avec emails
              </Button>
            </RemainingHoverCard>
          </Group>
        )}
      </Group>

      <Paper withBorder shadow="lg" p={"lg"} radius={"md"} mb={32} mt={16}>
        <Text mb={16} fw={400}>
          Comment importer vos contacts sur Google Maps
        </Text>

        <Image src={slider1} h={200} fit="cover" />
      </Paper>
    </Stack>
  );
};

export default GoogleMapsImport;
