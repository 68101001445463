import { useCallback, useEffect, useMemo, useState } from "react";
import { useUIStore } from "../../../../stores/UIStore";
import {
  ActionIcon,
  Anchor,
  Flex,
  Group,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import { useLanguage } from "../../../../stores/LanguageStore";
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  useGridApiRef,
} from "@mui/x-data-grid-pro";
import CampaignFilter from "./CampaignFilter";
import {
  archiveCampaign,
  editCampaign,
  getCampaignsStats,
} from "../../../../api/StatsAPI";
import dateColumn from "../../../../layouts/Table/columns/dateColumn";
import MainTable from "../../../../layouts/Table/MainTable";
import TableLayoutBlock from "../../../../layouts/Table/TableLayoutBlock";
import CampaignColumnId from "./columns/CampaignColumnId";
import { CampaignColumnStatus } from "./columns/CampaignColumnStatus";
import { CampaignColumnName } from "./columns/CampaignColumnName";
import { CampaignLastSent } from "./columns/CampaignLastSent";
import { IconArchive, IconEdit, IconRepeat } from "@tabler/icons-react";
import { CampaignColumnContacted } from "./columns/CampaignColumnContacted";
import { CampaignColumnRates } from "./columns/CampaignColumnRates";
import { CampaignColumnSender } from "./columns/CampaignColumnSender";
import { CampaignColumnDetails } from "./columns/CampaignColumnDetails";
import SelectionDeleter from "../../../../components/TableComponents/SelectionDeleter";
import { usePrmStore } from "../../../../stores/PrmStore";
import UnarchiveCampaign from "./components/UnarchiveCampaign";
import ArchiveCampaign from "./components/ArchiveCampaign";
import { sharingColumn } from "../../../../layouts/Table/columns/sharingColumn";
import { useProfileStore } from "../../../../stores/UserStore";
import { Link } from "react-router-dom";
import { editWorkflow } from "../../../../api/WorkflowAPI";
import StatsPageTitle from "../StatsPageTitle";
import CampaignActions from "./components/CampaignActions";
import TableFilterExport from "../../../../components/TableComponents/TableFilterExport";
import DownloadAllCampaigns from "./components/DownloadAllCampaigns";

const Campaigns = () => {
  const setPageTitle = useUIStore((s) => s.setPageTitle);
  useEffect(() => {
    setPageTitle("Campagnes");
  });
  const apiRef = useGridApiRef();
  const lang = useLanguage((s) => s.language);
  const [loading, setLoading] = useState(true);
  const [campaignFilter, setCampaignFilter] = useState("all");
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);

  const handleFilters = useCallback(
    (newFilter, newRows) => {
      let r = newRows || rows;
      let method =
        newFilter !== campaignFilter
          ? setFilteredRows
          : apiRef.current.updateRows;
      setCampaignFilter(newFilter);
      if (newFilter === "error") {
        apiRef.current.setColumnVisibility("archived_on", false);
        return method(
          r
            .filter((x) =>
              x.steps.find((x) =>
                x.details.find(
                  (x) => x.level === "critical" || x.level === "error"
                )
              )
            )
            .filter((x) => !x.archived)
        );
      }
      if (newFilter === "archived") {
        apiRef.current.setColumnVisibility("archived_on", true);
        return method(r.filter((x) => x.archived));
      }
      if (newFilter === "running") {
        apiRef.current.setColumnVisibility("archived_on", false);
        return method(r.filter((x) => !x.stopped).filter((x) => !x.archived));
      }
      if (newFilter === "paused") {
        apiRef.current.setColumnVisibility("archived_on", false);
        return method(r.filter((x) => x.stopped).filter((x) => !x.archived));
      } else {
        apiRef.current.setColumnVisibility("archived_on", false);
        method(r.filter((x) => !x.archived));
      }
    },
    [apiRef, campaignFilter, rows]
  );

  const columns = useMemo(
    () => [
      dateColumn("date_start", "Date prog", true),
      CampaignColumnId(),
      CampaignColumnStatus({ apiRef }),
      CampaignColumnName(),
      CampaignColumnContacted(),
      CampaignColumnRates({
        field: "opens",
        headerName: lang.statistics.columns.rates.contacts_opened,
        identifier: "contacts_opened",
      }),
      CampaignColumnRates({
        field: "clicks",
        headerName: lang.statistics.columns.rates.contacts_clicked,
        identifier: "contacts_clicked",
      }),
      CampaignColumnRates({
        field: "answers",
        headerName: lang.statistics.columns.rates.contacts_answered,
        identifier: "contacts_answered",
      }),
      CampaignColumnDetails(),
      CampaignColumnSender(),
      CampaignLastSent(),
      dateColumn("stopped_on", lang.statistics.columns.paused_on, true),
      // {
      //   field: "created_by",
      //   headerName: lang.statistics.columns.created_by.header_name,
      //   width: 125,
      //   renderCell: ({ value }) => {
      //     if (value)
      //       return (
      //         <Group direction={"row"} alignItems={"center"} spacing={1}>
      //           <Text fontSize={12}>#{value}</Text>
      //         </Group>
      //       );
      //     return "";
      //   },
      // },
      dateColumn("archived_on", lang.statistics.columns.archived_on, true),
      sharingColumn({
        field: "stats_sharings",
        apiRef,
        readOnly: true,
        editFunction:
          () =>
          ({ row, id, sharing }) =>
            editCampaign({
              programmation_id: id,
              workflow_id: row.workflow_id,
              data: { stats_sharings: sharing },
            }).then(() => {
              return editWorkflow({ id: row.workflow_id, sharing: sharing });
            }),
      }),
    ],
    [lang, apiRef]
  );

  const actionsCell = useCallback(
    ({ row, value }) => {
      if (row?.isChild) {
        return "";
      }

      return (
        <Group wrap="nowrap" gap={4}>
          <CampaignActions
            apiRef={apiRef}
            current={row}
            onFinish={() => setLoading(true)}
            campaignFilter={campaignFilter}
          />
          <Tooltip label={lang.workflows.reprog}>
            <Anchor
              component={Link}
              to={
                !row.write_permission
                  ? null
                  : `/campaign/edit/${row.id}?workflow=${row.workflow_id}&reprog=true`
              }
              style={{ display: "flex" }}
            >
              <ActionIcon variant="subtle" disabled={!row.write_permission}>
                <IconRepeat size={18} />
              </ActionIcon>
            </Anchor>
          </Tooltip>
          <Tooltip label={lang.workflows.edit}>
            <Anchor
              component={Link}
              to={
                !row.write_permission
                  ? null
                  : `/campaign/edit/${row.id}?workflow=${row.workflow_id}`
              }
              style={{ display: "flex" }}
            >
              <ActionIcon variant="subtle" disabled={!row.write_permission}>
                <IconEdit size={18} />
              </ActionIcon>
            </Anchor>
          </Tooltip>
        </Group>
      );
    },
    [apiRef, campaignFilter, lang.workflows.edit, lang.workflows.reprog]
  );

  const toolbarActions = useCallback(
    ({ selectedIds }) => {
      return (
        <SelectionDeleter
          handleDelete={({ parent_id, id }) =>
            archiveCampaign({ workflow_id: parent_id, programmation_id: id })
          }
          apiRef={apiRef}
          setTableLoading={setLoading}
          ids={selectedIds}
          tooltip={lang.statistics.archive.label}
          icon={<IconArchive size={18} />}
          buttonTooltip={"Archiver les séléctionnés"}
          warningText={
            <Text size="sm">
              Vous êtes sur le point d'archiver tous les séléctionnés. <br />
              <b>Ces campagnes seront stoppés.</b>
            </Text>
          }
          title={
            <Text size="md" truncate="end">
              Archiver <b> {selectedIds.length} éléments </b>
            </Text>
          }
          submitButtonText={lang.statistics.archive.dialog.submit_button}
          getParentId={(id) => {
            const programmations = usePrmStore.getState().campaignOptions;

            return programmations.find((x) => x.id === id)?.workflow_id;
          }}
        />
      );
    },
    [
      apiRef,
      lang.statistics.archive.dialog.submit_button,
      lang.statistics.archive.label,
    ]
  );

  useEffect(() => {
    if (loading) {
      getCampaignsStats()
        .then((response) => {
          let results = response.data.programmations;

          let final = [];

          results
            .sort((a, b) => b.id - a.id)
            .forEach((prog) => {
              let initial = prog.steps.find((x) => x.is_initial === true);

              const fromSharing =
                useProfileStore.getState().profile?.id !== prog?.created_by?.id;

              let parent = {
                ...prog,
                path: [prog.id.toString()],
                isParent: true,
                isChild: false,
                name: prog.workflow_name,
                initial,
                from_sharing: fromSharing,
                write_permission: !fromSharing,
              };

              let child = {
                path: [prog.id.toString(), "#" + prog.id.toString()],
                id: "#" + prog.id.toString(), // # = child row
                isChild: true,
                prog_id: prog.id,
                archived: prog.archived,
                prog_name: prog.workflow_name,
                stopped: prog.stopped,
                prog: prog,
                workflow: {
                  name: prog.workflow_name,
                  id: prog.workflow_id,
                },
                steps: prog.steps,
                write_permission: !fromSharing,
              };

              final.push(parent);
              final.push(child);
            });

          setRows(final);
          handleFilters(campaignFilter, final);
        })
        .finally(() => {
          setLoading(false);
        });
    }
  }, [loading, campaignFilter]);

  return (
    <>
      <StatsPageTitle />

      <TableLayoutBlock>
        <Stack flex={"1 1 auto"} w={0} miw={"100%"} gap={0}>
          <Group m={"xs"}>
            <CampaignFilter
              value={campaignFilter}
              onChange={handleFilters}
              archivedNumber={
                rows?.filter((x) => x?.archived && typeof x.id === "number")
                  ?.length
              }
            />

            <DownloadAllCampaigns />
          </Group>
          <Flex flex={"1 1 auto"} h={0}>
            <MainTable
              apiRef={apiRef}
              columns={columns}
              rows={filteredRows}
              loading={loading}
              setLoading={setLoading}
              id={"list-table"}
              actionsCell={actionsCell}
              toolbarActions={
                campaignFilter !== "archived" ? toolbarActions : undefined
              }
              treeData
              pageOrderName={"stats_order"}
              withBorderTop
              hidePerPage
              pinnedLeft={[
                GRID_CHECKBOX_SELECTION_FIELD,
                GRID_TREE_DATA_GROUPING_FIELD,
                "id",
                "stopped",
              ]}
            />
          </Flex>
        </Stack>
      </TableLayoutBlock>
    </>
  );
};

export default Campaigns;
