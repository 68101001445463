import {
  Badge,
  Box,
  Button,
  Divider,
  Paper,
  Stack,
  ThemeIcon,
  Tooltip,
} from "@mantine/core";
import { LinksGroup } from "../../../../layouts/Navbar/NavbarLinksGroup/NavbarLinksGroup.jsx";
import { useUIStore } from "../../../../stores/UIStore.js";
import {
  IconArrowBadgeUpFilled,
  IconBuildingStore,
  IconChartPie,
  IconPlaylistAdd,
  IconPlus,
  IconRocket,
  IconSpeakerphone,
  IconUserUp,
} from "@tabler/icons-react";
import { useLanguage } from "../../../../stores/LanguageStore.js";
import { IconChartInfographic } from "@tabler/icons-react";
import { IconAddressBook } from "@tabler/icons-react";
import { useLeadsStore } from "../../../../stores/LeadsStore.js";
import { getPermission } from "../../../../functions/utilFunctions.js";
import { Link } from "react-router-dom";
import { useProfileStore } from "../../../../stores/UserStore.js";
import { useEffect } from "react";
import Sparkles from "../../../../components/Sparkles/Sparkles.jsx";
import Ressources from "./Ressources.jsx";

const Navbar = ({ closed }) => {
  const lang = useLanguage((s) => s.language);
  const campaignOpened = useUIStore((s) => s.campaignOpened);
  const setCampaignOpened = useUIStore((s) => s.setCampaignOpened);
  const prms = useLeadsStore((s) => s.prms);
  const profile = useProfileStore((s) => s.profile);

  useEffect(() => {
    if (!getPermission("displaySideMenu")) {
      useUIStore.getState().setAsideCollapsed(true);
    } else {
      useUIStore.getState().setAsideCollapsed(false);
    }
  }, [profile]);

  if (getPermission("displaySideMenu")) {
    if (closed) {
      return (
        <Paper
          component={Stack}
          gap={0}
          className="navbar"
          w={75}
          radius={0}
          style={{
            boxShadow: "inset #00000009 0 2px 6px",
            borderRight: "1px solid #0000000c",
          }}
          h={"100%"}
          pb={6}
          px={0}
          bg={"#fbfbfb"}
        >
          <Link to={"/home2"}>
            <Box px={11} pt={"lg"} mb={8}>
              <svg viewBox="0 0 90.44 40.56" fill="#fcb614">
                <path d="m31.85,23.92L8.24,0,0,8.13l23.61,23.92,8.24-8.13Z" />
                <path d="m81.56,35.45c-6.37,6.63-16.91,6.83-23.54.46-.16-.15-.31-.3-.46-.46l-15.84-16.29c-2.04-2.12-5.42-2.19-7.54-.14-.05.05-.1.09-.14.14-2.13,2.2-2.13,5.7,0,7.9,2.03,2.12,5.4,2.19,7.52.16.05-.05.11-.1.16-.16l1.13-1.15,8.15,8.39-1.12,1.15c-6.37,6.63-16.91,6.83-23.54.46-.16-.15-.31-.3-.46-.46-6.63-6.89-6.63-17.79,0-24.68,3.2-3.3,12-10.77,12-10.77,0,0,8.59,8.24,11.8,11.54l12,12,4,3.47h0c2.05,2.12,5.43,2.19,7.55.14.05-.05.09-.09.14-.14,2.12-2.21,2.12-5.69,0-7.9-2.05-2.12-5.43-2.19-7.55-.14-.05.05-.09.09-.14.14l-1.13,1.15-8.15-8.39,1.12-1.15c3.25-3.25,12.48-10.72,12.48-10.72,0,0,8.34,7.74,11.54,11,3.21,3.2,5.01,7.54,5,12.07.03,4.62-1.76,9.07-4.98,12.38Z" />
              </svg>
            </Box>
          </Link>

          <Divider mt={2.5} size={1} color="#f0f0f0" />

          {getPermission("accessWorkflows") && (
            <Tooltip label="Nouvelle Campagne" position="right">
              <Button
                component={Link}
                to={"/campaign/create"}
                id="campaign-button"
                radius={"md"}
                h={44}
                w={"calc(100% - 26px)"}
                ml={13}
                p={0}
                miw={0}
                mt={12}
                justify="center"
                variant="gradient"
                pos={"relative"}
                size="md"
                style={{
                  overflow: "visible",
                }}
              >
                <Box pos={"absolute"} w={"100%"} h={"100%"} top={0} left={0}>
                  <Sparkles />
                </Box>
                <IconRocket />
              </Button>
            </Tooltip>
          )}

          {getPermission("accessContactLists") && (
            <Tooltip label="Créer une liste" position="right">
              <Button
                component={Link}
                to={"/list2"}
                id={"create-list"}
                radius={"md"}
                p={0}
                miw={0}
                h={36}
                variant="light"
                mt={12}
                mb={8}
                justify="center"
                w={"calc(100% - 26px)"}
                ml={13}
              >
                <IconPlaylistAdd size={22} />
              </Button>
            </Tooltip>
          )}

          {getPermission("accessModels") && (
            <Ressources
              inNavbar
              navbarClosed={closed}
              opened={campaignOpened}
              setOpened={setCampaignOpened}
            />
          )}

          {getPermission("accessReporting") && (
            <LinksGroup
              id={"statistics"}
              path={"/statistics"}
              icon={IconSpeakerphone}
              name={"Campagnes"}
              navbarClosed={closed}
            />
          )}

          {getPermission("accessReporting") && (
            <LinksGroup
              id={"reporting"}
              path={"/statistics/global-report"}
              icon={IconChartPie}
              name={"Rapports"}
              navbarClosed={closed}
            />
          )}

          {getPermission("accessPRM") && (
            <LinksGroup
              id={"leads-tracking"}
              path={"/leads-tracking"}
              icon={IconAddressBook}
              name={lang.header.navigations.prm.label}
              navbarClosed={closed}
              rightSection={
                prms?.[0]?.new_answerers_since_date_view > 0 && (
                  <Tooltip
                    label={`${prms?.[0]?.new_answerers_since_date_view} ${lang.header.navigations.prm.new_answerers}`}
                  >
                    <Badge
                      size="sm"
                      color="secondary.5"
                      pos={"absolute"}
                      right={10}
                    >
                      {prms?.[0]?.new_answerers_since_date_view}{" "}
                    </Badge>
                  </Tooltip>
                )
              }
            />
          )}

          <Divider mt={"auto"} mx={"35%"} size={"sm"} mb={8} />

          {getPermission("displayResellers") && (
            <LinksGroup
              id={"/resellers/white-labels"}
              path={"/resellers/white-labels"}
              name={lang.resellers.browser_tab_title}
              navbarClosed={closed}
              customIcon={
                <ThemeIcon variant={"subtle"} size={28} color={"primary"}>
                  <IconBuildingStore size={20} />
                </ThemeIcon>
              }
            />
          )}

          {getPermission("displayPricing") && (
            <Box pos={"relative"}>
              <LinksGroup
                id={"/account-settings/subscription"}
                path={"/account-settings/subscription"}
                name={lang.pricing.browser_tab_title}
                navbarClosed={closed}
                isPricing={true}
                customIcon={
                  <ThemeIcon variant={"subtle"} size={28} color={"secondary"}>
                    <IconArrowBadgeUpFilled size={20} />
                  </ThemeIcon>
                }
              />
            </Box>
          )}
        </Paper>
      );
    }
    return (
      <Paper
        component={Stack}
        gap={0}
        className="navbar"
        w={closed ? 60 : 240}
        radius={0}
        style={{
          boxShadow: "inset #00000009 0 2px 6px",
          borderRight: "1px solid #0000000c",
        }}
        h={"100%"}
        pb={6}
        px={4}
        bg={"#fbfbfb"}
      >
        <Link to={"/home2"}>
          <Box px={"lg"} pt={"lg"} mb={8}>
            <svg viewBox="0 0 326.44 40.56" fill="#fcb614">
              <path d="m31.85,23.92L8.24,0,0,8.13l23.61,23.92,8.24-8.13Z" />
              <path d="m81.56,35.45c-6.37,6.63-16.91,6.83-23.54.46-.16-.15-.31-.3-.46-.46l-15.84-16.29c-2.04-2.12-5.42-2.19-7.54-.14-.05.05-.1.09-.14.14-2.13,2.2-2.13,5.7,0,7.9,2.03,2.12,5.4,2.19,7.52.16.05-.05.11-.1.16-.16l1.13-1.15,8.15,8.39-1.12,1.15c-6.37,6.63-16.91,6.83-23.54.46-.16-.15-.31-.3-.46-.46-6.63-6.89-6.63-17.79,0-24.68,3.2-3.3,12-10.77,12-10.77,0,0,8.59,8.24,11.8,11.54l12,12,4,3.47h0c2.05,2.12,5.43,2.19,7.55.14.05-.05.09-.09.14-.14,2.12-2.21,2.12-5.69,0-7.9-2.05-2.12-5.43-2.19-7.55-.14-.05.05-.09.09-.14.14l-1.13,1.15-8.15-8.39,1.12-1.15c3.25-3.25,12.48-10.72,12.48-10.72,0,0,8.34,7.74,11.54,11,3.21,3.2,5.01,7.54,5,12.07.03,4.62-1.76,9.07-4.98,12.38Z" />
              <g>
                <path d="m108.4,30.77c-.29,0-.56-.11-.78-.29-.21-.19-.32-.46-.31-.74V3.15c0-.28.11-.55.31-.74.22-.18.49-.28.78-.28h4.68c.39-.03.77.1,1.05.37.2.14.36.32.49.53l7.63,12.93,7.64-12.93c.13-.19.28-.36.44-.53.29-.27.68-.4,1.07-.37h4.67c.29-.01.57.09.78.28.23.18.36.45.35.74v26.59c0,.29-.12.56-.35.74-.21.2-.49.3-.78.29h-5.17c-.3,0-.59-.1-.82-.29-.21-.19-.32-.46-.31-.74v-15.34l-4.9,8.55c-.13.27-.33.51-.57.69-.28.2-.62.3-.96.28h-2.25c-.34.02-.68-.08-.96-.28-.23-.18-.42-.41-.56-.66l-4.92-8.55v15.31c.02.28-.09.55-.29.74-.21.2-.49.3-.78.29h-5.17Z" />
                <path d="m150.37,31.19c-1.45.02-2.88-.26-4.21-.82-1.16-.51-2.17-1.3-2.94-2.3-1.78-2.38-1.29-5.76,1.09-7.54.12-.09.25-.18.38-.26,2.02-1.27,4.31-2.07,6.68-2.34l5.43-.77v-.57c.06-.81-.18-1.61-.66-2.25-.65-.61-1.53-.9-2.41-.78-.69-.03-1.38.13-1.99.46-.49.3-.9.72-1.18,1.22-.25.35-.66.55-1.09.53h-4.86c-.27.02-.53-.07-.74-.25-.17-.17-.25-.41-.22-.65.04-.6.24-1.18.57-1.68.48-.74,1.1-1.4,1.81-1.93.96-.66,2.02-1.16,3.14-1.47,1.49-.44,3.04-.65,4.59-.62,1.63-.03,3.25.17,4.81.62,1.19.33,2.29.92,3.22,1.72.82.71,1.45,1.6,1.86,2.61.42,1.04.63,2.16.62,3.28v12.43c0,.29-.12.56-.35.74-.2.19-.46.3-.74.29h-5.05c-.29,0-.57-.1-.78-.29-.21-.19-.32-.46-.31-.74v-1.47c-.4.57-.9,1.07-1.47,1.47-.69.47-1.45.81-2.25,1.03-.96.26-1.96.38-2.96.34Zm2.05-4.59c.78.02,1.55-.16,2.25-.5.69-.35,1.25-.9,1.6-1.59.43-.85.64-1.79.6-2.74v-.57l-3.68.62c-1.04.12-2.05.47-2.94,1.02-.54.34-.88.92-.9,1.56-.02.43.14.86.43,1.18.31.35.7.61,1.13.78.48.17.99.25,1.5.25Z" />
                <path d="m181.15,39.84c-1.88.06-3.76-.22-5.55-.82-1.27-.44-2.44-1.1-3.47-1.96-.74-.65-1.36-1.42-1.83-2.28-.32-.54-.52-1.14-.6-1.77-.03-.28.08-.56.29-.74.23-.19.53-.29.82-.28h5.52c.23-.01.45.04.65.16.23.16.39.39.47.65.16.33.35.65.57.94.28.34.64.61,1.05.78.59.24,1.22.35,1.86.32.75.03,1.49-.12,2.18-.41.58-.24,1.04-.69,1.3-1.27.33-.73.48-1.53.44-2.33v-2.78c-.74.72-1.61,1.29-2.56,1.68-1.21.43-2.5.63-3.78.6-1.33.02-2.64-.18-3.9-.6-1.08-.38-2.05-1.01-2.83-1.84-.77-.86-1.38-1.86-1.78-2.94-.42-1.31-.67-2.67-.74-4.05v-2.13c.05-1.34.28-2.68.68-3.96.37-1.12.96-2.15,1.74-3.03.8-.85,1.78-1.51,2.87-1.93,1.24-.48,2.57-.72,3.9-.69,2.58-.05,5.05,1.02,6.77,2.94v-1.47c0-.26.11-.52.31-.69.2-.22.49-.34.78-.32h4.99c.29-.01.58.11.78.32.22.16.35.42.35.69v19.57c.07,1.83-.38,3.64-1.3,5.23-.93,1.44-2.27,2.57-3.86,3.22-1.94.82-4.02,1.22-6.13,1.19Zm-.31-14.72c.8.03,1.58-.17,2.27-.57.56-.35.99-.87,1.25-1.47.3-.6.46-1.26.49-1.93v-2.49c-.02-.68-.19-1.35-.49-1.96-.25-.61-.69-1.13-1.25-1.47-1.43-.76-3.14-.76-4.56,0-.55.38-.97.93-1.21,1.56-.24.7-.39,1.43-.44,2.16v1.84c.05.74.2,1.48.44,2.18.24.63.66,1.16,1.21,1.55.7.38,1.5.55,2.3.49v.12Z" />
                <path d="m199.54,30.77c-.29,0-.57-.1-.78-.29-.2-.19-.3-.46-.29-.74V10.52c0-.28.1-.54.29-.74.21-.19.49-.29.78-.28h5.12c.27-.01.54.09.74.28.23.18.36.45.35.74v19.23c0,.29-.12.56-.35.74-.2.19-.46.3-.74.29h-5.12Zm0-24.34c-.29,0-.57-.1-.78-.29-.19-.2-.3-.46-.29-.74V1.77c0-.28.1-.54.29-.74.2-.22.49-.33.78-.32h5.17c.3-.01.58.1.78.32.23.17.36.45.35.74v3.64c0,.29-.13.56-.35.74-.21.2-.49.31-.78.29h-5.17Z" />
                <path d="m212.79,30.77c-.29,0-.57-.1-.78-.29-.21-.19-.32-.46-.31-.74V2.74c0-.28.11-.55.31-.74.21-.19.49-.29.78-.28h5.12c.29-.01.57.09.78.28.2.19.32.46.31.74v27c.01.28-.1.55-.31.74-.21.2-.49.3-.78.29h-5.12Z" />
                <path d="m235.4,31.19c-2.95.15-5.85-.79-8.16-2.62-2.13-2.01-3.29-4.85-3.17-7.77v-1.47c.01-1.94.52-3.85,1.47-5.54.96-1.49,2.34-2.67,3.96-3.39,1.84-.86,3.86-1.29,5.89-1.24,2.19-.06,4.36.44,6.29,1.47,1.62.9,2.93,2.25,3.78,3.89.85,1.74,1.28,3.67,1.25,5.61v.99c0,.29-.13.56-.35.74-.2.19-.46.29-.74.28h-13.87v.29c.02.74.17,1.47.43,2.16.54,1.26,1.81,2.06,3.18,2,.5.02,1-.06,1.47-.24.36-.16.69-.37,1-.62l.6-.62c.17-.2.38-.36.6-.49.25-.1.51-.14.78-.12h5.39c.25-.01.5.07.69.24.2.14.31.38.27.62-.1.61-.35,1.19-.74,1.68-.58.76-1.27,1.42-2.05,1.96-1.01.7-2.12,1.22-3.3,1.56-1.53.44-3.11.65-4.7.62Zm-3.68-13.25h7.36v-.07c.02-.79-.13-1.57-.43-2.3-.25-.62-.7-1.13-1.27-1.47-1.24-.71-2.75-.71-3.99,0-.54.37-.96.88-1.22,1.47-.3.73-.44,1.51-.43,2.3l-.03.07Z" />
                <path d="m258.4,31.19c-1.45.02-2.88-.26-4.21-.82-1.16-.51-2.17-1.3-2.94-2.3-1.78-2.38-1.29-5.76,1.09-7.54.12-.09.25-.18.38-.26,2.02-1.27,4.31-2.07,6.68-2.34l5.43-.77v-.57c.05-.81-.19-1.61-.68-2.25-.64-.6-1.51-.89-2.39-.78-.69-.03-1.38.13-1.99.46-.49.3-.9.72-1.18,1.22-.25.35-.66.55-1.09.53h-4.86c-.27.01-.53-.08-.74-.25-.17-.17-.25-.41-.22-.65.04-.6.24-1.18.57-1.68.49-.74,1.1-1.4,1.81-1.93.96-.66,2.02-1.16,3.14-1.47,1.49-.44,3.04-.65,4.59-.62,1.63-.03,3.25.17,4.81.62,1.19.33,2.29.92,3.22,1.72.82.71,1.45,1.6,1.86,2.61.42,1.04.63,2.16.62,3.28v12.43c0,.29-.12.56-.35.74-.2.19-.46.3-.74.29h-5.02c-.29,0-.57-.1-.78-.29-.21-.19-.32-.46-.31-.74v-1.47c-.4.57-.9,1.07-1.47,1.47-.69.47-1.45.81-2.25,1.03-.98.27-1.99.38-3,.34Zm2.05-4.59c.78.02,1.55-.16,2.25-.5.69-.35,1.25-.9,1.6-1.59.44-.84.65-1.79.62-2.74v-.57l-3.71.54c-1.04.12-2.05.47-2.94,1.02-.55.33-.89.92-.91,1.56-.02.43.14.86.43,1.18.31.35.7.61,1.13.78.49.19,1.01.3,1.53.32Z" />
                <path d="m286.52,31.19c-1.28.02-2.56-.19-3.77-.62-1.08-.42-2.06-1.06-2.87-1.88-.8-.88-1.42-1.91-1.83-3.03-.45-1.31-.7-2.69-.74-4.08v-2.94c.04-1.35.29-2.69.74-3.96.4-1.12,1.02-2.15,1.83-3.03.8-.85,1.78-1.51,2.87-1.93,1.2-.47,2.48-.71,3.77-.69,1.28-.03,2.55.21,3.74.69.98.41,1.88.99,2.65,1.72V2.74c0-.28.1-.54.29-.74.21-.19.49-.29.78-.28h5.3c.27,0,.54.09.74.28.23.18.36.45.35.74v27c0,.29-.12.56-.35.74-.2.19-.46.3-.74.29h-4.9c-.29,0-.57-.1-.78-.29-.21-.19-.32-.46-.31-.74v-1.31c-.73.83-1.63,1.51-2.64,1.97-1.3.56-2.72.83-4.14.78Zm2.34-5.49c.81.05,1.62-.15,2.31-.57.55-.37.98-.88,1.25-1.47.3-.64.46-1.34.49-2.05v-3.03c-.02-.65-.19-1.29-.49-1.87-.27-.61-.73-1.12-1.3-1.47-1.43-.76-3.14-.76-4.56,0-.55.38-.96.93-1.16,1.56-.24.7-.39,1.43-.44,2.16-.09.84-.09,1.69,0,2.53.05.74.2,1.48.44,2.18.21.63.62,1.17,1.16,1.55.7.38,1.5.55,2.3.49Z" />
                <path d="m315.69,31.19c-1.62.03-3.24-.16-4.81-.57-1.14-.33-2.22-.83-3.21-1.47-.71-.47-1.33-1.07-1.83-1.77-.35-.47-.57-1.01-.65-1.59-.04-.28.07-.56.29-.74.19-.2.46-.32.74-.32h4.95c.07-.02.15-.02.22,0l.27.16c.4.21.78.46,1.13.74.4.3.84.54,1.3.74.56.24,1.17.35,1.78.34.71.03,1.41-.13,2.03-.46.52-.25.84-.78.82-1.35.02-.39-.12-.77-.38-1.06-.46-.41-1.01-.7-1.6-.85-1.16-.37-2.36-.64-3.56-.82-1.36-.28-2.68-.7-3.95-1.27-1.03-.46-1.93-1.18-2.61-2.09-.63-.93-.94-2.03-.91-3.15.03-1.13.44-2.23,1.15-3.11.86-1.1,2-1.95,3.3-2.46,1.69-.71,3.5-1.04,5.33-.99,1.49-.04,2.98.16,4.42.57,1.11.32,2.17.81,3.12,1.47.76.46,1.42,1.05,1.96,1.75.38.47.62,1.03.69,1.63.03.27-.06.54-.25.74-.19.19-.44.29-.71.29h-4.61c-.12.01-.24.01-.35,0l-.29-.16c-.38-.17-.73-.4-1.05-.66-.38-.29-.79-.53-1.22-.74-.55-.24-1.14-.35-1.74-.32-.64-.04-1.28.13-1.83.49-.41.29-.65.76-.65,1.27,0,.36.12.71.35.99.41.39.92.68,1.47.82,1.19.33,2.39.6,3.61.81,1.66.2,3.28.7,4.77,1.47,1.05.52,1.92,1.34,2.52,2.34.48.85.74,1.81.74,2.78.01,1.27-.43,2.5-1.25,3.47-.98,1.06-2.22,1.84-3.61,2.25-1.91.59-3.89.87-5.89.82Z" />
              </g>
            </svg>
          </Box>
        </Link>

        <Divider mt={2.5} size={1} color="#f0f0f0" />

        {getPermission("accessWorkflows") && (
          <Button
            component={Link}
            to={"/campaign/create?blockRightSide=true"}
            leftSection={<IconPlus size={24} />}
            id="campaign-button"
            radius={"md"}
            h={44}
            w={"calc(100% - 24px)"}
            mx={12}
            mt={12}
            justify="start"
            variant="gradient"
            pos={"relative"}
            size="md"
            style={{
              overflow: "visible",
            }}
            className="shadow-on-hover"
          >
            <Box pos={"absolute"} w={"100%"} h={"100%"} top={0} left={0}>
              <Sparkles />
            </Box>
            Nouvelle campagne
          </Button>
        )}

        {getPermission("accessContactLists") && (
          <Button
            component={Link}
            to={"/contact-lists/import"}
            leftSection={<IconPlus size={22} />}
            id={"create-list"}
            radius={"md"}
            w={"calc(100% - 24px)"}
            mx={12}
            h={36}
            variant="light"
            // gradient={{ from: "primary.4", to: "primary.6", deg: 90 }}
            mt={12}
            mb={8}
            justify="start"
            className="shadow-on-hover"
          >
            Créer une liste
          </Button>
        )}

        {getPermission("accessModels") && (
          <Ressources
            inNavbar
            navbarClosed={closed}
            opened={campaignOpened}
            setOpened={setCampaignOpened}
          />
        )}

        {getPermission("accessReporting") && (
          <LinksGroup
            id={"statistics"}
            path={"/statistics"}
            icon={IconSpeakerphone}
            name={"Campagnes"}
            navbarClosed={closed}
          />
        )}

        {getPermission("accessReporting") && (
          <LinksGroup
            id={"reporting"}
            path={"/statistics/global-report"}
            icon={IconChartPie}
            name={"Rapports"}
            navbarClosed={closed}
          />
        )}

        {getPermission("accessPRM") && (
          <LinksGroup
            id={"leads-tracking"}
            path={"/leads-tracking"}
            icon={IconAddressBook}
            name={lang.header.navigations.prm.label}
            navbarClosed={closed}
            rightSection={
              prms?.[0]?.new_answerers_since_date_view > 0 && (
                <Tooltip
                  label={`${prms?.[0]?.new_answerers_since_date_view} ${lang.header.navigations.prm.new_answerers}`}
                >
                  <Badge
                    size="sm"
                    color="secondary.5"
                    pos={"absolute"}
                    right={10}
                  >
                    {prms?.[0]?.new_answerers_since_date_view}{" "}
                  </Badge>
                </Tooltip>
              )
            }
          />
        )}

        <Divider mt={"auto"} mx={"35%"} size={"sm"} mb={8} />

        {getPermission("displayResellers") && (
          <LinksGroup
            id={"/resellers/white-labels"}
            path={"/resellers/white-labels"}
            name={lang.resellers.browser_tab_title}
            customIcon={
              <ThemeIcon variant={"subtle"} size={28} color={"primary"}>
                <IconBuildingStore size={20} />
              </ThemeIcon>
            }
          />
        )}

        {getPermission("displayPricing") && (
          <Box pos={"relative"}>
            <LinksGroup
              id={"/account-settings/subscription"}
              path={"/account-settings/subscription"}
              name={lang.pricing.browser_tab_title}
              isPricing={true}
              customIcon={
                <ThemeIcon variant={"subtle"} size={28} color={"secondary"}>
                  <IconArrowBadgeUpFilled size={20} />
                </ThemeIcon>
              }
            />
          </Box>
        )}
      </Paper>
    );
  }

  return "";
};

export default Navbar;
