import { Collapse } from "@mantine/core";
import {
  IconBoxModel2,
  IconBrandLinkedin,
  IconList,
  IconMailForward,
  IconSend,
  IconSignature,
  IconMail,
  IconMessageCircle,
  IconMessage,
  IconApps,
} from "@tabler/icons-react";
import { useMemo } from "react";
import { useLanguage } from "../../../../stores/LanguageStore";
import { useAccountsStore } from "../../../../stores/ConfigStore";
import NavItemMenu from "../../../../layouts/Navbar/NavItemMenu";
import { LinksGroup } from "../../../../layouts/Navbar/NavbarLinksGroup/NavbarLinksGroup";
import { IconLinkedin } from "../../../../components/Icons/IconLinkedin";
import { useUIStore } from "../../../../stores/UIStore";

const iconSize = 18;

const Ressources = ({ navbarClosed, opened, setOpened, is2 }) => {
  const lang = useLanguage((s) => s.language);
  const emails = useAccountsStore((s) => s.email_accounts);
  const linkedins = useAccountsStore((s) => s.linkedin_accounts);

  const accountErrors = useMemo(() => {
    return {
      emails: emails.filter((x) => !x.is_valid).length,
      linkedins: linkedins.filter((x) => !x.is_valid).length,
    };
  }, [emails, linkedins]);

  const ressources = [
    {
      id: "contact-lists",
      path: "/list2",
      icon: <IconList size={iconSize} />,
      name: "Mes listes",
    },
    {
      id: "models",
      path: "/models",
      icon: <IconBoxModel2 size={iconSize} />,
      name: lang.header.navigations.models.label,
      children: [
        {
          path: "/email",
          name: lang.header.navigations.models.emails,
          icon: <IconMail size={iconSize} />,
          color: "orange",
        },
        {
          path: "/linkedin",
          name: "LinkedIn",
          icon: <IconLinkedin size={iconSize} />,
        },
        {
          path: "/sms",
          name: lang.header.navigations.models.sms,
          icon: <IconMessageCircle size={iconSize} />,
          color: "green",
        },
      ],
    },
    {
      id: "senders",
      name: lang.header.navigations.senders,
      icon: <IconSend size={iconSize} />,
      path: "senders",
      children: [
        {
          icon: <IconMailForward size={iconSize} />,
          name: lang.header.menus.link_groups.emails_accounts,
          path: "/email",
          indicatorContent: accountErrors.emails,
        },
        {
          icon: <IconSignature size={iconSize} />,
          name: lang.header.menus.link_groups.email_signatures,
          path: "/email-signatures",
        },
        {
          icon: <IconBrandLinkedin size={iconSize} />,
          name: lang.header.menus.link_groups.linkedin_accounts,
          path: "/linkedin",
          indicatorContent: accountErrors.linkedins,
        },
      ],
    },
  ];

  return (
    <>
      <LinksGroup
        closed={navbarClosed}
        name={lang.header.menus.link_groups.title_ressources}
        onClick={() => {
          if (useUIStore.getState().navbarClosed) {
            useUIStore.getState().setNavbarClosed(false);
            setOpened(true);
          } else {
            setOpened(!opened);
          }
        }}
        icon={IconApps}
        navbarClosed={navbarClosed}
        hasLinks={true}
        opened={opened}
        noNav
      />

      <Collapse in={opened}>
        {ressources.map((link) => (
          <NavItemMenu
            is2
            key={link.path}
            item={link}
            navbarClosed={navbarClosed}
          />
        ))}
      </Collapse>
    </>
  );
};

export default Ressources;
